import { ConstituencyModel } from '../../../models/ConstituenciesModels';

export const ConstituenciesList: ConstituencyModel[] = [
	{
		id: '1',
		name: 'Arun',
	},
	{
		id: '2',
		name: 'Arun',
	},
	{
		id: '3',
		name: 'Arun',
	},
	{
		id: '4',
		name: 'Arun',
	},
	{
		id: '5',
		name: 'Arun',
	},
	{
		id: '6',
		name: 'Arun',
	},
	{
		id: '7',
		name: 'Arun',
	},
	{
		id: '8',
		name: 'Arun',
	},
	{
		id: '9',
		name: 'Arun',
	},
	{
		id: '10',
		name: 'Arun',
	},
	{
		id: '11',
		name: 'Arun',
	},
	{
		id: '12',
		name: 'Arun',
	},
	{
		id: '13',
		name: 'Arun',
	},
	{
		id: '14',
		name: 'Arun',
	},
	{
		id: '15',
		name: 'Arun',
	},
	{
		id: '16',
		name: 'Arun',
	},
	{
		id: '17',
		name: 'Arun',
	},
	{
		id: '18',
		name: 'Arun',
	},
	{
		id: '19',
		name: 'Arun',
	},
	{
		id: '20',
		name: 'Zafferan',
	},
];
