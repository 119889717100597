import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE_TYPE } from '../../models/CommonModels';
import { RootState } from '../store';
import { PricingPlanModel } from '../../models/PricingPlansModels';

interface PricingPlansDetailsTypes extends INITIAL_STATE_TYPE {
	data: null | PricingPlanModel;
}

interface PricingPlansListTypes extends INITIAL_STATE_TYPE {
	data: PricingPlanModel[];
}

interface InitialStateTypes {
	pricingPlansList: PricingPlansListTypes;
	pricingPlanDetails: PricingPlansDetailsTypes;
	savePricingPlan: INITIAL_STATE_TYPE;
}

const initialState: InitialStateTypes = {
	pricingPlansList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	pricingPlanDetails: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: null,
	},
	savePricingPlan: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
};

const PricingPlanSlice = createSlice({
	name: 'pricingPlans',
	initialState,
	reducers: {
		pricingPlansListInProgress: (state) => {
			state.pricingPlansList.data = [];
			state.pricingPlansList.loading = true;
			state.pricingPlansList.success = false;
			state.pricingPlansList.error = false;
			state.pricingPlansList.message = null;
		},
		pricingPlansListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: PricingPlanModel[]; message: string }>
		) => {
			state.pricingPlansList.data = payload.data;
			state.pricingPlansList.loading = false;
			state.pricingPlansList.success = true;
			state.pricingPlansList.message = payload.message;
		},
		pricingPlansListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.pricingPlansList.data = [];
			state.pricingPlansList.loading = false;
			state.pricingPlansList.error = true;
			state.pricingPlansList.message = payload.message;
		},

		pricingPlanDetailsInProgress: (state, {}: PayloadAction<string>) => {
			state.pricingPlanDetails.loading = true;
			state.pricingPlanDetails.success = false;
			state.pricingPlanDetails.error = false;
			state.pricingPlanDetails.message = null;
		},
		pricingPlanDetailsInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: PricingPlanModel; message: string }>
		) => {
			state.pricingPlanDetails.data = payload.data;
			state.pricingPlanDetails.loading = false;
			state.pricingPlanDetails.success = true;
			state.pricingPlanDetails.message = payload.message;
		},
		pricingPlanDetailsInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.pricingPlanDetails.data = null;
			state.pricingPlanDetails.loading = false;
			state.pricingPlanDetails.error = true;
			state.pricingPlanDetails.message = payload.message;
		},

		savePricingPlanInProgress: (state, {}: PayloadAction<PricingPlanModel>) => {
			state.savePricingPlan.loading = true;
			state.savePricingPlan.success = false;
			state.savePricingPlan.error = false;
			state.savePricingPlan.message = null;
		},
		savePricingPlanInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.savePricingPlan.loading = false;
			state.savePricingPlan.success = true;
			state.savePricingPlan.message = payload.message;
		},
		savePricingPlanInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.savePricingPlan.loading = false;
			state.savePricingPlan.error = true;
			state.savePricingPlan.message = payload.message;
		},
	},
});

export const {
	pricingPlanDetailsInProgress,
	pricingPlanDetailsInSuccess,
	pricingPlanDetailsInError,

	savePricingPlanInProgress,
	savePricingPlanInSuccess,
	savePricingPlanInError,

	pricingPlansListInProgress,
	pricingPlansListInSuccess,
	pricingPlansListInError,
} = PricingPlanSlice.actions;

export const selectPricingPlansListLoading = (state: RootState) =>
	state.pricingPlans.pricingPlansList.loading;
export const selectPricingPlansList = (state: RootState) =>
	state.pricingPlans.pricingPlansList.data;

export const selectSavePricingPlanLoading = (state: RootState) =>
	state.pricingPlans.savePricingPlan.loading;

export const selectPricingPlanListLoading = (state: RootState) =>
	state.pricingPlans.pricingPlanDetails.loading;
export const selectPricingPlanDetails = (state: RootState) =>
	state.pricingPlans.pricingPlanDetails.data;

export default PricingPlanSlice;
