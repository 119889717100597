export const petitionTypes = [
	{
		Code: 0,
		Description: 'All',
		Category: 0,
	},
	{
		Code: 1,
		Description: 'Pending',
		Category: 1,
	},
	{
		Code: 2,
		Description: 'In-Progress',
		Category: 2,
	},
	{
		Code: 3,
		Description: 'Forwarded',
		Category: 3,
	},
	{
		Code: 4,
		Description: 'Resolved',
		Category: 4,
	},
	{
		Code: 5,
		Description: 'Reject',
		Category: 5,
	},
];
