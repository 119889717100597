import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import {
	APP_ROUTES,
	PUBLIC_ROUTES,
	SUPER_ADMIN_ROUTES,
} from '../common/constants/Routes';
import DashboardScreen from '../features/app/DashboardScreen';
import AppLayout from './AppLayout';
import CandidateTable from '../features/app/admin/candidates/CandidateTable';
import Candidates from '../features/super_admin/Candidates/Candidates';
import CandidateAddScreen from '../features/super_admin/Candidates/CandidateAddScreen';
import CandidateEditScreen from '../features/super_admin/Candidates/CandidateEditScreen';
import PublicList from '../features/app/candidate/public list/PublicList';
import AddPublicForm from '../features/app/candidate/public list/AddPublicForm';
import EditPublic from '../features/app/candidate/public list/EditPublic';
import PetitionTable from '../features/app/candidate/petitions/PetitionTable';
import AddPetitionForm from '../features/app/candidate/petitions/AddPetitionForm';
import EditPetitionForm from '../features/app/candidate/petitions/EditPetitionForm';
import CandidateProfile from '../features/app/candidate/CandidateProfile';
import ChangePassword from '../features/app/ChangePassword';
import Parties from '../features/super_admin/Parties/Parties';
import ConstituencyAddScreen from '../features/super_admin/Constituencies/ConstituencyAddScreen';
import PartieEditScreen from '../features/super_admin/Parties/PartieEditScreen';
import Constituencies from '../features/super_admin/Constituencies/Constituencies';
import ConstituenciesEditScreen from '../features/super_admin/Constituencies/ConstituencieEditScreen';
import ViewPetition from '../features/app/candidate/petitions/ViewPetition';
import PartiesAddScreen from '../features/super_admin/Parties/PartiesAddScreen';
import PricingPlans from '../features/super_admin/PricingPlans/PricingPlans';
import PricingPlanAddScreen from '../features/super_admin/PricingPlans/PricingPlanAddScreen';
import PricingPlanEditScreen from '../features/super_admin/PricingPlans/PricingPlanEditScreen';
import PdfPetition from '../features/app/candidate/petitions/PdfPetition';

const AppRoutes = ({ isLogged }: any) => {
	console.log('isLogged', isLogged, localStorage.getItem('user'));
	const user = localStorage.getItem('user');
	console.log('user-AppRoutes', user);
	return (
		<Routes>
			{isLogged === true ? (
				<Route path="/" element={<AppLayout />}>
					<Route path={APP_ROUTES.DASHBOARD} element={<DashboardScreen />} />
					<Route
						path={APP_ROUTES.ADMINCANDIDATE}
						element={<CandidateTable />}
					/>
					<Route path={APP_ROUTES.PUBLIC_LIST} element={<PublicList />} />
					<Route
						path={APP_ROUTES.ADD_PUBLIC_LIST}
						element={<AddPublicForm />}
					/>
					<Route path={APP_ROUTES.EDIT_PUBLIC_LIST} element={<EditPublic />} />
					<Route path={APP_ROUTES.PETITION} element={<PetitionTable />} />
					<Route path={APP_ROUTES.ADD_PETITION} element={<AddPetitionForm />} />
					<Route
						path={APP_ROUTES.EDIT_PETITION}
						element={<EditPetitionForm />}
					/>{' '}
					<Route path={APP_ROUTES.PDF_PETITION} element={<PdfPetition />} />{' '}
					<Route path={APP_ROUTES.VIEW_PETITION} element={<ViewPetition />} />
					<Route
						path={APP_ROUTES.CANDIDATE_PROFILE}
						element={<CandidateProfile />}
					/>
					<Route
						path={APP_ROUTES.CHANGE_PASSWORD}
						element={<ChangePassword />}
					/>
					{/* ---------------Super Admin Start--------------- */}
					<Route
						path={SUPER_ADMIN_ROUTES.CANDIDATES}
						element={<Candidates />}
					/>
					<Route
						path={SUPER_ADMIN_ROUTES.CANDIDATES_ADD}
						element={<CandidateAddScreen />}
					/>
					<Route
						path={`${SUPER_ADMIN_ROUTES.CANDIDATES_EDIT}/:candidate_id`}
						element={<CandidateEditScreen />}
					/>
					<Route path={SUPER_ADMIN_ROUTES.PARTIES} element={<Parties />} />
					<Route
						path={SUPER_ADMIN_ROUTES.PARTIES_ADD}
						element={<PartiesAddScreen />}
					/>
					<Route
						path={`${SUPER_ADMIN_ROUTES.PARTIES_EDIT}/:partie_id`}
						element={<PartieEditScreen />}
					/>
					<Route
						path={SUPER_ADMIN_ROUTES.CONSTITUENCIES}
						element={<Constituencies />}
					/>
					<Route
						path={SUPER_ADMIN_ROUTES.CONSTITUENCIES_ADD}
						element={<ConstituencyAddScreen />}
					/>
					<Route
						path={`${SUPER_ADMIN_ROUTES.CONSTITUENCIES_EDIT}/:constituency_id`}
						element={<ConstituenciesEditScreen />}
					/>
					<Route
						path={SUPER_ADMIN_ROUTES.PRICING_PLANS}
						element={<PricingPlans />}
					/>
					<Route
						path={SUPER_ADMIN_ROUTES.PRICING_PLANS_ADD}
						element={<PricingPlanAddScreen />}
					/>
					<Route
						path={`${SUPER_ADMIN_ROUTES.PRICING_PLANS_EDIT}/:pricing_plan_id`}
						element={<PricingPlanEditScreen />}
					/>
					{/* ---------------Super Admin End--------------- */}
					{!Object.values(PUBLIC_ROUTES).includes(location.pathname) ? (
						<Route path="*" element={<Navigate to={APP_ROUTES.DASHBOARD} />} />
					) : (
						<></>
					)}
				</Route>
			) : (
				<></>
			)}
		</Routes>
	);
};

export default AppRoutes;
