import { Avatar, Box, Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { Controller, useForm } from 'react-hook-form';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import MailIcon from '@mui/icons-material/Mail';

import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';

import { APP_ROUTES } from '../../../common/constants/Routes';
import { useTranslation } from 'react-i18next';
import theme from '../../../common/theme';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CustomButton from '../../../common/ui/CustomButton';
import {
	getPartyListRequest,
	getProfileInfo,
	getProfileRequest,
	getprofileStatus,
	partyListSuccess,
	selectPartiesList,
	updateProfileData,
	updateProfileLoading,
	updateProfileRequest,
	updateProfileStatus,
} from '../../../store/profile/_profile.slice';
import { useAppSelector } from '../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import CircularIndeterminate from '../../../common/ui/CircularLoading';
import { updatePublicRequest } from '../../../store/public/_public.slice';
import { setAlertInfo } from '../../../common/Common.slice';
import { candidatesListInProgress } from '../../../store/candidates/_candidates.slice';

const CandidateProfile = ({ editData }: { editData?: any }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [passwordMatch, setPasswordMatch] = useState(true);
	const [imageUrl, setImageUrl] = useState('');
	const [base64, setBase64] = useState(''); 
	const [loading, setLoading] = useState(false);
	const getProfileData = useSelector(getProfileInfo);
	const updateProfilestatus = useSelector(updateProfileLoading);
	const updatedProfileData = useSelector(updateProfileData);
	const loadingProfileData = useSelector(getprofileStatus);
	const role_id = localStorage.getItem('role_id');
	console.log('getProfileDatagetProfileDatagetProfileData', role_id === '3');
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		setValue,
		trigger,
		getValues,

		resetField,
	} = useForm();
	useEffect(() => {
		if (loadingProfileData === 'done') {
			if (getProfileData) {
				setValue('full_name', getProfileData.name);
				setValue('email', getProfileData.email);
				setValue('phone', getProfileData.phone);
				setValue(
					'address',
					role_id === '2'
						? getProfileData?.candidate_address
						: role_id === '3'
						? getProfileData?.people_address
						: getProfileData?.admin_address || ''
				);
			}
		}
	}, [loadingProfileData]);
	const handleReset = () => {
		setValue('full_name', '');
		setValue('phone', '');
		setValue('address', '');
		setSelectedImage(null);
	};

	const [selectedImage, setSelectedImage] = useState(null);

	const handleImageChange = (event: any) => {
		const file = event.target.files[0];
		console.log('selected', selectedImage, file);
		setSelectedImage(file);
		const reader = new FileReader();
		reader.onload = () => {
			if (reader.result) {
				let base64String = reader.result.toString();
				base64String = base64String.replace(/^data:.*?;base64,/, '');
				setBase64(base64String);
			}
		};

		reader.readAsDataURL(file);
	};
	const fetchImageAndConvertToBase64 = async (
		imageUrl: string,
		setBase64: React.Dispatch<React.SetStateAction<string>>
	) => {
		try {
			const response = await fetch(imageUrl);
			const blob = await response.blob();
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				const base64String = reader.result as string; // Ensure result is string
				setBase64(base64String);
			};
		} catch (error) {
			console.error('Error fetching image and converting to base64:', error);
		}
	};

	const onSubmit = (data: any) => {
		if (!selectedImage) {
			const imageUrl = getProfileData?.profile_url;
			fetchImageAndConvertToBase64(imageUrl, setBase64);
		}
		data.id = location.state?.data?.id;
		data.user_id = location.state?.data?.user_id;
		const payload = {
			public_id:
				role_id === '2'
					? getProfileData?.candidate_id
					: role_id === '3'
					? getProfileData?.people_id
					: getProfileData?.id,
			data: {
				full_name: data?.full_name,
				email: data?.email,
				contact: data?.phone,
				address: data?.address,
				profile_url: base64,
			},
		};

		dispatch(updateProfileRequest(payload as any));

		// navigate(APP_ROUTES.PUBLIC_LIST);
	};
	const mobileNo = watch('phone');
	const parseMobileNo = (mobileNo: any) => {
		const allowedFirstDigits = ['6', '7', '8', '9'];
		const numberPattern = /\d+/g;
		const matches: any[] | null = String(mobileNo).match(numberPattern);
		if (matches) {
			const digits = matches.join('');
			if (allowedFirstDigits.includes(digits.charAt(0))) {
				return digits.substring(0, 10);
			}
		}
		return '';
	};
	useEffect(() => {
		if (mobileNo) {
			setValue('phone', parseMobileNo(mobileNo));
		}
	}, [mobileNo]);
	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		console.log('emial');
		const emailValue = e.target.value.replace(/[^\w\s@.]/gi, '');
		setValue('email', emailValue, { shouldValidate: true });
		trigger('email');
	};

	useEffect(() => {
		dispatch(getProfileRequest());
	}, []);

	useEffect(() => {
		if (loadingProfileData === 'loading' || updateProfilestatus) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [loadingProfileData, updateProfilestatus]);

	// useEffect(() => {
	// 	if (partyStatus === 'done') {
	// 		setPartyListData(
	// 			partyList &&
	// 				partyList?.map((item: any, index: any) => {
	// 					return {
	// 						Code: index + 1,
	// 						Description: item.name,
	// 						Category: item.name,
	// 					};
	// 				})
	// 		);
	// 	}
	// }, [partyStatus]);

	// useEffect(() => {
	// 	if (getProfileData) {
	// 		setImageUrl(getProfileData?.profile_url);
	// 		setSelectedImage(true as any);
	// 	}
	// }, []);
	return (
		<Grid className="mt-30">
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					display="flex"
					justifyContent="center"
					// className='login-form'
				>
					<Grid
						item
						xs={12}
						p={2}
						display={'flex'}
						justifyContent={'space-between'}
					>
						<Typography
							fontSize={20}
							fontWeight={600}
							color={theme.palette.info.dark}
						>
							{t('MY_PROFILE')}
						</Typography>
						<CustomButton
							type="button"
							text={t('GO_BACK')}
							startIcon={SubdirectoryArrowRightIcon}
							color={'primary'}
							className="p-8 color-gray"
							onClick={() => {
								navigate(APP_ROUTES.DASHBOARD);
							}}
						/>
					</Grid>
					{loading ? (
						<Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
							<CircularIndeterminate />
						</Grid>
					) : (
						<>
							{/* <Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								p={2}
								style={{ display: 'flex', justifyContent: 'center' }}
							>
								<div
									style={{
										textAlign: 'center',
										border: '2px solid orange',
										borderRadius: '50%',
									}}
								>
									<>
										<label htmlFor="upload-image">
											<Badge
												badgeContent={<AddAPhotoIcon />}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'right',
												}}
											>
												{selectedImage ? (
													<img
														src={
															selectedImage
																? URL.createObjectURL(selectedImage)
																: imageUrl
														}
														alt="Selected"
														style={{
															height: '100px',
															width: '90px',
															borderRadius: '50%',
														}}
													/>
												) : (
													<PersonIcon sx={{ fontSize: 80 }} />
												)}
											</Badge>
										</label>
										<input
											id="upload-image"
											type="file"
											accept="image/*"
											style={{ display: 'none' }}
											onChange={handleImageChange}
										/>
									</>
								</div>
							</Grid> */}
							<Grid
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								xl={12}
								p={2}
								style={{ display: 'flex', justifyContent: 'center' }}
							>
								<div
									style={{
										textAlign: 'center',
										border: '2px solid orange',
										borderRadius: '50%',
									}}
								>
									<>
										<label htmlFor="upload-image">
											<Badge
												badgeContent={<AddAPhotoIcon />}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'right',
												}}
											>
												{selectedImage ? (
													<img
														src={URL.createObjectURL(selectedImage)}
														alt="Selected"
														style={{
															height: '100px',
															width: '90px',
															borderRadius: '50%',
														}}
													/>
												) : getProfileData?.profile_url ? (
													<img
														src={getProfileData.profile_url}
														alt="Profile"
														style={{
															height: '100px',
															width: '90px',
															borderRadius: '50%',
														}}
													/>
												) : (
													<PersonIcon sx={{ fontSize: 80 }} />
												)}
											</Badge>
										</label>
										<input
											id="upload-image"
											type="file"
											accept="image/*"
											style={{ display: 'none' }}
											onChange={handleImageChange}
										/>
									</>
								</div>
							</Grid>

							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
								<LabelWithIcon
									label={t('FULL_NAME')}
									justifyContent="flex-start"
									className="mb-6  "
									labelProps={{ color: 'black' }}
								/>
								<FormInput
									name="full_name"
									type="text"
									placeholder={`${t('FULL_NAME')}...`}
									control={control}
									className="input-from"
									rules={{
										required: {
											value: true,
											message: t('PLEASE_ENTER_FULL_NAME'),
										},
									}}
									inputProps={{
										...register('full_name', {}),
									}}
									errors={errors}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
								<LabelWithIcon
									label={t('EMAIL')}
									justifyContent="flex-start"
									className="mb-6"
									labelProps={{ color: 'black' }}
								/>
								<FormInput
									name="email"
									type="text"
									placeholder={`${t('EMAIL')}...`}
									control={control}
									className="input-from"
									disabled={true}
									rules={{
										required: {
											value: false,
											message: t('PLEASE_ENTER_EMAIL'),
										},
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
											message: t('INVALID_EMAIL_ADDRESS'),
										},
									}}
									inputProps={{
										...register('email', {}),
										onChange: handleEmailChange,
									}}
									errors={errors}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
								<LabelWithIcon
									label={t('MOBILE_NUMBER')}
									justifyContent="flex-start"
									className="mb-6 "
									labelProps={{ color: 'black' }}
								/>
								<FormInput
									name="phone"
									type="text"
									placeholder={`${t('MOBILE')}...`}
									control={control}
									className="input-from"
									rules={{
										required: {
											value: true,
											message: t('PLEASE_ENTER_MOBILE_NUMBER'),
										},
										maxLength: {
											value: 10,
											message: t('MOBILE_NUMBER_SHOULD_NOT_EXCEED'),
										},
										pattern: {
											value: /^[0-9]*$/,
											message: t('ONLY_NUMERIC_CHARACTERS_ALLOWED'),
										},
										minLength: {
											value: 10,
											message: t(
												'MOBILE_NUMBER_SHOULD_BE ATLEAST_10_DIGITS_LONG'
											),
										},
									}}
									inputProps={{
										...register('phone', {}),
									}}
									errors={errors}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
								<LabelWithIcon
									label={t('ADDRESS')}
									justifyContent="flex-start"
									className="mb-6"
									labelProps={{ color: 'black' }}
								/>
								<FormInput
									// hideCountButton
									name="address"
									type="text"
									multipleLine
									placeholder={`${t('ADDRESS')}...`}
									control={control}
									className="input-from-text"
									rules={{
										required: {
											value: true,
											message: t('PLEASE_ENTER_A_ADDRESS'),
										},
									}}
									inputProps={{
										...register('address', {}),
									}}
									errors={errors}
								/>
							</Grid>
						</>
					)}

					<Grid item xs={12} justifyContent="flex-end" display={'flex'} my={5}>
						<CustomButton
							type="button"
							text={t('RESET')}
							color={'primary'}
							className="p-12 mx-10 color-gray"
							onClick={handleReset}
						/>
						<CustomButton
							type="submit"
							text={t('EDIT_PROFILE')}
							color={'warning'}
							disabled={!passwordMatch}
							className="p-12"
						/>
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
};

export default CandidateProfile;
