import { Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import CustomButton from '../../../../common/ui/CustomButton';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import CustomSelectBox from '../../../../common/ui/Selectbox/CustomSelect';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { useTranslation } from 'react-i18next';
import theme from '../../../../common/theme';
import Badge from '@mui/material/Badge';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PersonIcon from '@mui/icons-material/Person';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TitlebarImageList from './ImageList';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import CommonModal from '../../../../common/ui/CommonModal';
import '../candidate.scss';
import { useDispatch } from 'react-redux';
import { UpdatePetetionRequest } from '../../../../store/petetions/_petetion.slice';

const ForwardModal = ({
	setOpen,
	open,
	modalType,
	petitionId,
	viewPetitionData,
	handleNavigate,
}: {
	setOpen?: any;
	open?: any;
	modalType?: any;
	petitionId?: any;
	viewPetitionData: any;
	handleNavigate: any;
}) => {
	const { t } = useTranslation();
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [selectedImages, setSelectedImages] = useState<File[]>([]);
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [selectedImagePreviews, setSelectedImagePreviews] = useState<any[]>([]);
	const [selectedFilePreviews, setSelectedFilePreviews] = useState<any[]>([]);
	// const dispatch = useDispatch();
	const [passwordMatch, setPasswordMatch] = useState(true);
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		setValue,
		trigger,
	} = useForm({
		defaultValues: {
			description: '',
			testPhotos: '',
		},
	});

	const handleReset = () => {
		setValue('description', '');
		setSelectedImages([]);
		setSelectedFiles([]);
		setSelectedImagePreviews([]);
		setSelectedFilePreviews([]);
	};

	const [selectedImage, setSelectedImage] = useState(null);

	const handleImageChange = (event: any) => {
		const file = event.target.files[0];

		const reader = new FileReader();
		reader.onload = () => {
			if (reader.result) {
				let base64String = reader.result.toString();

				base64String = base64String.replace(/^data:.*?;base64,/, '');

				setSelectedImages((prevState) => [...prevState, file]);
				setSelectedImagePreviews((prevState) => [...prevState, base64String]);
			}
		};

		reader.readAsDataURL(file);
	};
	const handleDelete = (index: number) => {
		// Remove image from selectedImages
		const updatedSelectedImages = [...selectedImages];
		updatedSelectedImages.splice(index, 1);
		setSelectedImages(updatedSelectedImages);

		// Remove image from selectedImagePreviews
		const updatedSelectedImagePreviews = [...selectedImagePreviews];
		updatedSelectedImagePreviews.splice(index, 1);
		setSelectedImagePreviews(updatedSelectedImagePreviews);
	};
	const handleDeleteFile = (indexToDelete: any) => {
		const updatedSelectedFiles = selectedFiles.filter(
			(_, index) => index !== indexToDelete
		);
		setSelectedFiles(updatedSelectedFiles);

		const updatedSelectedFilePreviews = selectedFilePreviews.filter(
			(_, index) => index !== indexToDelete
		);
		setSelectedFilePreviews(updatedSelectedFilePreviews);
	};
	const onSubmit = (data: any) => {
		let payload: any = {
			id: viewPetitionData?.id,
			handleNavigate,
		};

		if (modalType === 'forward') {
			data = {
				forword_description: data.description,
				forword_image: selectedImagePreviews,
				forword_pdf: selectedFilePreviews,
			};
			payload = {
				...payload,
				petetionType: 'forwarded',
				data: data,
			};
			dispatch(UpdatePetetionRequest(payload as any));
		} else if (modalType === 'resolved') {
			data = {
				resolved_description: data.description,
				resolved_image: selectedImagePreviews,
				resolved_pdf: selectedFilePreviews,
			};
			payload = {
				...payload,
				petetionType: 'resolved',
				data: data,
			};
			dispatch(UpdatePetetionRequest(payload as any));
		} else if (modalType === 'reject') {
			data = {
				reject_description: data.description,
				reject_image: selectedImagePreviews,
				reject_pdf: selectedFilePreviews,
				petitionId,
			};
			payload = {
				...payload,
				petetionType: 'reject',
				data: data,
			};
			dispatch(UpdatePetetionRequest(payload as any));
		} else {
		}

		console.log('Payload:', payload);
		// dispatch(updateTeamMemberInProgress(payload));

		// navigate(APP_ROUTES.PUBLIC_LIST);
		setOpen(false);
		handleReset();
	};

	const [fileUploaded, setFileUploaded] = useState(false);

	const handleFileChange = (event: any) => {
		console.log('event', event);
		const file = event.target.files[0];
		if (file) {
			setSelectedFiles((prevState) => [...prevState, file]);

			const reader = new FileReader();
			reader.onloadend = () => {
				let base64String = reader.result as string;
				console.log('base64String111', base64String);
				base64String = base64String.replace(/^data:.*?;base64,/, '');
				console.log('base64String', base64String);
				if (file.type === 'application/pdf') {
					console.log('base64String', base64String);
					setFileUploaded(true);
					setSelectedFilePreviews((prevState) => [...prevState, base64String]);
				} else {
					alert('Please upload a PDF file.');

					event.target.value = '';
				}
			};
			reader.readAsDataURL(file);
		}
	};
	const [modalClass, setModalClass] = useState(false);
	useEffect(() => {
		if (selectedFiles.length > 0) {
			setModalClass(true);
		} else if (selectedImages?.length > 0) {
			setModalClass(true);
		} else {
			setModalClass(false);
		}
	}, [selectedFiles, selectedImages]);
	return (
		<CommonModal
			onClose={() => setOpen(false)}
			open={open}
			modalClassName={`px-20 py-5 ${
				modalClass ? 'petition-modal' : 'setlength'
			}`}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					display="flex"
					justifyContent="center"
					className="mt-10"
					style={{ maxHeight: '350px', overflowY: 'auto' }}
				>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						display={'flex'}
						justifyContent={'space-between'}
					>
						<Typography
							fontSize={20}
							fontWeight={600}
							color={theme.palette.info.dark}
						>
							{modalType === 'forward'
								? t('FORWARD_PETITION')
								: modalType === 'resolved'
								? t('RESOLVED_PETITION')
								: modalType === 'reject'
								? t('REJECT_PETITION')
								: t('INPROGRESS_PETITION')}
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={12} xl={12} p={1}>
						<LabelWithIcon
							label={t('DESCRIPTION')}
							justifyContent="flex-start"
							className="mb-6"
							labelProps={{ color: 'black' }}
						/>
						<FormInput
							// hideCountButton
							name="description"
							type="text"
							placeholder={`${t('DESCRIPTION')}...`}
							control={control}
							className="input-from-text"
							rules={{
								required: {
									value: true,
									message: t('PLEASE_ENTER_DESCRIPTION'),
								},
							}}
							inputProps={{
								...register('description', {}),
							}}
							errors={errors}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={6}
						lg={6}
						xl={6}
						p={1}
						justifyContent="flex-end"
					>
						<LabelWithIcon
							label={t('UPLOAD_PHOTO')}
							justifyContent="flex-start"
							className="mb-3"
							labelProps={{ color: 'black' }}
						/>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							lg={6}
							xl={6}
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<div
								style={{
									border: '2px solid orange',
								}}
							>
								<label htmlFor="upload-image">
									<Badge
										badgeContent={<AddAPhotoIcon />}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'right',
										}}
									>
										<PersonIcon sx={{ fontSize: 60 }} />
									</Badge>
								</label>

								<input
									id="upload-image"
									type="file"
									accept="image/*"
									style={{ display: 'none' }}
									onChange={handleImageChange}
								/>
							</div>
						</Grid>
						{selectedImages.length >= 1 && (
							<TitlebarImageList
								selectedImages={selectedImages}
								type="modal"
								onDelete={(index) => handleDelete(index)}
							/>
						)}
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={6}
						lg={6}
						xl={6}

						// justifyContent="flex-end"
						// style={{
						// 	display: 'flex',
						// 	justifyContent: 'center',
						// }}
					>
						<LabelWithIcon
							label={t('UPLOAD_FILE')}
							justifyContent="flex-start"
							className="mb-6"
							labelProps={{ color: 'black' }}
						/>
						<input
							// {...register('testPhotos', {
							// 	validate: {
							// 		// lessThan10MB: (files) => files[0]?.size < 30000 || 'Max 30kb',
							// 		// acceptedFormats: (files) =>
							// 		//   ["image/jpeg", "image/png", "image/gif"].includes(
							// 		//     files[0]?.type
							// 		//   ) || "Only PNG, JPEG e GIF"
							// 	},
							// })}
							type="file"
							onChange={handleFileChange}
							name="testPhotos"
							accept="application/pdf"
							multiple
						/>
						<List
							component="nav"
							aria-label="main mailbox folders"
							style={{ maxHeight: '150px', overflowY: 'auto' }}
						>
							{selectedFiles.map((file, index) => (
								<>
									<ListItemButton>
										<ListItemIcon>
											<AttachFileIcon />
										</ListItemIcon>
										<ListItemText primary={file.name} />
										<IconButton onClick={() => handleDeleteFile(index)}>
											<DeleteIcon />
										</IconButton>
									</ListItemButton>
									<Divider />
								</>
							))}
						</List>
					</Grid>
					<Grid item xs={12} justifyContent="flex-end" display={'flex'}>
						<CustomButton
							type="button"
							text={t('RESET')}
							color={'primary'}
							className="p-12 mx-10 color-gray"
							onClick={handleReset}
						/>
						<CustomButton
							type="submit"
							text={t('SUBMIT')}
							color={'warning'}
							className="p-12"
						/>
					</Grid>
				</Grid>
			</form>
		</CommonModal>
	);
};

export default ForwardModal;
