import React from 'react';
import { Grid, Typography } from '@mui/material';
import CustomButton from '../../../common/ui/CustomButton';
import { useNavigate } from 'react-router-dom';
import PartieForm from './PartieForm';
import { PartieModel } from '../../../models/PartiesModels';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
	savePartieInProgress,
	selectSavePartieLoading,
} from '../../../store/parties/_parties.slice';
import { SUPER_ADMIN_ROUTES } from '../../../common/constants/Routes';
import { useTranslation } from 'react-i18next';

const PartiesAddScreen = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const isSubmitLoading = useAppSelector(selectSavePartieLoading);
	const handlePartieSubmit = (data: PartieModel) => {
		let payload: any = {
			id: '',
			name: data.name,
			handleSuccess: () => navigate(SUPER_ADMIN_ROUTES.PARTIES),
		};
		dispatch(savePartieInProgress(payload));
	};

	return (
		<Grid container direction={'column'} wrap={'nowrap'}>
			<Grid>
				<Grid className="donation-header">
					<Typography fontSize={20} fontWeight={700}>
						{t('ADD_PARTY')}
					</Typography>
					<CustomButton
						type="button"
						text={t('CANCEL')}
						className=" px-10 py-5 my-20 add-donation-button"
						fontSize={14}
						onClick={() => navigate(-1)}
						color={'warning'}
					/>
				</Grid>
			</Grid>
			<Grid>
				<PartieForm
					onSubmit={handlePartieSubmit}
					isSubmitting={isSubmitLoading}
				/>
			</Grid>
		</Grid>
	);
};

export default PartiesAddScreen;
