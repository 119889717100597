import React from 'react';
import { Grid } from '@mui/material';
import PartiesHeader from './PartiesHeader';
import PartiesTable from './PartiesTable';

const Parties = () => {
	return (
		<Grid container direction={'column'} wrap={'nowrap'}>
			<Grid>
				<PartiesHeader />
			</Grid>
			<Grid>
				<PartiesTable />
			</Grid>
		</Grid>
	);
};

export default Parties;
