import {
	Divider,
	Grid,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import '../candidate.scss';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { updatePetitionLoading } from '../../../../store/petetions/_petetion.slice';
import cryptoEncryptionDecryption from '../../../../common/crypto';
import CircularIndeterminate from '../../../../common/ui/CircularLoading';
import icon from '../../../../assets/images/political_logo.png';
import theme from '../../../../common/theme';
import Separator from '../../../../common/ui/Separator';
import { Logo } from '../../../../store/candidates/_candidates.slice';
const PdfPetition = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const contentRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [goBack, setGoback] = useState(false);
	const updateLoading = useSelector(updatePetitionLoading);
	const logo = useSelector(Logo);
	const printData = location?.state?.data?.data;
	const userInfo = localStorage.getItem('userInfo');
	const decrptionUser = JSON.parse(
		cryptoEncryptionDecryption.Decrypt(userInfo)
	);

	console.log('rint-PetitionData', printData);
	const {
		register,
		formState: { errors },
		control,
	} = useForm();

	useEffect(() => {
		if (updateLoading) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [updateLoading]);
	useEffect(() => {
		const element = document.getElementById('pdf-content');

		html2pdf()
			.from(element)
			.save('petition.pdf')
			.then(() => {
				setLoading(false);
			});
		setGoback(true);
	}, []);
	useEffect(() => {
		if (goBack) {
			navigate('/petition');
		}
	}, [goBack]);
	return (
		<Grid
			container
			justifyContent="center"
			display={'flex'}
			id="pdf-content"
			ref={contentRef}
		>
			{loading ? (
				<Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
					<CircularIndeterminate />
				</Grid>
			) : (
				<Grid item container xs={9} sm={9} md={9} lg={10} xl={10}>
					<Grid item container sm={12} md={12} lg={12} xl={12} p={1}>
						<Grid item>
							<img src={icon} style={{ width: '300px' }} />
						</Grid>
					</Grid>{' '}
					<Grid item container>
						<Grid item p={1}>
							<Typography
								justifyContent="flex-start"
								display={'flex'}
								fontSize={20}
								fontWeight={600}
							>
								Candidate Name
							</Typography>
						</Grid>
						<Grid item p={1}>
							<Typography fontSize={20}>
								: {printData?.Candidate?.full_name}
							</Typography>
						</Grid>
					</Grid>
					<Grid item sm={12} md={12} lg={12} xl={12}>
						<Separator color={theme.palette.primary.main} />
					</Grid>
					<Grid item sm={12} md={12} lg={12} xl={12} container>
						<Grid item xs={6} sm={6} md={5} lg={5} xl={5} p={1}>
							<Typography
								justifyContent="flex-start"
								display={'flex'}
								fontSize={20}
								fontWeight={600}
							>
								Full Name
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={7} lg={7} xl={7} p={1}>
							<Typography fontSize={20}> : {printData?.full_name}</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={5} lg={5} xl={5} p={1}>
							<Typography
								justifyContent="flex-start"
								display={'flex'}
								fontSize={20}
								fontWeight={600}
							>
								Mobile Number
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={7} lg={7} xl={7} p={1}>
							<Typography fontSize={20}> : {printData?.mobile}</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={5} lg={5} xl={5} p={1}>
							<Typography
								justifyContent="flex-start"
								display={'flex'}
								fontSize={20}
								fontWeight={600}
							>
								Date
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={7} lg={7} xl={7} p={1}>
							<Typography fontSize={20}>
								: {moment(printData?.createdAt).format('YYYY-MM-DD')}
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={5} lg={5} xl={5} p={1}>
							<Typography
								justifyContent="flex-start"
								display={'flex'}
								fontSize={20}
								fontWeight={600}
							>
								Address
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={7} lg={7} xl={7} p={1}>
							<Typography fontSize={20}>: {printData?.address}</Typography>
						</Grid>
					</Grid>
					<Grid item sm={12} md={12} lg={12} xl={12} container>
						<Grid item xs={6} sm={6} md={5} lg={5} xl={5} p={1}>
							<Typography
								justifyContent="flex-start"
								display={'flex'}
								fontSize={20}
								fontWeight={600}
							>
								Assembly Constituency
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={7} lg={7} xl={7} p={1}>
							<Typography fontSize={20}>
								: {printData?.Parliamentsubconstituency?.name}
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={5} lg={5} xl={5} p={1}>
							<Typography
								justifyContent="flex-start"
								display={'flex'}
								fontSize={20}
								fontWeight={600}
							>
								Email
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={7} lg={7} xl={7} p={1}>
							<Typography fontSize={20} style={{ wordWrap: 'break-word' }}>
								: {printData?.email}
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={5} lg={5} xl={5} p={1}>
							<Typography
								justifyContent="flex-start"
								display={'flex'}
								fontSize={20}
								fontWeight={600}
							>
								Subject
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={7} lg={7} xl={7} p={1}>
							<Typography fontSize={20}>: {printData?.subject}</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={5} lg={5} xl={5} p={1}>
							<Typography
								justifyContent="flex-start"
								display={'flex'}
								fontSize={20}
								fontWeight={600}
							>
								Description
							</Typography>
						</Grid>
						<Grid item xs={6} sm={6} md={7} lg={7} xl={7} p={1}>
							<Typography fontSize={20}>: {printData?.description}</Typography>
						</Grid>
					</Grid>
					{/* <>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							lg={6}
							xl={6}
							p={1}
							justifyContent="flex-end"
						>
							<Typography
								justifyContent="flex-start"
								display={'flex'}
								fontSize={20}
								fontWeight={600}
							>
								Uploaded Photo
							</Typography>

							{printData?.image_url?.map((data: any) => (
								<img
									src={data}
									style={{ width: '100px', height: '100px', padding: '2px' }}
								/>
							))}
						</Grid>
						<Grid
							item
							xs={12}
							sm={6}
							md={6}
							lg={6}
							xl={6}
							p={2}
							justifyContent="flex-end"
						>
							<LabelWithIcon
								label={t('UPLOADED_FILE')}
								justifyContent="flex-start"
								className="mb-6"
								labelProps={{ color: 'black' }}
							/>

							<List component="nav" aria-label="main mailbox folders">
								{printData?.pdf_url?.map((file: any, index: any) => (
									<>
										<ListItemButton>
											<ListItemIcon>
												<AttachFileIcon />
											</ListItemIcon>

											{printData ? (
												<CustomButton
													type="button"
													text={t('UPLOADED_FILE')}
													startIcon={DownloadIcon}
													color={'primary'}
													className="p-8"
													// onClick={() => handleDownload(file)}
												/>
											) : (
												<ListItemText
													primary={`${file.name ? file.name : file}`}
													onClick={() => handleDownload(file)}
												/>
											)}
										</ListItemButton>
										<Divider />
									</>
								))}
							</List>
						</Grid>
						{Number(decrptionUser?.role_id) === 3 && (
							<>
								{printData?.forword_image.length > 0 ||
									(printData?.forword_pdf.length > 0 && (
										<ViewImageandFile
											title="FORWARD"
											images={printData?.forword_image}
											files={printData?.forword_pdf}
										/>
									))}
								{printData?.resolved_image.length > 0 ||
									(printData?.resolved_pdf.length > 0 && (
										<ViewImageandFile
											title="RESOLVED"
											images={printData?.resolved_image}
											files={printData?.resolved_pdf}
										/>
									))}
								{printData?.rejected_image.length > 0 ||
									(printData?.rejected_pdf.length > 0 && (
										<ViewImageandFile
											title="REJECTED"
											images={printData?.rejected_image}
											files={printData?.rejected_pdf}
										/>
									))}
							</>
						)}
					</> */}
				</Grid>
			)}
		</Grid>
	);
};

export default PdfPetition;
