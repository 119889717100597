import { ConstituencyModel } from '../../models/ConstituenciesModels';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE_TYPE } from '../../models/CommonModels';
import { RootState, useAppSelector } from '../store';

interface ConstituenciesDetailsTypes extends INITIAL_STATE_TYPE {
	data: null | ConstituencyModel;
}

interface ConstituenciesListTypes extends INITIAL_STATE_TYPE {
	data: ConstituencyModel[];
}

interface InitialStateTypes {
	constituenciesList: ConstituenciesListTypes;
	constituencyDetails: ConstituenciesDetailsTypes;
	saveConstituency: INITIAL_STATE_TYPE;
}

const initialState: InitialStateTypes = {
	constituenciesList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	constituencyDetails: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: null,
	},
	saveConstituency: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
};

const ConstituenciesSlice = createSlice({
	name: 'constituencies',
	initialState,
	reducers: {
		constituenciesListInProgress: (state) => {
			state.constituenciesList.data = [];
			state.constituenciesList.loading = true;
			state.constituenciesList.success = false;
			state.constituenciesList.error = false;
			state.constituenciesList.message = null;
		},
		constituenciesListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: ConstituencyModel[]; message: string }>
		) => {
			state.constituenciesList.data = payload.data;
			state.constituenciesList.loading = false;
			state.constituenciesList.success = true;
			state.constituenciesList.message = payload.message;
		},
		constituenciesListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.constituenciesList.data = [];
			state.constituenciesList.loading = false;
			state.constituenciesList.error = true;
			state.constituenciesList.message = payload.message;
		},

		constituencyDetailsInProgress: (state, {}: PayloadAction<string>) => {
			state.constituencyDetails.loading = true;
			state.constituencyDetails.success = false;
			state.constituencyDetails.error = false;
			state.constituencyDetails.message = null;
		},
		constituencyDetailsInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: ConstituencyModel; message: string }>
		) => {
			state.constituencyDetails.data = payload.data;
			state.constituencyDetails.loading = false;
			state.constituencyDetails.success = true;
			state.constituencyDetails.message = payload.message;
		},
		constituencyDetailsInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.constituencyDetails.data = null;
			state.constituencyDetails.loading = false;
			state.constituencyDetails.error = true;
			state.constituencyDetails.message = payload.message;
		},

		saveConstituencyInProgress: (
			state,
			{}: PayloadAction<ConstituencyModel>
		) => {
			state.saveConstituency.loading = true;
			state.saveConstituency.success = false;
			state.saveConstituency.error = false;
			state.saveConstituency.message = null;
		},
		saveConstituencyInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.saveConstituency.loading = false;
			state.saveConstituency.success = true;
			state.saveConstituency.message = payload.message;
		},
		saveConstituencyInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.saveConstituency.loading = false;
			state.saveConstituency.error = true;
			state.saveConstituency.message = payload.message;
		},
	},
});

export const {
	constituencyDetailsInProgress,
	constituencyDetailsInSuccess,
	constituencyDetailsInError,

	saveConstituencyInProgress,
	saveConstituencyInSuccess,
	saveConstituencyInError,

	constituenciesListInProgress,
	constituenciesListInSuccess,
	constituenciesListInError,
} = ConstituenciesSlice.actions;

export const selectConstituenciesListLoading = (state: RootState) =>
	state.constituencies.constituenciesList.loading;
export const selectConstituenciesList = (state: RootState) =>
	state.constituencies.constituenciesList.data;

export const selectSaveConstituencyLoading = (state: RootState) =>
	state.constituencies.saveConstituency.loading;

export const selectConstituencyLoading = (state: RootState) =>
	state.constituencies.constituencyDetails.loading;
export const selectConstituencyDetails = (state: RootState) =>
	state.constituencies.constituencyDetails.data;

export default ConstituenciesSlice;
