import React from 'react';
import { Grid, Typography } from '@mui/material';
import CustomButton from '../../../common/ui/CustomButton';
import { useNavigate } from 'react-router-dom';
import PricingPlanForm from './PricingPlanForm';
import { PricingPlanModel } from '../../../models/PricingPlansModels';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
	savePricingPlanInProgress,
	selectSavePricingPlanLoading,
} from '../../../store/pricingPlans/_pricingPlans.slice';
import { SUPER_ADMIN_ROUTES } from '../../../common/constants/Routes';

const PricingPlanAddScreen = () => {
	const navigate = useNavigate();

	return (
		<Grid container direction={'column'} wrap={'nowrap'}>
			<Grid>
				<Grid className="donation-header">
					<Typography fontSize={20} fontWeight={700}>
						Add PricingPlan
					</Typography>
					<CustomButton
						type="button"
						text="Cancel"
						className=" px-10 py-5 my-20 add-donation-button"
						fontSize={14}
						onClick={() => navigate(-1)}
						color={'warning'}
					/>
				</Grid>
			</Grid>
			<Grid>
				<PricingPlanForm />
			</Grid>
		</Grid>
	);
};

export default PricingPlanAddScreen;
