import {
	Divider,
	Grid,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';

import '../candidate.scss';

import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import { useForm } from 'react-hook-form';
import TitlebarImageList from './ImageList';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import theme from '../../../../common/theme';

import { APP_ROUTES } from '../../../../common/constants/Routes';
import CustomButton from '../../../../common/ui/CustomButton';

const ViewImageandFile = ({
	title,
	images,
	files,
	desctiptionsLable,
	descriptions,
}: {
	title: any;
	images: any;
	files: any;
	desctiptionsLable?: any;
	descriptions?: any;
}) => {
	const { t } = useTranslation();

	const {
		register,
		formState: { errors },
		control,
	} = useForm();

	const handleDownload = (file: any) => {
		if (file.name) {
			const blobUrl = URL.createObjectURL(file);

			const link = document.createElement('a');
			link.href = blobUrl;
			link.setAttribute('download', file.name);
			document.body.appendChild(link);
			link.click();
			URL.revokeObjectURL(blobUrl);
		} else {
			window.open(file, '_blank');
		}
	};

	return (
		<>
			<Grid item xs={12} p={2} justifyContent="flex-end">
				<Typography
					fontSize={20}
					fontWeight={600}
					color={theme.palette.info.dark}
				>
					{t(title)}
				</Typography>
			</Grid>
			<Grid item xs={12} p={1} justifyContent="flex-end">
				<Typography>
					<LabelWithIcon
						label={desctiptionsLable}
						justifyContent="flex-start"
						className="mb-6"
						labelProps={{ color: 'black', fontSize: 20, fontWeight: 600 }}
					/>
					<Typography color={theme.palette.common.black} className="mb-6 ml-8">
						{descriptions}
					</Typography>
				</Typography>
			</Grid>
			{images.length > 0 && (
				<Grid
					item
					xs={12}
					sm={6}
					md={6}
					lg={6}
					xl={6}
					p={2}
					justifyContent="flex-end"
				>
					<LabelWithIcon
						label={t('UPLOAD_PHOTO')}
						justifyContent="flex-start"
						className="mb-6"
						labelProps={{ color: 'black' }}
					/>
					<TitlebarImageList selectedImages={images} viewImage="true" />
				</Grid>
			)}
			{files.length > 0 && (
				<Grid
					item
					xs={12}
					sm={6}
					md={6}
					lg={6}
					xl={6}
					p={2}
					justifyContent="flex-end"
				>
					<LabelWithIcon
						label={t('UPLOADED_FILE')}
						justifyContent="flex-start"
						className="mb-6"
						labelProps={{ color: 'black' }}
					/>

					<List component="nav" aria-label="main mailbox folders">
						{files?.map((file: any, index: any) => (
							<>
								<ListItemButton>
									<ListItemIcon>
										<AttachFileIcon />
									</ListItemIcon>
									{/* {editData ? ( */}
									<CustomButton
										type="button"
										text={t('DOWNLOAD_FILE')}
										startIcon={DownloadIcon}
										color={'primary'}
										className="p-8"
										onClick={() => handleDownload(file)}
									/>
									{/* ) : (
									<ListItemText
										primary={`${file.name ? file.name : file}`}
										onClick={() => handleDownload(file)}
									/>
								)} */}
								</ListItemButton>
								<Divider />
							</>
						))}
					</List>
				</Grid>
			)}
		</>
	);
};

export default ViewImageandFile;
