import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE_TYPE } from '../../models/CommonModels';
import { RootState, useAppSelector } from '../store';
import { LoadingState } from '../../common';

interface InitialStateTypes {
	publicList: {
		status: LoadingState;
		data: any;
		error: any;
		message: any;
	};
	savePublic: {
		status: LoadingState;
		data: any;
		error: any;
		message: any;
	};
	updatePublic: {
		status: LoadingState;
		data: any;
		error: any;
		message: any;
	};
	deletePublic: {
		loading: boolean;
		success: boolean;
		error: boolean;
		message: string | null;
	};
}

const initialState: InitialStateTypes = {
	publicList: {
		status: 'idle',
		data: undefined,
		error: undefined,
		message: undefined,
	},

	savePublic: {
		status: 'idle',
		data: undefined,
		error: undefined,
		message: undefined,
	},
	updatePublic: {
		status: 'idle',
		data: undefined,
		error: undefined,
		message: undefined,
	},
	deletePublic: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
};

const publicSlice = createSlice({
	name: 'public',
	initialState,
	reducers: {
		publicsListInProgress: (state) => {
			state.publicList.status = 'loading';
			state.publicList.data = undefined;
			state.publicList.error = undefined;
		},
		publicsListInSuccess: (state, { payload }) => {
			state.publicList.status = 'done';
			state.publicList.data = payload;
			state.publicList.error = undefined;
			state.publicList.message = payload.message;
		},
		publicsListInError: (state, { payload }) => {
			state.publicList.status = 'error';
			state.publicList.data = undefined;
			state.publicList.error = payload;
			state.publicList.message = payload.message;
		},
		savePublicRequest: (state) => {
			state.savePublic.status = 'loading';
			state.savePublic.data = undefined;
			state.savePublic.error = undefined;
		},
		savePublicSuccess: (state, { payload }) => {
			state.savePublic.status = 'done';
			state.savePublic.data = payload.data;
			state.savePublic.error = undefined;
			state.savePublic.message = payload.message;
		},
		savePublicError: (state, { payload }) => {
			state.savePublic.status = 'error';
			state.savePublic.data = undefined;
			state.savePublic.error = payload;
			state.savePublic.message = payload.message;
		},
		updatePublicRequest: (state) => {
			state.updatePublic.status = 'loading';
			state.updatePublic.data = undefined;
			state.updatePublic.error = undefined;
		},
		updatePublicSuccess: (state, { payload }) => {
			state.updatePublic.status = 'done';
			state.updatePublic.data = payload.data;
			state.updatePublic.error = undefined;
			state.updatePublic.message = payload.message;
		},
		updatePublicError: (state, { payload }) => {
			state.updatePublic.status = 'error';
			state.updatePublic.data = undefined;
			state.updatePublic.error = payload;
			state.updatePublic.message = payload.message;
		},

		deletepublicInProgress: (
			state,
			{}: PayloadAction<{ data: any; message: string }>
		) => {
			state.deletePublic.loading = true;
			state.deletePublic.success = false;
			state.deletePublic.error = false;
			state.deletePublic.message = null;
		},
		deletepublicInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.deletePublic.loading = false;
			state.deletePublic.success = true;
			state.deletePublic.message = payload.message;
		},
		deletepublicInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.deletePublic.loading = false;
			state.deletePublic.error = true;
			state.deletePublic.message = payload.message;
		},
		publicDefaultData: (state) => {
			state.savePublic.status = 'loading';
			state.savePublic.data = undefined;
			state.savePublic.error = undefined;
		},
	},
});

export const {
	savePublicRequest,
	savePublicSuccess,
	savePublicError,
	publicDefaultData,

	updatePublicRequest,
	updatePublicSuccess,
	updatePublicError,

	publicsListInProgress,
	publicsListInSuccess,
	publicsListInError,

	deletepublicInProgress,
	deletepublicInSuccess,
	deletepublicInError,
} = publicSlice.actions;

export const selectpublicListLoading = (state: RootState) =>
	state.public.publicList.status === 'loading';

export const selectpublicListInfo = (state: RootState) =>
	state.public.publicList.data;

export const selectpublicListError = (state: RootState) =>
	state.public.publicList.error;
export const savepublicSuccessData = (state: RootState) =>
	state.public.savePublic.status;

export default publicSlice;
