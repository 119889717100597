import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CommonModal from '../../../../common/ui/CommonModal';
import { Box, Chip, ChipProps, Grid, Typography } from '@mui/material';
import {
	PetetionLogRequest,
	petetionLogInfo,
} from '../../../../store/petetions/_petetion.slice';
import dayjs from 'dayjs';
import theme from '../../../../common/theme';
import CustomButton from '../../../../common/ui/CustomButton';
import TableComponent from '../../../../common/ui/TableComponent';
import { DataGrid } from '@mui/x-data-grid';
import { Height } from '@mui/icons-material';
import Separator from '../../../../common/Separator';

const ViewPetitionLogs = ({
	setOpen,
	open,
	petitionId,
}: {
	setOpen: any;
	open: any;

	petitionId: any;
}) => {
	const { t } = useTranslation();
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const petetionList = useSelector(petetionLogInfo);
	const [petitionTypeCount, setPetitionTypeCount] = useState();
	console.log(petetionList);

	useEffect(() => {
		console.log('petitionId', petitionId);
		const payload = {
			petetion_id: petitionId,
		};
		dispatch(PetetionLogRequest(payload as any));
	}, [petitionId]);
	const petetionStaus: any = {
		1: 'Start_Date',
		2: 'Inprogress_Date',
		3: 'Forward_Date',
		4: 'Resolved_Date',
		5: 'Reject_Date',
		6: 'Edit_Date',
	};

	const columns = [
		{
			headerName: t('STATUS'),
			field: 'event_type_status',
			sortable: false,
			disableColumnMenu: true,
			width: 110,

			renderCell: ({ value }: any) => {
				let chipColor: ChipProps['color'] = 'default';
				switch (value) {
					case 1:
						chipColor = 'primary';
						break;
					case 6:
						chipColor = 'warning';
						break;
					case 2:
						chipColor = 'info';
						break;
					case 3:
						chipColor = 'secondary';
						break;
					case 4:
						chipColor = 'success';
						break;
					case 5:
						chipColor = 'error';
						break;
					default:
						chipColor = 'default';
						break;
				}

				return (
					<Chip
						label={t(petetionStaus[value])}
						color={chipColor}
						variant="outlined"
					/>
				);
			},
		},
		{
			headerName: t('DATE'),
			field: 'createdAt',
			sortable: false,
			disableColumnMenu: true,
			width: 100,
			renderCell: ({ value }: any) => {
				return dayjs(value).format('YYYY-MM-DD');
			},
		},
		{
			headerName: t('DESCRIPTION'),
			field: 'description',
			sortable: false,
			disableColumnMenu: true,
			width: 200,
			renderCell: ({ value }: any) => {
				return (
					<Box display="flex" alignItems="center" height="100%">
						<Typography
							fontSize={12}
							sx={{
								textWrap: 'balance',
							}}
						>
							{value}
						</Typography>
					</Box>
				);
			},
		},
	];
	let chipColor: ChipProps['color'] = 'default';
	const chipColors = (value: any) => {
		switch (value) {
			case 1:
				return 'primary';
			case 6:
				return 'warning';
			case 2:
				return 'info';
			case 3:
				return 'secondary';
			case 4:
				return 'success';
			case 5:
				return 'error';
			default:
				return 'default';
		}
	};

	return (
		<CommonModal
			boxProps={{ height: 400 }}
			onClose={() => setOpen(false)}
			open={open}
			modalClassName={`p-20  `}
		>
			<Grid container justifyContent={'center'}>
				<Grid item xs={12} py={1}>
					<Typography
						fontSize={20}
						fontWeight={600}
						color={theme.palette.info.dark}
					>
						{t('PETITION_LOGS')}
					</Typography>
				</Grid>

				<>
					{petetionList?.data?.map((value: any) => {
						return (
							<>
								<Grid item xs={7} my={1}>
									<Typography>
										<Chip
											label={t(petetionStaus[value?.event_type_status])}
											color={chipColors(value?.event_type_status)}
											// variant="outlined"
										/>
									</Typography>
								</Grid>
								<Grid item xs={5} my={1}>
									<Typography>
										{dayjs(value?.createdAt).format('DD-MM-YYYY')}
									</Typography>
								</Grid>
								<Grid item xs={12} my={1}>
									<Typography fontSize={18} fontWeight={600}>
										{t('SUBJECT')}
									</Typography>
									<Typography>{value?.subject}</Typography>
								</Grid>
								<Grid item xs={12} my={1}>
									<Typography fontSize={18} fontWeight={600}>
										{t('DESCRIPTION')}
									</Typography>
									<Typography>{value?.description}</Typography>
								</Grid>
								<Grid item xs={12} my={1}>
									<Separator />
								</Grid>
							</>
						);
					})}
				</>
				<Grid
					item
					xs={12}
					justifyContent="flex-end"
					display={'flex'}
					my={1}
					mx={3}
				>
					<CustomButton
						text={t('CLOSE')}
						color={'warning'}
						className="p-12"
						onClick={() => setOpen(false)}
					/>
				</Grid>
			</Grid>
		</CommonModal>
	);
};
export default ViewPetitionLogs;
