import { CandidateModel } from '../../../models/CandidatesModels';

// export const CandidatesList: CandidateModel[] = [
// 	{
// 		id: '1',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '2',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '3',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '4',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '5',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '6',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '7',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '8',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '9',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '10',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '11',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '12',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '13',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '14',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '15',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '16',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '17',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '18',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '19',
// 		full_name: 'Arun',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// 	{
// 		id: '20',
// 		full_name: 'Zafferan',
// 		email: 'test@gmailc.com',
// 		mobile: '9952737326',
// 		address: '122A, Trichy',
// 		party_id: 'sdasdasd',
// 		constituent_id: 1,
// 	},
// ];
export const option: any = [
	{
		Code: 1,
		Description: 'MP',
		Category: 1,
	},
	{
		Code: 2,
		Description: 'MLA',
		Category: 2,
	},
];
