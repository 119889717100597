import React, { useEffect } from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';
import CustomButton from '../../../common/ui/CustomButton';
import PricingPlanForm from './PricingPlanForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
	pricingPlanDetailsInProgress,
	selectPricingPlanDetails,
	selectPricingPlanListLoading,
	selectSavePricingPlanLoading,
} from '../../../store/pricingPlans/_pricingPlans.slice';

const PricingPlanEditScreen = () => {
	const navigate = useNavigate();
	const { pricingPlan_id } = useParams();
	const dispatch = useAppDispatch();
	const isSubmitLoading = useAppSelector(selectSavePricingPlanLoading);
	const isPricingPlanDetailsLoading = useAppSelector(
		selectPricingPlanListLoading
	);
	const pricingPlanDetails = useAppSelector(selectPricingPlanDetails);

	useEffect(() => {
		if (pricingPlan_id) {
			dispatch(pricingPlanDetailsInProgress(pricingPlan_id));
		}
	}, [pricingPlan_id]);

	return (
		<Grid container direction={'column'} wrap={'nowrap'}>
			<Grid>
				<Grid className="donation-header">
					<Typography fontSize={20} fontWeight={700}>
						Edit PricingPlan
					</Typography>
					<CustomButton
						type="button"
						text="Cancel"
						className=" px-10 py-5 my-20 add-donation-button"
						fontSize={14}
						onClick={() => navigate(-1)}
						color={'warning'}
					/>
				</Grid>
			</Grid>
			<Grid>
				{isPricingPlanDetailsLoading ? (
					<Grid container justifyContent={'center'}>
						<Grid item>
							<CircularProgress color="primary" />
						</Grid>
					</Grid>
				) : (
					<PricingPlanForm
						defaultValues={pricingPlanDetails ? pricingPlanDetails : {}}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export default PricingPlanEditScreen;
