import React, { useEffect } from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';
import CustomButton from '../../../common/ui/CustomButton';
import PartieForm from './PartieForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
	partieDetailsInProgress,
	savePartieInProgress,
	selectPartieDetails,
	selectPartieListLoading,
	selectSavePartieLoading,
} from '../../../store/parties/_parties.slice';
import { PartieModel } from '../../../models/PartiesModels';
import { SUPER_ADMIN_ROUTES } from '../../../common/constants/Routes';
import { useSelector } from 'react-redux';

const PartieEditScreen = () => {
	const navigate = useNavigate();
	const { partie_id } = useParams();
	const dispatch = useAppDispatch();
	const isSubmitLoading = useAppSelector(selectSavePartieLoading);
	const isPartieDetailsLoading = useAppSelector(selectPartieListLoading);

	
	const partieDetails:any = useSelector(selectPartieDetails);
	const partyList :any= partieDetails?.data || [];
	
	


	useEffect(() => {
		if (partie_id) {
			dispatch(partieDetailsInProgress(partie_id));
		}
	}, [partie_id]);

	return (
		<Grid container direction={'column'} wrap={'nowrap'}>
			<Grid>
				<Grid className="donation-header">
					<Typography fontSize={20} fontWeight={700}>
						Edit Party
					</Typography>
					<CustomButton
						type="button"
						text="Cancel"
						className=" px-10 py-5 my-20 add-donation-button"
						fontSize={14}
						onClick={() => navigate(-1)}
						color={'warning'}
					/>
				</Grid>
			</Grid>
			<Grid>
				{isPartieDetailsLoading ? (
					<Grid container justifyContent={'center'}>
						<Grid item>
							<CircularProgress color="primary" />
						</Grid>
					</Grid>
				) : (
					<PartieForm
					
						isSubmitting={isSubmitLoading}
						defaultValues={partieDetails ? partieDetails : {}}
						type="edit"
					/>
				)}
			</Grid>
		</Grid>
	);
};

export default PartieEditScreen;
