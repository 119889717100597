import {
	axiosAuthInstance,
	axiosInstance,
	getInstance,
} from '../../common/axiosInstance';
import { PetetionRoutes } from '../../common/routes';

const axios = axiosInstance;
const getaxios = getInstance;

const {
	addPetetion,
	updatePetetion,
	editPetetion,
	getPetetion,
	getPetetionList,
	deletePetetion,
	subconstituent,
	petetionLogs,
} = PetetionRoutes;

// TODO: uncomment when working on api /petetion/list

const addPetetions = (data: any) =>
	axios.request({
		url: `${addPetetion.url}`,
		data: data,
	});
const updatePetetions = (data: any) =>
	axios.request({
		url: `${updatePetetion.url}/${data?.id}?petetionType=${data?.petetionType}`,
		method: 'PUT',
		data: data?.data ? data?.data : {},
	});

const petetionDetails = (data: any) =>
	getaxios.request({
		url: `${getPetetion.url}?petetion_id=${data?.petetion_id}`,
		method: 'GET',
	});

const petetionList = (data: any) =>
	getaxios.request({
		url: `${getPetetionList.url}?pageNo=${data?.pageNo}
		&pagesize=10&pagination_required=${data?.pagination}
		&canditate_id=${data?.canditate_id ? data?.canditate_id : ''}
		&people_id=${data?.people_id ? data?.people_id : ''}&petition_type=${
			data?.petition_type
		}`,
		method: 'GET',
	});
const editPetetions = (data: any) =>
	axios.request({
		url: `${editPetetion.url}/${data?.id}`,
		method: 'PUT',
		data: data?.datas ? data?.datas : {},
	});
const subconstituentRequsetInfo = (data: any) =>
	getaxios.request({
		url: `${subconstituent.url}?contituent_id=${data}`,
		method: 'GET',
	});
const petetionLog = (data: any) =>
	getaxios.request({
		url: `${petetionLogs.url}?petetion_id=${data?.petetion_id}`,
		method: 'GET',
	});

export {
	addPetetions,
	updatePetetions,
	petetionDetails,
	petetionList,
	editPetetions,
	subconstituentRequsetInfo,
	petetionLog,
};
