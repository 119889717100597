import { delay, put, takeLatest, call } from 'redux-saga/effects';

import {
	deletepublicInError,
	deletepublicInProgress,
	deletepublicInSuccess,
	publicsListInError,
	publicsListInProgress,
	publicsListInSuccess,
	savePublicError,
	savePublicRequest,
	savePublicSuccess,
	updatePublicError,
	updatePublicRequest,
	updatePublicSuccess,
} from './_public.slice';
import {
	publicListRequestInfo,
	savePublicRequestInfo,
	updatePublicRequestInfo,
} from './_public.service';
import { AxiosResponse } from 'axios';

function* publicListWatcher({ payload }: any) {
	try {
		const { data }: AxiosResponse<any> = yield call(
			publicListRequestInfo,
			payload
		);
		yield put(publicsListInSuccess(data));
	} catch (e: any) {
		yield put(publicsListInError(e.message));
	}
}

function* savePublicWatcher({ payload }: ReturnType<typeof savePublicRequest>) {
	const { ...requestPayload }: any = payload;
	console.log('requestPayload', requestPayload);
	try {
		const { data }: AxiosResponse<any> = yield call(
			savePublicRequestInfo,
			requestPayload
		);
		console.log('saga', data);
		yield put(savePublicSuccess(data));
	} catch (e: any) {
		yield put(savePublicError(e.message));
	}
}
function* updatePublicWatcher({
	payload,
}: ReturnType<typeof updatePublicRequest>) {
	const { ...requestPayload }: any = payload;
	console.log('requestPayload', requestPayload);
	try {
		const { data }: AxiosResponse<any> = yield call(
			updatePublicRequestInfo,
			requestPayload
		);
		console.log('saga', data);
		yield put(updatePublicSuccess(data));
	} catch (e: any) {
		yield put(updatePublicError(e.message));
	}
}
export default function* publicSaga() {
	yield takeLatest(publicsListInProgress.type, publicListWatcher);
	yield takeLatest(savePublicRequest.type, savePublicWatcher);
	yield takeLatest(deletepublicInProgress.type, savePublicWatcher);
	yield takeLatest(updatePublicRequest.type, updatePublicWatcher);
}
