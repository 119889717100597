import { PartieModel } from '../../models/PartiesModels';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE_TYPE } from '../../models/CommonModels';
import { RootState, useAppSelector } from '../store';

interface PartiesDetailsTypes extends INITIAL_STATE_TYPE {
	data: null | PartieModel;
}

interface PartiesListTypes extends INITIAL_STATE_TYPE {
	data: PartieModel[];
}

interface InitialStateTypes {
	partiesList: PartiesListTypes;
	partieDetails: PartiesDetailsTypes;
	savePartie: INITIAL_STATE_TYPE;
	updateParty:INITIAL_STATE_TYPE;
	deleteParty:INITIAL_STATE_TYPE
}

const initialState: InitialStateTypes = {
	partiesList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	partieDetails: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: null,
	},
	savePartie: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	
	updateParty: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	deleteParty: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
};

const PartieSlice = createSlice({
	name: 'parties',
	initialState,
	reducers: {
		partiesListInProgress: (state) => {
			state.partiesList.data = [];
			state.partiesList.loading = true;
			state.partiesList.success = false;
			state.partiesList.error = false;
			state.partiesList.message = null;
		},
		partiesListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: PartieModel[]; message: string }>
		) => {
			state.partiesList.data = payload.data;
			state.partiesList.loading = false;
			state.partiesList.success = true;
			state.partiesList.message = payload.message;
		},
		partiesListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.partiesList.data = [];
			state.partiesList.loading = false;
			state.partiesList.error = true;
			state.partiesList.message = payload.message;
		},

		partieDetailsInProgress: (state, {}: PayloadAction<string>) => {
			state.partieDetails.loading = true;
			state.partieDetails.success = false;
			state.partieDetails.error = false;
			state.partieDetails.message = null;
		},
		partieDetailsInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: PartieModel; message: string }>
		) => {
			state.partieDetails.data = payload.data;
			state.partieDetails.loading = false;
			state.partieDetails.success = true;
			state.partieDetails.message = payload.message;
		},
		partieDetailsInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.partieDetails.data = null;
			state.partieDetails.loading = false;
			state.partieDetails.error = true;
			state.partieDetails.message = payload.message;
		},

		savePartieInProgress: (state, {}: PayloadAction<PartieModel>) => {
			state.savePartie.loading = true;
			state.savePartie.success = false;
			state.savePartie.error = false;
			state.savePartie.message = null;
		},
		savePartieInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.savePartie.loading = false;
			state.savePartie.success = true;
			state.savePartie.message = payload.message;
		},
		savePartieInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.savePartie.loading = false;
			state.savePartie.error = true;
			state.savePartie.message = payload.message;
		},
		updatePartyInProgress: (state, {}: PayloadAction<PartieModel>) => {
			state.updateParty.loading = true;
			state.updateParty.success = false;
			state.updateParty.error = false;
			state.updateParty.message = null;
		},
		updatePartyInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.updateParty.loading = false;
			state.updateParty.success = true;
			state.updateParty.message = payload.message;
		},
		updatePartyInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.updateParty.loading = false;
			state.updateParty.error = true;
			state.updateParty.message = payload.message;
		},
		deletePartyInProgress: (state, {}: PayloadAction<PartieModel>) => {
			state.deleteParty.loading = true;
			state.deleteParty.success = false;
			state.deleteParty.error = false;
			state.deleteParty.message = null;
		},
		deletePartyInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.deleteParty.loading = false;
			state.deleteParty.success = true;
			state.deleteParty.message = payload.message;
		},
		deletePartyInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.deleteParty.loading = false;
			state.deleteParty.error = true;
			state.deleteParty.message = payload.message;
		},
	},
});

export const {
	partieDetailsInProgress,
	partieDetailsInSuccess,
	partieDetailsInError,
	savePartieInProgress,
	savePartieInSuccess,
	savePartieInError,
	updatePartyInProgress,
	updatePartyInError,
	updatePartyInSuccess,
	deletePartyInError,
	deletePartyInProgress,
	deletePartyInSuccess,
	partiesListInProgress,
	partiesListInSuccess,
	partiesListInError,
} = PartieSlice.actions;

export const selectPartiesListLoading = (state: RootState) =>
	state.parties.partiesList.loading;
export const selectPartiesList = (state: RootState) =>
	state.parties.partiesList.data;
export const partyListSuccess = (state: RootState) =>
	state.parties.partiesList.success;

export const selectSavePartieLoading = (state: RootState) =>
	state.parties.savePartie.loading;
	export const selectUpdatePartieLoading = (state: RootState) =>
	state.parties.updateParty.loading;
	export const selectDeletePartieLoading = (state: RootState) =>
	state.parties.deleteParty.loading;
export const selectPartieListLoading = (state: RootState) =>
	state.parties.partieDetails.loading;
export const selectPartieDetails = (state: RootState) =>
	state.parties.partieDetails.data;

export default PartieSlice;
