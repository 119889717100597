import { Grid, Typography, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import LoginForm from './LoginForm';
import './Login.scss';
import Slider from 'react-slick';
import ForgetPassword from './ForgetPassword';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Login.scss';
import person from '../../common/icons/40.png';
import person1 from '../../common/icons/41 (1).png';
const ForgetPasswordScreen = () => {
	const theme = useTheme();
	const settings = {
		dots: false,
		infinite: true,
		speed: 5000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true, 
		autoplaySpeed: 200,
		nextArrow: <></> 
	  };
	return (
		<Grid container justifyContent="center" className="login">
			<Grid item xs={8} className="login-image">
			<Slider {...settings} >
				<div className='person-image-head'>
				<img src={person} className="person-image" />
				</div>
				<div className='person-image-head'>
				<img src={person1} className="person-image" />
				</div>
				
				</Slider>
			</Grid>
			<Grid
				item
				xs={4}
				justifyContent="center"
				display={'flex'}
				alignItems={'center'}
				className="login-form-main"
			>
				<ForgetPassword />
			</Grid>
		</Grid>
	);
};
export default ForgetPasswordScreen;
