import { Grid, Typography, useTheme } from '@mui/material';

import { useForm, useWatch } from 'react-hook-form';
import React, { useEffect, useState } from 'react';

import './Login.scss';

import icon from '../../assets/images/political_logo.png';

import { Link, useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { APP_ROUTES, AUTH_ROUTES } from '../../common/constants/Routes';
import SvgConverter from '../../common/SvgConverter';
import FormInput from '../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../common/ui/CustomButton';
import LabelWithIcon from '../../common/ui/LabelWithIcon';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import {
	fetchLoginRequest,
	fetchOtpRequest,
} from '../../store/auth/_auth.slice';
import { setAlertInfo } from '../../common/Common.slice';
import { candidateDetailsInProgress } from '../../store/candidates/_candidates.slice';
import { candidateId } from '../../common/utils';
const LoginForm = ({ logo }: { logo: any }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const theme = useTheme();
	const navigate = useNavigate();
	const [showLanguageModal, setShowLanguageModal] = useState(false);
	const [password, setPassword] = useState(true);
	const [model, setModel] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
	} = useForm({
		defaultValues: {
			email: '',
			password: '',
		},
	});
	const username = watch('email');

	const onSubmit = (data: any) => {
		// localStorage.setItem('user', 'true');
		console.log('data', data);
		const handleSuccess = () => {
			navigate(APP_ROUTES.DASHBOARD);
			localStorage.setItem('user', data?.userName);
			window.location.reload();
			dispatch(
				setAlertInfo({
					open: true,
					type: 'SUCCESS',
					description: `Logged in Successfully`,
				})
			);
		};

		const payload: any = {
			data,
			navigate,
			handleSuccess,
		};
		dispatch(fetchLoginRequest(payload));
	};
	const handePassword = () => {
		const handleSuccess = () => {
			navigate(AUTH_ROUTES.OTP_SCREEN, {
				state: { email: username, type: 'forgetpassword' },
			});
		};
		const payload: any = {
			email: username,
			handleSuccess,
		};
		dispatch(fetchOtpRequest(payload));

		// setModel(true);
		setPassword(false);
		// handleSubmit(onSubmit);
	};
	useEffect(() => {
		dispatch(candidateDetailsInProgress(candidateId));
	}, []);
	return (
		<Grid>
			<>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid
						container
						display="flex"
						flexDirection="column"
						justifyContent="center"
						className="login-form"
					>
						<Grid justifyContent="center" display={'flex'} my={2}>
							<div className="login-logo">
								<img
									src={logo}
									className="login-logo-img"
									width={100}
									height={100}
								/>
							</div>
						</Grid>
						<Grid my={1}>
							<LabelWithIcon
								label={t('EMAIL_/_MOBILE_NUMBER')}
								// iconProps={{ height: 33 }}
								labelProps={{ color: theme.palette.common.white, fontSize: 18 }}
								justifyContent={'flex-start'}
								// className='dob-input'
							/>
						</Grid>
						<Grid item my={1}>
							<FormInput
								placeholder={`${t('ENTER_YOUR_EMAIL_/_MOBILE_NUMBER')}...`}
								className="username-input"
								name="email"
								control={control}
								rules={{
									required: t('PLEASE_ENTER_YOUR_MOBILE_NUMBER_EMAIL'),
									pattern: {
										value: /^(?:\d{10}|[^\s@]+@[^\s@]+\.[^\s@]+)$/,
										message: t('INVALID_EMAIL_ADDRESS'),
									},
								}}
								inputProps={{
									className: 'text-center',
									...register('email', {}),
								}}
								errors={errors}
							/>
						</Grid>
						<Grid my={1}>
							<LabelWithIcon
								label={t('PASSWORD')}
								// iconProps={{ height: 33 }}
								labelProps={{ color: theme.palette.common.white, fontSize: 18 }}
								justifyContent={'flex-start'}
								// className='dob-input'
							/>
						</Grid>
						<Grid item my={1}>
							<FormInput
								placeholder={`${t('ENTER_YOUR_PASSWORD')}...`}
								className="username-input"
								name="password"
								type="password"
								control={control}
								rules={{
									required: {
										value: password,
										message: t('PLEASE_ENTER_YOUR_PASSWORD'),
									},
								}}
								inputProps={{
									className: 'text-center',
									...register('password', {}),
								}}
								errors={errors}
							/>
						</Grid>
						<Grid justifyContent="flex-end" display={'flex'} my={1}>
							<Link
								to={''}
								onClick={() => {
									handePassword();
								}}
							>
								<Typography color={theme.palette.common.white}>
									{`${t('FORGET_PASSWORD')}?`}
								</Typography>{' '}
							</Link>
						</Grid>
						<Grid justifyContent="flex-end" display={'flex'} my={1}>
							<Link to={AUTH_ROUTES.SIGNUP} color={theme.palette.common.white}>
								<Typography color={theme.palette.common.white}>
									{t('DONT_HAVE_AN_ACCOUNT_SIGNUP')}
								</Typography>
							</Link>
						</Grid>
						<Grid justifyContent="center" display={'flex'} my={5}>
							<CustomButton
								type="submit"
								text={t('LOGIN')}
								className="login-button"
								color={'primary'}
							/>
							{/* <CustomButton
							text="language selector"
							className="login-button"
							color={'primary'}
							onClick={() => setShowLanguageModal(true)}
						/> */}
						</Grid>
						{/* <LanguageSelector
						open={showLanguageModal}
						setOpen={setShowLanguageModal}
						// onSubmit={handleAddressChangesSubmit}
						// onCancel={handleCancelChanges}
						contentType="LOGIN"
					/> */}
					</Grid>
				</form>
			</>
			{/* <OtpModel open={model} setOpen={setModel} /> */}
		</Grid>
	);
};

export default LoginForm;
