import { Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import '../candidate.scss';
import CustomButton from '../../../../common/ui/CustomButton';
import TableComponent from '../../../../common/ui/TableComponent';

import { GridColDef } from '@mui/x-data-grid';

import PetitionHeader from './PetitionHeader';
import { useTranslation } from 'react-i18next';
import CircularIndeterminate from '../../../../common/ui/CircularLoading';
import { useDispatch, useSelector } from 'react-redux';
import {
	PetetionDetailRequest,
	PetetionListRequest,
	selectPetetionDetailsInfo,
	selectPetetionDetailsLoading,
	selectPetetionListInfo,
	selectPetetionListLoading,
} from '../../../../store/petetions/_petetion.slice';
import cryptoEncryptionDecryption from '../../../../common/crypto';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import { Controller, useForm } from 'react-hook-form';
import CustomSelectBox from '../../../../common/ui/Selectbox/CustomSelect';
import { petitionTypes } from '../../../../common/constants/FormItems';
import Chip, { ChipProps } from '@mui/material/Chip';
const PetitionTable = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [veiwOpen, setVeiwOpen] = useState(false);
	const [petitionType, setPetitionType] = useState('0');
	const [petitionTypeCount, setPetitionTypeCount] = useState();
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [deleteId, setDeleteId] = useState();
	const petetionList = useSelector(selectPetetionListInfo);
	const loadingData = useSelector(selectPetetionListLoading);
	const userInfo = localStorage.getItem('userInfo');
	const role_id = localStorage.getItem('role_id');
	const decrptionUser = JSON.parse(
		cryptoEncryptionDecryption.Decrypt(userInfo)
	);
	const {
		formState: { errors },
		control,
		register,
		getValues,
	} = useForm();
	console.log('location.state', location.state);
	useEffect(() => {
		console.log('petition_type', petitionType);
		const payloads = {
			canditate_id: decrptionUser?.candidate_id
				? decrptionUser?.candidate_id
				: '',
			people_id: decrptionUser?.people_id ? decrptionUser?.people_id : '',
			pageNo: 1,
			pagination: true,
			petition_type: '',
		};

		dispatch(PetetionListRequest(payloads as any));
	}, []);

	const handlePagination = (data: any) => {
		console.log(
			'pagenation',
			petitionType,
			petitionType === '0' || 0 ? '' : petitionType
		);
		const payloads = {
			canditate_id: decrptionUser?.candidate_id
				? decrptionUser?.candidate_id
				: '',
			people_id: decrptionUser?.people_id ? decrptionUser?.people_id : '',
			pageNo: data?.page + 1,
			pagination: true,
			petition_type: Number(petitionType) === 0 ? '' : petitionType,
		};

		dispatch(PetetionListRequest(payloads as any));
	};

	const handleModel = () => {
		setVeiwOpen(true);
	};
	const handleDelete = () => {
		setOpenDeleteModal(false);
	};

	const columns = [
		{
			headerName: t('FULL_NAME'),
			field: 'full_name',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},
		{
			headerName: t('EMAIL'),
			field: 'email',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},
		{
			headerName: t('MOBILE_NUMBER'),
			field: 'mobile',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},
		{
			headerName: t('ADDRESS'),
			field: 'address',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},

		{
			headerName: t('CANDIDATE_NAME'),
			field: 'candidate_name',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
		},
		{
			field: 'subject',
			headerName: t('SUBJECT'),
			disableColumnMenu: true,
			sortable: false,
			flex: 1,
		},
		// {
		// 	headerName: t('DESCRIPTION'),
		// 	field: 'description',
		// 	sortable: false,
		// 	disableColumnMenu: true,
		// 	flex: 1,
		// },
		{
			headerName: t('STATUS'),
			field: 'status_name',
			sortable: false,
			disableColumnMenu: true,
			flex: 1,
			renderCell: ({ value }: any) => {
				let chipColor: ChipProps['color'] = 'default';
				switch (value) {
					case 'pending':
						chipColor = 'default';
						break;
					case 'inPrgress':
						chipColor = 'info';
						break;
					case 'forwarded':
						chipColor = 'secondary';
						break;
					case 'resolved':
						chipColor = 'success';
						break;
					case 'reject':
						chipColor = 'error';
						break;
					default:
						chipColor = 'default';
						break;
				}

				return <Chip label={value} color={chipColor} variant="outlined" />;
			},
		},
		{
			headerName: t('ACTION'),
			field: 'Action',
			sortable: false,
			disableColumnMenu: true,
			width: Number(decrptionUser?.role_id) === 3 ? 280 : 180,
			renderCell: ({ row }: any) => {
				const handleEditClick = () => {
					navigate('/petition/edit', {
						state: { data: { data: row, type: 'edit' } },
					});
				};

				const handleViewClick = () => {
					navigate('/petition/view', {
						state: { data: { data: row, type: 'view' } },
					});
				};
				const handlePrintClick = () => {
					navigate('/petition/pdf', {
						state: { data: { data: row, type: 'print' } },
					});
				};
				const handleDeleteClick = () => {
					setDeleteId(row?.id);
					setOpenDeleteModal(true);
				};

				return (
					<>
						{' '}
						<CustomButton
							text={t('PDF')}
							className="mx-3  color-gray"
							onClick={handlePrintClick}
						/>
						<CustomButton
							text={t('VIEW')}
							className="mx-3"
							color={'warning'}
							onClick={handleViewClick}
						/>
						{Number(decrptionUser?.role_id) === 3 && (
							<>
								{Number(row?.status) === 1 && (
									<CustomButton
										text={t('EDIT')}
										className="mx-3  color-gray"
										onClick={handleEditClick}
									/>
								)}
							</>
						)}
						{/* <CustomButton
							text={t('DELETE')}
							className="mx-3 color-gray"
							onClick={handleDeleteClick}
							color={'warning'}
						/> */}
					</>
				);
			},
		},
	];

	useEffect(() => {
		if (loadingData === 'loading') {
			setLoading(true);
		} else {
			setLoading(false);
		}
		if (loadingData === 'done') {
			if (petitionType === 'forward') {
				setPetitionTypeCount(petetionList?.statusCount?.forwarded);
			} else if (petitionType === 'inprogress') {
				setPetitionTypeCount(petetionList?.statusCount?.inprogress);
			} else if (petitionType === 'pending') {
				setPetitionTypeCount(petetionList?.statusCount?.pending);
			} else if (petitionType === 'inprogress') {
				setPetitionTypeCount(petetionList?.statusCount?.inprogress);
			} else if (petitionType === 'resolved') {
				setPetitionTypeCount(petetionList?.statusCount?.resolved);
			} else if (petitionType === 'reject') {
				setPetitionTypeCount(petetionList?.statusCount?.reject);
			} else {
				setPetitionTypeCount(petetionList?.totalRecords);
			}
		}
	}, [loadingData]);
	console.log('petetionList', petetionList?.forwarded, petetionList);
	const handlePetitionTypeChange = (event: any) => {
		const selectedPetitionType = event.target.value;

		setPetitionType(event.target.value);
		console.log('petitionType', selectedPetitionType);
		const payloads = {
			canditate_id: decrptionUser?.candidate_id
				? decrptionUser?.candidate_id
				: '',
			people_id: decrptionUser?.people_id ? decrptionUser?.people_id : '',
			pageNo: 1,
			pagination: true,
			petition_type: selectedPetitionType === 0 ? '' : selectedPetitionType,
		};
		console.log('payloads', payloads);
		dispatch(PetetionListRequest(payloads as any));
	};
	useEffect(() => {
		if (location.state === null) {
			setPetitionType('0');
		} else if (location.state === 1) {
			setPetitionType('1');
		} else if (location.state === 2) {
			setPetitionType('2');
		} else if (location.state === 3) {
			setPetitionType('3');
		} else if (location.state === 4) {
			setPetitionType('4');
		} else if (location.state === 5) {
			setPetitionType('5');
		}
		console.log('petitionTypepetitionType', petitionType);
		const payloads = {
			canditate_id: decrptionUser?.candidate_id
				? decrptionUser?.candidate_id
				: '',
			people_id: decrptionUser?.people_id ? decrptionUser?.people_id : '',
			pageNo: 1,
			pagination: true,
			petition_type: location.state === null ? '' : location.state,
		};

		dispatch(PetetionListRequest(payloads as any));
	}, [location.state]);
	return (
		<Grid container my={5}>
			<Grid xs={12} p={2}>
				<PetitionHeader view={Number(decrptionUser?.role_id) === 3} />
			</Grid>
			<Grid item xs={12} sm={6} md={2} lg={2} xl={2} p={2}>
				<LabelWithIcon
					label={t('PETITION_TYPE')}
					justifyContent="flex-start"
					className="mb-6  "
					labelProps={{ color: 'black' }}
				/>
				<Controller
					control={control}
					name="petition_type"
					render={({ field }) => (
						<CustomSelectBox
							value={petitionType}
							options={petitionTypes}
							ClassName="form-select"
							placeholder={`${t('PETITION_TYPE')}...`}
							inputProps={register('petition_type', {})}
							name={'petition_type'}
							error={errors}
							onChange={handlePetitionTypeChange}
							defaultValue={'all' || ''}
						/>
					)}
					rules={{ required: 'Candidate is required' }}
				/>
			</Grid>
			{loading ? (
				<>
					<Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
						<CircularIndeterminate />
					</Grid>{' '}
				</>
			) : (
				<Grid xs={12} my={5}>
					<TableComponent
						totalRecords={
							petitionTypeCount
								? petitionTypeCount
								: petetionList?.totalRecords || 0
						}
						type="petition"
						rows={petetionList?.data ? petetionList?.data : []}
						columns={columns}
						handlePagination={handlePagination}
						paginationModel={{
							page: Number(petetionList?.currentPage) - 1 || 0,
							pageSize: Number(petetionList?.pagesize) || 5,
						}}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default PetitionTable;
