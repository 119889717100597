import { Instance } from '../../common/axiosInstance';
import ROUTES from '../../common/routes';

const token = localStorage.getItem('accessToken');

export const getConstituentListEffect = (payload: any) => {
	return Instance.request({
		url: `/constituent/list?pagination_required=${payload.pagination_required}&pageNo=${payload.pageNo}&pageSize=${payload.pageSize}`,
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	});
};
