import { delay, put, call, takeLatest } from 'redux-saga/effects';
import {
	deletePartyInError,
	deletePartyInProgress,
	deletePartyInSuccess,
	partieDetailsInError,
	partieDetailsInProgress,
	partieDetailsInSuccess,
	partiesListInError,
	partiesListInProgress,
	partiesListInSuccess,
	savePartieInError,
	savePartieInProgress,
	savePartieInSuccess,
	updatePartyInError,
	updatePartyInProgress,
	updatePartyInSuccess,
} from './_parties.slice';
import { PartiesList } from '../../features/super_admin/Parties/constant';
import { addPartyEffect, deletePartyEffect, getPartyEffect, getPartyListEffect, updatePartyEffect } from './_parties.service';
import { AxiosResponse } from 'axios';

function* getPartiesListWatcher({
	payload: { handleSuccess, ...payload },
}: any) {
	try {
		yield delay(3000);
		const { data } = yield call(getPartyListEffect, payload);
		console.log('data', data);
		yield put(
			partiesListInSuccess({
				data: data,
				message: 'Success',
			})
		);
	} catch (error) {
		yield put(
			partiesListInError({
				message: 'error',
			})
		);
	}
}

function* getCandidateDetailsWatcher({
	payload,
}: ReturnType<typeof partieDetailsInProgress>) {
	try {
		const { data } = yield call(getPartyEffect, payload);
	 yield put(
		 partieDetailsInSuccess({
	 	  data: data,
		 	message: 'Success',
		 })
	 );
	} catch (error) {
		yield put(
			partieDetailsInError({
				message: 'error',
			})
		);
	}
}

function* savePartieWatcher({
    payload,
}: ReturnType<typeof savePartieInProgress>) {
    const { navigate, ...requestPayload }: any = payload;
    try {
        const { data }: AxiosResponse<any> = yield call(addPartyEffect, requestPayload.data);
        yield put(savePartieInSuccess(data));
        if (requestPayload.handleSuccess) {
            requestPayload.handleSuccess();	
        }
    } catch (e: any) {
        yield put(savePartieInError(e.message));
    }
}

function* updatePartyWatcher({
    payload,
}: ReturnType<typeof updatePartyInProgress >) {
    const { navigate, ...requestPayload }: any = payload;

    try {
		
        const { data }: AxiosResponse<any> = yield call(updatePartyEffect, requestPayload);
        yield put(updatePartyInSuccess(data));
        if (requestPayload) {
			console.log(requestPayload,"requestPayload")
            requestPayload.handleSuccess();
        }
    } catch (e: any) {
        yield put(updatePartyInError(e.message));
    }
}
function* deletePartyWatcher({
    payload,
}: ReturnType<typeof deletePartyInProgress>) {
    const { navigate, ...requestPayload }: any = payload;

    try {
		
        const { data }: AxiosResponse<any> = yield call(deletePartyEffect, requestPayload);
        yield put(deletePartyInSuccess(data));
        if (requestPayload.handleSuccess) {
            requestPayload.handleSuccess();
        }
    } catch (e: any) {
        yield put(deletePartyInError(e.message));
    }
}

export default function* PartiesSaga() {
	yield takeLatest(partiesListInProgress.type, getPartiesListWatcher);
	yield takeLatest(partieDetailsInProgress.type, getCandidateDetailsWatcher);
	yield takeLatest(savePartieInProgress.type, savePartieWatcher);
	yield takeLatest(updatePartyInProgress.type, updatePartyWatcher);
	yield takeLatest(deletePartyInProgress.type, deletePartyWatcher);
}
