import React, { useEffect, useState } from 'react';
import { Badge, Grid, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import LabelWithIcon from '../../../common/ui/LabelWithIcon';
import theme from '../../../common/theme';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PersonIcon from '@mui/icons-material/Person';
import Select from 'react-select';
import {
	constituenciesListInProgress,
	selectConstituenciesList,
} from '../../../store/constituencies/_constituencies.slice';
import { useDispatch, useSelector } from 'react-redux';
import {
	partiesListInProgress,
	selectPartiesList,
} from '../../../store/parties/_parties.slice';
import { useNavigate } from 'react-router';
import { SUPER_ADMIN_ROUTES } from '../../../common/constants/Routes';
import {
	constituentList,
	constituentListRequest,
	parlimentConstituentList,
	parlimentConstituentListRequest,
	saveCandidateInProgress,
	updateCandidateInProgress,
} from '../../../store/candidates/_candidates.slice';
import { option } from './constant';
import { useTranslation } from 'react-i18next';
import CustomSelectBox from '../../../common/ui/Selectbox/CustomSelect';
import TitlebarImageList from '../../app/candidate/petitions/ImageList';
import { setAlertInfo } from '../../../common/Common.slice';
import store from '../../../store/store';
import CoPresentIcon from '@mui/icons-material/CoPresent';

const CandidateForm = ({
	defaultValues,
	type,
	isSubmitting,
}: {
	defaultValues?: any;
	type: string;
	isSubmitting?: any;
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [designationType, setDesignationType] = useState('1');
	const [selectedImage, setSelectedImage] = useState(null);
	const [candidateProfile, setCandidateProfile] = useState('');
	const [candidateLogo, setCandidateLogo] = useState('');
	const [selectedLogo, setSelectedLogo] = useState(null);
	const [carouselImages, setCarouselImages] = useState<File[]>([]);
	const [carouselBase64, setCarouselBase64] = useState<File[]>([]);
	const constituentListData: any = useSelector(constituentList);
	const parlimentConstituentListData: any = useSelector(
		parlimentConstituentList
	);
	const parties: any = useSelector(selectPartiesList);
	const {
		handleSubmit,
		formState: { errors },
		control,
		watch,
		setValue,
		register,
		trigger,
	} = useForm({
		defaultValues: {
			full_name: defaultValues?.full_name || '',
			email: defaultValues?.email || '',
			mobile: defaultValues?.mobile || '',
			address: defaultValues?.address || '',
			party_id: defaultValues?.party_id || '',
			constituent_id: defaultValues?.constituent_id || '',
			designation_status: defaultValues?.desginations_status || '',
			parliament_constituencies_id:
				defaultValues?.parliament_constituencies_id || '',
		},
	});
	console.log('defaultValues', defaultValues);
	useEffect(() => {
		const payload: any = {
			pageNo: 1,
			pagination_required: false,
		};
		dispatch(constituenciesListInProgress(payload));
		dispatch(partiesListInProgress(payload));
	}, []);
	const handleImageChange = (event: any) => {
		const file = event.target.files[0];
		console.log('selected', selectedImage, file);
		setSelectedImage(file);
		const reader = new FileReader();
		reader.onload = () => {
			if (reader.result) {
				let base64String = reader.result.toString();
				base64String = base64String.replace(/^data:.*?;base64,/, '');
				setCandidateProfile(base64String);
			}
		};

		reader.readAsDataURL(file);
	};
	const handleCarouselImage = (event: any) => {
		if (carouselImages.length >= 3) {
			console.log('Maximum limit reached. You can upload only three images.');
			const alertInfo = {
				open: true,
				type: 'INFO',
				description: 'Maximum limit reached. You can upload only three images.',
			};
			store.dispatch(setAlertInfo(alertInfo as any));
		} else {
			console.log('fileee', event);
			const file = event.target.files[0];
			const reader = new FileReader();
			reader.onload = () => {
				if (reader.result) {
					let base64String = reader.result.toString();
					base64String = base64String.replace(/^data:.*?;base64,/, '');
					setCarouselImages((prevState) => [...prevState, file]);
					// setCarouselBase64((prevState) => [...prevState, base64String]);
				}
			};
			reader.readAsDataURL(file);
			console.log('usesatate', carouselImages);
		}
	};
	const handleLogoChange = (event: any) => {
		const file = event.target.files[0];
		console.log('selected', selectedImage, file);
		setSelectedLogo(file);
		const reader = new FileReader();
		reader.onload = () => {
			if (reader.result) {
				let base64String = reader.result.toString();
				base64String = base64String.replace(/^data:.*?;base64,/, '');
				setCandidateLogo(base64String);
			}
		};

		reader.readAsDataURL(file);
	};
	const partiesList = parties?.data;

	const handleParty = (value: any) => {
		setValue('party_id', value, { shouldValidate: true });
	};
	const handleCandidateSubmit = (data: any) => {
		console.log(type, 'type');
		if (type === 'edit') {
			let payload: any = {
				id: defaultValues?.id,
				user_id: defaultValues?.user_id,
				full_name: data.full_name,
				email: data.email,
				mobile: data.mobile,
				address: data.address,
				party_id: data.party_id,
				desginations_status: designationType,
				// parliament_constituencies_id: data?.parliament_constituencies_id,
				// constituent_id: data.constituent_id,
				candidate_img: candidateProfile,
				candidate_carousel_img: carouselBase64,
				candidate_logo: candidateLogo,

				handleSuccess: () => navigate(SUPER_ADMIN_ROUTES.CANDIDATES),
			};
			{
				Number(designationType) === 1
					? (payload.parliament_constituencies_id =
							data?.parliament_constituencies_id)
					: (payload.constituent_id = data.constituent_id);
			}
			dispatch(updateCandidateInProgress(payload));
		} else {
			let payload: any = {
				full_name: data.full_name,
				email: data.email,
				mobile: data.mobile,
				address: data.address,
				party_id: data.party_id,
				desginations_status: designationType,
				handleSuccess: () => navigate(SUPER_ADMIN_ROUTES.CANDIDATES),
			};
			{
				Number(designationType) === 1
					? (payload.parliament_constituencies_id =
							data?.parliament_constituencies_id)
					: (payload.constituent_id = data.constituent_id);
			}
			dispatch(saveCandidateInProgress(payload));
		}
	};
	const handleDesignationChange = (event: any) => {
		setDesignationType(event.target.value);
	};
	const mobileNo = watch('mobile');
	const parseMobileNo = (mobileNo: any) => {
		const allowedFirstDigits = ['6', '7', '8', '9'];
		const numberPattern = /\d+/g;
		const matches: any[] | null = String(mobileNo).match(numberPattern);
		if (matches) {
			const digits = matches.join('');
			if (allowedFirstDigits.includes(digits.charAt(0))) {
				return digits.substring(0, 10);
			}
		}
		return '';
	};
	useEffect(() => {
		if (mobileNo) {
			setValue('mobile', parseMobileNo(mobileNo));
		}
	}, [mobileNo]);
	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		console.log('emial');
		const emailValue = e.target.value.replace(/[^\w\s@.]/gi, '');
		setValue('email', emailValue, { shouldValidate: true });
		trigger('email');
	};
	const handleDelete = (index: number) => {
		const updatedSelectedImages = [...carouselImages];
		updatedSelectedImages.splice(index, 1);
		setCarouselImages(updatedSelectedImages);

		const updatedSelectedImagePreviews = [...carouselBase64];
		updatedSelectedImagePreviews.splice(index, 1);
		setCarouselBase64(updatedSelectedImagePreviews);
	};
	useEffect(() => {
		const payload = { pagination: false, pageNo: 1, pageSize: 10 };
		dispatch(parlimentConstituentListRequest(payload as any));
		const payload1 = { pagination: false };
		dispatch(constituentListRequest(payload1 as any));
	}, []);
	useEffect(() => {
		setDesignationType(defaultValues?.desginations_status);
		// setValue('constituent_id', defaultValues?.constituent_id || '');
	}, [defaultValues]);
	return (
		<form onSubmit={handleSubmit(handleCandidateSubmit)}>
			<Grid container spacing={2}>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}
					p={2}
					style={{ display: 'flex', justifyContent: 'center' }}
				>
					<div
						style={{
							textAlign: 'center',
							border: '2px solid orange',
							borderRadius: '50%',
						}}
					>
						<>
							{' '}
							<label htmlFor="upload-image">
								<Badge
									badgeContent={<AddAPhotoIcon />}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}
								>
									{selectedImage ? (
										<img
											src={URL.createObjectURL(selectedImage)}
											alt="Selected"
											style={{
												height: '100px',
												width: '90px',
												borderRadius: '50%',
											}}
										/>
									) : (
										<PersonIcon sx={{ fontSize: 80 }} />
									)}
								</Badge>
							</label>
							{/* <label htmlFor="upload-image">
								<Badge
									badgeContent={<AddAPhotoIcon />}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}
								>
									{selectedImage ? (
										<img
											src={URL.createObjectURL(selectedImage)}
											alt="Selected"
											style={{
												height: '100px',
												width: '90px',
												borderRadius: '50%',
											}}
										/>
									) : getProfileData?.profile_url ? (
										<img
											src={getProfileData.profile_url}
											alt="Profile"
											style={{
												height: '100px',
												width: '90px',
												borderRadius: '50%',
											}}
										/>
									) : (
										<PersonIcon sx={{ fontSize: 80 }} />
									)}
								</Badge>
							</label> */}
							<input
								id="upload-image"
								type="file"
								accept="image/*"
								style={{ display: 'none' }}
								onChange={handleImageChange}
							/>
						</>
					</div>
				</Grid>
				<Grid item md={6} sm={12}>
					<FormInput
						control={control}
						name="full_name"
						label={t('FULL_NAME')}
						rules={{
							required: { value: true, message: t('PLEASE_ENTER_FULL_NAME') },
						}}
						errors={errors}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
					<LabelWithIcon
						label={t('EMAIL')}
						justifyContent="flex-start"
						className="mb-6"
						labelProps={{ color: 'black' }}
					/>
					<FormInput
						name="email"
						type="text"
						placeholder={`${t('EMAIL')}...`}
						control={control}
						className="input-from"
						rules={{
							required: {
								value: true,
								message: t('PLEASE_ENTER_EMAIL'),
							},
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: t('INVALID_EMAIL_ADDRESS'),
							},
						}}
						inputProps={{
							...register('email', {}),
							onChange: handleEmailChange,
						}}
						errors={errors}
					/>
				</Grid>
				{/* <Grid item md={6} sm={12}>
					<FormInput
						control={control}
						name="mobile"
						label={t('MOBILE_NUMBER')}
						rules={{
							required: {
								value: true,
								message: t('PLEASE_ENTER_MOBILE_NUMBER'),
							},
						}}
						errors={errors}
					/>
				</Grid> */}
				<Grid item md={6} sm={12}>
					<LabelWithIcon
						label={t('MOBILE_NUMBER')}
						justifyContent="flex-start"
						className="mb-6 "
						labelProps={{ color: 'black' }}
					/>
					<FormInput
						name="mobile"
						type="text"
						placeholder={`${t('MOBILE_NUMBER')}...`}
						control={control}
						className="input-from"
						rules={{
							required: {
								value: true,
								message: t('PLEASE_ENTER_MOBILE_NUMBER'),
							},
							maxLength: {
								value: 10,
								message: t('MOBILE_NUMBER_SHOULD_NOT_EXCEED'),
							},
							pattern: {
								value: /^[0-9]*$/,
								message: t('ONLY_NUMERIC_CHARACTERS_ALLOWED'),
							},
							minLength: {
								value: 10,
								message: t('MOBILE_NUMBER_SHOULD_BE ATLEAST_10_DIGITS_LONG'),
							},
						}}
						inputProps={{
							...register('mobile', {}),
						}}
						errors={errors}
					/>
				</Grid>
				<Grid item md={6} sm={12}>
					<FormInput
						control={control}
						name="address"
						label={t('ADDRESS')}
						rules={{
							required: { value: true, message: t('PLEASE_ENTER_A_ADDRESS') },
						}}
						errors={errors}
					/>
				</Grid>
				<Grid item md={6} sm={12}>
					<LabelWithIcon
						label={t('DESIGNATION')}
						justifyContent="flex-start"
						className="mb-6  "
						labelProps={{ color: 'black' }}
					/>
					<Controller
						control={control}
						name="designation_status"
						render={({ field }) => (
							<CustomSelectBox
								options={option}
								value={designationType}
								ClassName="form-select"
								placeholder={`${t('DESIGNATION')}...`}
								inputProps={register('designation_status', {
									required: {
										value: true,
										message: t('PLEASE_SELECT_DESIGNATION'),
									},
								})}
								name={'designation_status'}
								error={errors}
								onChange={handleDesignationChange}
								defaultValue={Number(defaultValues?.desginations_status) || ''}
							/>
						)}
					/>
				</Grid>
				{Number(designationType) === 1 ? (
					<Grid item md={6} sm={12}>
						<LabelWithIcon
							label={t('PARLIMENT_CONSTITUENCY')}
							justifyContent="flex-start"
							className="mb-6  "
							labelProps={{ color: 'black' }}
						/>
						<Controller
							control={control}
							name="parliament_constituencies_id"
							render={({ field }) => (
								<CustomSelectBox
									options={parlimentConstituentListData?.map(
										(item: any, index: any) => {
											return {
												Code: item.id,
												Description: item.name,
												Category: item.id,
											};
										}
									)}
									ClassName="form-select"
									placeholder={`${t('PARLIMENT_CONSTITUENCY')}...`}
									inputProps={register('parliament_constituencies_id', {
										required: {
											value: true,
											message: t('PLEASE_SELECT_PARLIMENT_CONSTITUENCY'),
										},
									})}
									name={'parliament_constituencies_id'}
									error={errors}
									defaultValue={
										defaultValues?.parliament_constituencies_id || ''
									}
								/>
							)}
						/>
					</Grid>
				) : (
					<>
						{Number(designationType) === 2 ? (
							<Grid item md={6} sm={12}>
								<LabelWithIcon
									label={t('CONSTITUENCY')}
									justifyContent="flex-start"
									className="mb-6  "
									labelProps={{ color: 'black' }}
								/>
								<Controller
									control={control}
									name="constituent_id"
									render={({ field }) => (
										<CustomSelectBox
											options={constituentListData?.map(
												(item: any, index: any) => {
													return {
														Code: item.id,
														Description: item.name,
														Category: item.id,
													};
												}
											)}
											ClassName="form-select"
											placeholder={`${t('CONSTITUENCY')}...`}
											inputProps={register('constituent_id', {
												required: {
													value: true,
													message: t('PLEASE_SELECT_CONSTITUENCY'),
												},
											})}
											name={'constituent_id'}
											error={errors}
											defaultValue={defaultValues?.constituent_id || ''}
										/>
									)}
								/>
							</Grid>
						) : null}
					</>
				)}
				<Grid item md={6} sm={12}>
					<LabelWithIcon
						label={t('PARTY')}
						justifyContent="flex-start"
						labelProps={{ color: theme.palette.common.black, fontSize: 18 }}
					/>
					<Controller
						control={control}
						name="party_id"
						render={({ field }) => (
							<CustomSelectBox
								options={partiesList?.map((item: any, index: any) => {
									return {
										Code: item.id,
										Description: item.name,
										Category: item.id,
									};
								})}
								ClassName="form-select"
								placeholder={`${t('PARTY')}...`}
								inputProps={register('party_id', {
									required: {
										value: true,
										message: t('PARTY_IS_REQUIRED'),
									},
								})}
								name={'party_id'}
								error={errors}
								defaultValue={defaultValues?.party_id || ''}
							/>
							// <Select
							// 	options={partiesList}
							// 	isSearchable
							// 	placeholder={t('SELECT_PARTY')}
							// 	value={
							// 		partiesList?.find(
							// 			(option: any) => option.id === watch('party_id')
							// 		) || null
							// 	}
							// 	getOptionLabel={(option: any) => `${option.name}`}
							// 	getOptionValue={(option: any) => option.id}
							// 	onChange={(selectedOption: any) => {
							// 		handleParty(selectedOption?.id || '');
							// 		field.onChange(selectedOption?.id || '');
							// 	}}
							// 	onBlur={() => field.onBlur()}
							// 	isClearable
							// />
						)}
						rules={{ required: t('PARTY_IS_REQUIRED') }}
					/>
					{/* {errors?.party_id?.message ? (
						<Typography color={theme.palette.error.main} fontSize={12}>
							<>{errors?.party_id?.message}</>
						</Typography>
					) : null} */}
				</Grid>
				{/* <Grid
					item
					xs={12}
					sm={6}
					md={6}
					lg={6}
					xl={6}
					justifyContent="flex-end"
				>
					<LabelWithIcon
						label={t('UPLOAD_LOGO')}
						justifyContent="flex-start"
						className="mb-6"
						labelProps={{ color: theme.palette.common.black, fontSize: 18 }}
					/>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						p={1}
						style={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<div>
							<label htmlFor="upload-logo">
								<CoPresentIcon sx={{ fontSize: 60 }} />

								<CustomButton
									text={'UPLOAD_LOGO'}
									startIcon={ArchiveIcon}
									type="button"
									color={'primary'}
									className=" px-10 py-5 my-20 add-donation-button"
								/>
							</label>
							<input
								id="upload-logo"
								type="file"
								accept="image/*"
								style={{ display: 'none' }}
								onChange={handleLogoChange}
							/>
						</div>
					</Grid>{' '}
					{selectedLogo && (
						<Grid container justifyContent="center">
							<img
								src={URL.createObjectURL(selectedLogo)}
								alt="Selected Logo"
								style={{
									height: '100px',
									width: '100px',
									borderRadius: '50%',
								}}
							/>
						</Grid>
					)}
				</Grid> */}

				{/* <Grid
					item
					xs={12}
					sm={6}
					md={6}
					lg={6}
					xl={6}
					p={2}
					justifyContent="flex-end"
				>
					<LabelWithIcon
						label={t('UPLOAD_PHOTO')}
						justifyContent="flex-start"
						className="mb-6"
						labelProps={{ color: theme.palette.common.black, fontSize: 18 }}
					/>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						lg={12}
						xl={12}
						p={2}
						style={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<div
							style={{
								border: '2px solid orange',
							}}
						>
							<label htmlFor="upload-carousel">
								<Badge
									badgeContent={<AddAPhotoIcon />}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}
								>
									<PersonIcon sx={{ fontSize: 60 }} />
								</Badge>
							</label>

							<input
								id="upload-carousel"
								type="file"
								accept="image/*"
								style={{ display: 'none' }}
								onChange={handleCarouselImage}
							/>
						</div>
					</Grid>

					{carouselImages.length >= 1 && (
						<TitlebarImageList
							selectedImages={carouselImages}
							onDelete={(index) => handleDelete(index)}
						/>
					)}
				</Grid> */}
			</Grid>
			<Grid container justifyContent={'center'}>
				<CustomButton
					type="submit"
					text={t('SUBMIT')}
					className=" px-10 py-5 my-20 add-donation-button"
					fontSize={14}
					color={'primary'}
					// loading={isSubmitting}
				/>
			</Grid>
		</form>
	);
};

export default CandidateForm;
