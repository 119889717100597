import { Grid, Typography } from '@mui/material';
import React from 'react';

// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import '../admin.scss';
import AddIcon from '@mui/icons-material/Add';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import CustomButton from '../../../../common/ui/CustomButton';
function CandidateHeader() {
	const navigate = useNavigate();
	const handleNavigate = () => {
		navigate(APP_ROUTES.DASHBOARD);
	};
	return (
		<Grid className="donation-header">
			<Typography fontSize={20} fontWeight={700}>
				Donations
			</Typography>
			<CustomButton
				type="button"
				text="ADD Donation"
				startIcon={AddIcon}
				className=" px-10 py-5 my-20 add-donation-button"
				fontSize={14}
				onClick={() => {
					navigate(APP_ROUTES.CANDIDATE);
				}}
				color={'warning'}
			/>
		</Grid>
	);
}

export default CandidateHeader;
