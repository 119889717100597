/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { CircularProgress, FormHelperText, useTheme } from '@mui/material';
import theme from '../../theme';

interface Props {
	label?: string | undefined;
	options: [] | any;
	value?: any | '';
	setSelectedOption?: any;
	ClassName?: string | undefined;
	dropdownWidth?: string;
	inputProps?: any;
	defaultValue?: any;
	menuProps?: any;
	loading?: boolean;
	placeholder?: any;
	error: any;
	errorTextClassName?: any;
	name: any;
	disable?: any;
	onChange?: any;
	// relationDisable?: boolean;
}

const CustomSelectBox: React.FC<Props> = ({
	label,
	options = [],
	value,
	setSelectedOption,
	ClassName,
	dropdownWidth,
	inputProps,
	defaultValue,
	menuProps,
	loading = false,
	placeholder,
	error,
	errorTextClassName,
	name,
	disable = false,
	onChange,
	// relationDisable = false,
}) => {
	const theme = useTheme();
	const [isItemSelected, setIsItemSelected] = useState(false);
	const handleChange = (event: SelectChangeEvent<{ value: unknown }>) => {
		if (setSelectedOption) {
			setSelectedOption(event.target.value);
		}
		setIsItemSelected(true);
	};
	const labelStyles = {
		display: isItemSelected ? 'none' : 'block',
	};
	const selectStyles = {
		height: 40,
		borderRadius: '5px',
		'& .MuiOutlinedInput-root': {
			border: '1px solid blue',
			height: 40,
			borderRadius: '5px',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'blue',
		},
	};
	console.log('sdfsa', error);

	return loading ? (
		<CircularProgress />
	) : (
		<FormControl sx={selectStyles} className={`${ClassName}`}>
			<InputLabel classes={labelStyles} variant="filled">
				{label}
			</InputLabel>
			<Select
				value={value}
				onChange={onChange ? onChange : handleChange}
				defaultValue={defaultValue}
				inputProps={inputProps}
				MenuProps={menuProps}
				placeholder={'select'}
				// sx={{ height: 42 }}
			>
				{options.map((option: any, index: any) => (
					<MenuItem
						key={index}
						value={option?.Code}
						sx={{
							width: dropdownWidth,
						}}
						disabled={disable}
					>
						{option?.Description}
					</MenuItem>
				))}
			</Select>
			{error?.[name]?.message && (
				<FormHelperText
					className={`error-text ${errorTextClassName}`}
					error={false}
					sx={{ color: theme.palette.error.dark }}
				>
					{error?.[name]?.message}
				</FormHelperText>
			)}
		</FormControl>
	);
};

export default CustomSelectBox;
