import React from 'react';
import { Grid } from '@mui/material';
import PricingPlansHeader from './PricingPlansHeader';
import PricingPlansTable from './PricingPlansTable';

const PricingPlans = () => {
	return (
		<Grid container direction={'column'} wrap={'nowrap'}>
			<Grid>
				<PricingPlansHeader />
			</Grid>
			<Grid>
				<PricingPlansTable />
			</Grid>
		</Grid>
	);
};

export default PricingPlans;
