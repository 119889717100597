import { Grid, IconButton, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import CustomButton from '../../../../common/ui/CustomButton';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import DownloadIcon from '@mui/icons-material/Download';
import CustomSelectBox from '../../../../common/ui/Selectbox/CustomSelect';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { useTranslation } from 'react-i18next';
import theme from '../../../../common/theme';
import Badge from '@mui/material/Badge';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PersonIcon from '@mui/icons-material/Person';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TitlebarImageList from './ImageList';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import {
	AddPetetionRequest,
	EditPetetionRequest,
	SubconstituentListRequest,
	selectAddPetetionLoading,
	subconstituentData,
} from '../../../../store/petetions/_petetion.slice';
import DeleteIcon from '@mui/icons-material/Delete';
import cryptoEncryptionDecryption from '../../../../common/crypto';
import { constituenciesListInProgress } from '../../../../store/constituencies/_constituencies.slice';
import { selectLoginRequestInfo } from '../../../../store/auth/_auth.slice';
import CircularIndeterminate from '../../../../common/ui/CircularLoading';
const AddPetitionForm = ({ editData }: { editData?: any }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const [selectedImages, setSelectedImages] = useState<File[]>([]);
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [selectedImagePreviews, setSelectedImagePreviews] = useState<any[]>([]);
	const [selectedFilePreviews, setSelectedFilePreviews] = useState<any[]>([]);
	const [fileUploaded, setFileUploaded] = useState(false);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const [passwordMatch, setPasswordMatch] = useState(true);
	const userInfo = localStorage.getItem('userInfo');
	const decrptionUser = JSON.parse(
		cryptoEncryptionDecryption.Decrypt(userInfo)
	);
	const constituent_id = localStorage.getItem('constituent_id');
	const designation_status = localStorage.getItem('designation_status');
	const constituentList = useSelector(subconstituentData);
	const addLoading = useSelector(selectAddPetetionLoading);
	// console.log('constituentList', editData);
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		setValue,
		trigger,
		getValues,

		resetField,
	} = useForm({
		defaultValues: {
			full_name: editData?.full_name || '',
			email: editData?.email || '',
			mobile: editData?.mobile || '',
			address: editData?.address || '',
			description: editData?.description || '',
			candidate_id: editData?.candidate_id || '',
			testPhotos: '',
			subject: editData?.subject || '',
			parliament_sub_constituencies_id:
				editData?.parliament_sub_constituencies_id || '',
		},
	});

	const handleReset = () => {
		setValue('full_name', '');
		setValue('email', '');
		setValue('mobile', '');
		setValue('subject', '');
		setValue('address', '');
		setValue('description', '');
		setValue('parliament_sub_constituencies_id', '');
		setValue('testPhotos', '');
		setSelectedImages([]);
		setSelectedFiles([]);
	};
	// async function pdfUrlToBase64(pdfUrl: any) {
	// 	console.log('pdfUrl:', pdfUrl);
	// 	try {
	// 		const response = await fetch(pdfUrl);

	// 		if (!response.ok) {
	// 			throw new Error(
	// 				`Failed to fetch: ${response.status} ${response.statusText}`
	// 			);
	// 		}
	// 		const blob = await response.blob();
	// 		console.log('Blob:', blob);
	// 		return new Promise((resolve, reject) => {
	// 			const reader = new FileReader();
	// 			reader.readAsDataURL(blob);
	// 			reader.onloadend = () => {
	// 				const base64data = (reader.result as string)?.split(',')[1];
	// 				resolve(base64data);
	// 			};
	// 			reader.onerror = () => {
	// 				reject(reader.error);
	// 			};
	// 		});
	// 	} catch (error) {
	// 		console.error('Error fetching PDF:', error);
	// 		throw error; // Re-throw the error to propagate it up the call stack
	// 	}
	// }

	useEffect(() => {
		if (editData?.pdf_url) {
			setSelectedFiles(editData?.pdf_url);
			setSelectedFilePreviews(editData?.pdf_url);
		}
	}, [editData?.pdf_url]);

	useEffect(() => {
		if (editData?.image_url) {
			setSelectedImages(editData?.image_url);
			setSelectedImagePreviews(editData?.image_url);
		}
	}, [editData?.image_url]);

	const handleImageChange = (event: any) => {
		const file = event.target.files[0];
		const reader = new FileReader();
		reader.onload = () => {
			if (reader.result) {
				let base64String = reader.result.toString();
				base64String = base64String.replace(/^data:.*?;base64,/, '');
				setSelectedImages((prevState) => [...prevState, file]);
				setSelectedImagePreviews((prevState) => [...prevState, base64String]);
			}
		};
		reader.readAsDataURL(file);
	};

	const mobileNo = watch('mobile');
	const parseMobileNo = (mobileNo: any) => {
		const allowedFirstDigits = ['6', '7', '8', '9'];
		const numberPattern = /\d+/g;
		const matches: any[] | null = String(mobileNo).match(numberPattern);
		if (matches) {
			const digits = matches.join('');
			if (allowedFirstDigits.includes(digits.charAt(0))) {
				return digits.substring(0, 10);
			}
		}
		return '';
	};
	useEffect(() => {
		if (mobileNo) {
			setValue('mobile', parseMobileNo(mobileNo));
		}
	}, [mobileNo]);
	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		console.log('emial');
		const emailValue = e.target.value.replace(/[^\w\s@.]/gi, '');
		setValue('email', emailValue, { shouldValidate: true });
		trigger('email');
	};

	const handleFileChange = (event: any) => {
		const file = event.target.files[0];
		if (file) {
			// console.log(file);
			setSelectedFiles((prevState) => [...prevState, file]);

			const reader = new FileReader();
			reader.onloadend = () => {
				let base64String = reader.result as string;

				base64String = base64String.replace(/^data:.*?;base64,/, '');

				if (file.type === 'application/pdf') {
					setFileUploaded(true);
					setSelectedFilePreviews((prevState) => [...prevState, base64String]);
				} else {
					alert('Please upload a PDF file.');

					event.target.value = '';
				}
			};
			reader.readAsDataURL(file);
		}
	};

	const handleDelete = (index: number) => {
		const updatedSelectedImages = [...selectedImages];
		updatedSelectedImages.splice(index, 1);
		setSelectedImages(updatedSelectedImages);

		const updatedSelectedImagePreviews = [...selectedImagePreviews];
		updatedSelectedImagePreviews.splice(index, 1);
		setSelectedImagePreviews(updatedSelectedImagePreviews);
	};
	const handleDeleteFile = (indexToDelete: any) => {
		const updatedSelectedFiles = selectedFiles.filter(
			(_, index) => index !== indexToDelete
		);
		setSelectedFiles(updatedSelectedFiles);
		const updatedSelectedFilePreviews = selectedFilePreviews.filter(
			(_, index) => index !== indexToDelete
		);
		setSelectedFilePreviews(updatedSelectedFilePreviews);
	};
	const handleDownload = (file: any) => {
		if (file.name) {
			const blobUrl = URL.createObjectURL(file);

			const link = document.createElement('a');
			link.href = blobUrl;
			link.setAttribute('download', file.name);
			document.body.appendChild(link);
			link.click();
			URL.revokeObjectURL(blobUrl);
		} else {
			window.open(file, '_blank');
		}
	};
	const handleConstituency = (value: any) => {
		setValue('parliament_sub_constituencies_id', value, {
			shouldValidate: true,
		});
	};
	const onSubmit = (datas: any) => {
		datas.canditate_id =
			editData?.canditate_id || decrptionUser?.people_canditate_id;
		datas.email = editData?.email || decrptionUser?.email;
		const handleNavigate = () => {
			navigate(APP_ROUTES.PETITION);
		};

		if (location?.state?.data?.type === 'edit') {
			console.log('datas', selectedFilePreviews);
			const isBase64 = (str: any) => {
				const base64Regex = /^[A-Za-z0-9+/]*={0,2}$/;
				return base64Regex.test(str);
			};
			const isURL = (str: any) => {
				const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
				return urlRegex.test(str);
			};

			const urls: any = [];
			const base64Strings: any = [];
			const images_urls: any = [];
			const images_base64Strings: any = [];

			selectedFilePreviews.forEach((item) => {
				if (isBase64(item)) {
					base64Strings.push(item);
				} else if (isURL(item)) {
					urls.push(item);
				}
			});
			selectedImagePreviews.forEach((item) => {
				if (isBase64(item)) {
					images_base64Strings.push(item);
				} else if (isURL(item)) {
					images_urls.push(item);
				}
			});

			datas.petetion_image = images_base64Strings;
			datas.old_image = images_urls;
			datas.petetion_pdf = base64Strings;
			datas.old_pdf = urls;

			const payload = {
				handleNavigate,
				id: editData?.id,
				datas,
			};
			dispatch(EditPetetionRequest(payload as any));
		} else {
			datas.petetion_image = selectedImagePreviews;
			datas.petetion_pdf = selectedFilePreviews;
			const payloadData =
				designation_status == '1'
					? datas
					: {
							full_name: datas?.full_name,
							email: datas?.email,
							mobile: datas?.mobile,
							address: datas?.addesss,
							description: datas?.description,
							canditate_id: datas?.canditate_id,
							petetion_image: datas?.petetion_image,
							petetion_pdf: datas?.petetion_pdf,
					  };
			const payload = {
				handleNavigate,
				datas,
			};
			console.log('payload', payload);
			dispatch(AddPetetionRequest(payload as any));
		}
	};
	useEffect(() => {
		dispatch(SubconstituentListRequest(constituent_id as any));
	}, [designation_status]);
	useEffect(() => {
		if (addLoading) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [addLoading]);
	return (
		<Grid className="mt-30">
			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid
					container
					display="flex"
					justifyContent="center"
					// className='login-form'
				>
					<Grid
						item
						xs={12}
						p={2}
						display={'flex'}
						justifyContent={'space-between'}
					>
						<Typography
							fontSize={20}
							fontWeight={600}
							color={theme.palette.info.dark}
						>
							{location?.state?.data?.type === 'edit'
								? t('EDIT_PETITION')
								: t('ADD_PETITION')}
						</Typography>
						<CustomButton
							type="button"
							text={t('GO_BACK')}
							startIcon={SubdirectoryArrowRightIcon}
							color={'primary'}
							className="p-8 color-gray"
							onClick={() => {
								navigate(APP_ROUTES.PETITION);
							}}
						/>
					</Grid>

					{loading ? (
						<Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
							<CircularIndeterminate />
						</Grid>
					) : (
						<>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
								<LabelWithIcon
									label={t('FULL_NAME')}
									justifyContent="flex-start"
									className="mb-6  "
									labelProps={{ color: 'black' }}
								/>
								<FormInput
									name="full_name"
									type="text"
									placeholder={`${t('FULL_NAME')}...`}
									control={control}
									className="input-from"
									rules={{
										required: {
											value: true,
											message: t('PLEASE_ENTER_FULL_NAME'),
										},
									}}
									inputProps={{
										...register('full_name', {}),
									}}
									errors={errors}
								/>
							</Grid>
							{/* <Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={t('EMAIL')}
							justifyContent="flex-start"
							className="mb-6"
							labelProps={{ color: 'black' }}
						/>
						<FormInput
							name="email"
							type="text"
							placeholder={`${t('EMAIL')}...`}
							control={control}
							className="input-from"
							rules={{
								required: {
									value: true,
									message: t('PLEASE_ENTER_EMAIL'),
								},
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: t('INVALID_EMAIL_ADDRESS'),
								},
							}}
							inputProps={{
								...register('email', {}),
								onChange: handleEmailChange,
							}}
							errors={errors}
						/>
					</Grid> */}
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
								<LabelWithIcon
									label={t('MOBILE_NUMBER')}
									justifyContent="flex-start"
									className="mb-6 "
									labelProps={{ color: 'black' }}
								/>
								<FormInput
									name="mobile"
									type="text"
									placeholder={`${t('MOBILE_NUMBER')}...`}
									control={control}
									className="input-from"
									rules={{
										required: {
											value: true,
											message: t('PLEASE_ENTER_MOBILE_NUMBER'),
										},
										maxLength: {
											value: 10,
											message: t('MOBILE_NUMBER_SHOULD_NOT_EXCEED'),
										},
										pattern: {
											value: /^[0-9]*$/,
											message: t('ONLY_NUMERIC_CHARACTERS_ALLOWED'),
										},
										minLength: {
											value: 10,
											message: t(
												'MOBILE_NUMBER_SHOULD_BE ATLEAST_10_DIGITS_LONG'
											),
										},
									}}
									inputProps={{
										...register('mobile', {}),
									}}
									errors={errors}
								/>
							</Grid>
							{designation_status === '1' ? (
								<>
									<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
										<LabelWithIcon
											label={t('SUBJECT')}
											justifyContent="flex-start"
											className="mb-6  "
											labelProps={{ color: 'black' }}
										/>
										<FormInput
											name="subject"
											type="text"
											placeholder={`${t('SUBJECT')}...`}
											control={control}
											className="input-from"
											rules={{
												required: {
													value: true,
													message: t('PLEASE_ENTER_SUBJECT'),
												},
											}}
											inputProps={{
												...register('subject', {}),
											}}
											errors={errors}
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
										<LabelWithIcon
											label={t('ASSEMBLY_CONSTITUENCY')}
											justifyContent="flex-start"
											className="mb-6  "
											labelProps={{ color: 'black' }}
										/>
										<Controller
											control={control}
											name="parliament_sub_constituencies_id"
											render={({ field }) => (
												<CustomSelectBox
													options={constituentList?.map(
														(item: any, index: any) => {
															return {
																Code: item.id,
																Description: item.name,
																Category: item.id,
															};
														}
													)}
													ClassName="form-select"
													placeholder={`${t('ASSEMBLY_CONSTITUENCY')}...`}
													inputProps={register(
														'parliament_sub_constituencies_id',
														{
															required: {
																value: true,
																message: t('PLEASE_SELECT_A_CONSTITUENCY'),
															},
														}
													)}
													name={'parliament_sub_constituencies_id'}
													error={errors}
													defaultValue={
														editData?.parliament_sub_constituencies_id || ''
													}
												/>
											)}
											rules={{ required: 'Candidate is required' }}
										/>
										{/* {errors?.parliament_sub_constituencies_id?.message ? (
											<Typography
												color={theme.palette.error.main}
												fontSize={12}
											>
												<>{errors?.parliament_sub_constituencies_id?.message}</>
											</Typography>
										) : null} */}
									</Grid>
								</>
							) : (
								<></>
							)}
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
								<LabelWithIcon
									label={t('ADDRESS')}
									justifyContent="flex-start"
									className="mb-6"
									labelProps={{ color: 'black' }}
								/>
								<FormInput
									// hideCountButton
									name="address"
									type="text"
									multipleLine
									placeholder={`${t('ADDRESS')}...`}
									control={control}
									className="input-from-text"
									rules={{
										required: {
											value: true,
											message: t('PLEASE_ENTER_A_ADDRESS'),
										},
									}}
									inputProps={{
										...register('address', {}),
									}}
									errors={errors}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
								<LabelWithIcon
									label={t('DESCRIPTION')}
									justifyContent="flex-start"
									className="mb-6"
									labelProps={{ color: 'black' }}
								/>
								<FormInput
									// hideCountButton
									name="description"
									type="text"
									multipleLine
									placeholder={`${t('DESCRIPTION')}...`}
									control={control}
									className="input-from-text"
									rules={{
										required: {
											value: true,
											message: t('PLEASE_ENTER_DESCRIPTION'),
										},
									}}
									inputProps={{
										...register('description', {}),
									}}
									errors={errors}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								md={6}
								lg={6}
								xl={6}
								p={2}
								justifyContent="flex-end"
							>
								<LabelWithIcon
									label={t('UPLOAD_PHOTO')}
									justifyContent="flex-start"
									className="mb-6"
									labelProps={{ color: 'black' }}
								/>
								<Grid
									item
									xs={12}
									sm={12}
									md={12}
									lg={12}
									xl={12}
									p={2}
									style={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<div
										style={{
											border: '2px solid orange',
										}}
									>
										<label htmlFor="upload-image">
											<Badge
												badgeContent={<AddAPhotoIcon />}
												anchorOrigin={{
													vertical: 'bottom',
													horizontal: 'right',
												}}
											>
												<PersonIcon sx={{ fontSize: 60 }} />
											</Badge>
										</label>

										<input
											id="upload-image"
											type="file"
											accept="image/*"
											style={{ display: 'none' }}
											onChange={handleImageChange}
											// multiple
										/>
									</div>
								</Grid>

								{selectedImages.length >= 1 && (
									<TitlebarImageList
										selectedImages={selectedImages}
										onDelete={(index) => handleDelete(index)}
									/>
								)}
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								md={6}
								lg={6}
								xl={6}
								p={2}
								justifyContent="flex-end"
							>
								<LabelWithIcon
									label={t('UPLOAD_FILE')}
									justifyContent="flex-start"
									className="mb-6"
									labelProps={{ color: 'black' }}
								/>
								<input
									{...register('testPhotos', {
										validate: {
											// lessThan10MB: (files) => files[0]?.size < 30000 || 'Max 30kb',
											// acceptedFormats: (files) =>
											//   ["image/jpeg", "image/png", "image/gif"].includes(
											//     files[0]?.type
											//   ) || "Only PNG, JPEG e GIF"
										},
									})}
									type="file"
									onChange={handleFileChange}
									name="testPhotos"
									accept="application/pdf"
									// multiple
								/>
								<List component="nav" aria-label="main mailbox folders">
									{selectedFiles.map((file, index) => (
										<>
											<ListItemButton>
												<ListItemIcon>
													<AttachFileIcon />
												</ListItemIcon>
												{editData ? (
													<CustomButton
														type="button"
														text={t('DOWNLOAD_FILE')}
														startIcon={DownloadIcon}
														color={'primary'}
														className="p-8"
														onClick={() => handleDownload(file)}
													/>
												) : (
													<ListItemText
														primary={`${file.name ? file.name : file}`}
														onClick={() => handleDownload(file)}
													/>
												)}

												<IconButton onClick={() => handleDeleteFile(index)}>
													<DeleteIcon />
												</IconButton>
											</ListItemButton>
											<Divider />
										</>
									))}
								</List>

								{errors.testPhotos && <span>{errors.testPhotos.message}</span>}
							</Grid>
						</>
					)}

					<Grid item xs={12} justifyContent="flex-end" display={'flex'} my={5}>
						<CustomButton
							type="button"
							text={t('RESET')}
							color={'primary'}
							className="p-12 mx-10 color-gray"
							onClick={handleReset}
						/>
						<CustomButton
							type="submit"
							text={t('SUBMIT')}
							color={'warning'}
							disabled={!passwordMatch}
							className="p-12"
						/>
					</Grid>
				</Grid>
			</form>
		</Grid>
	);
};

export default AddPetitionForm;
