import React from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';
import { useTranslation } from 'react-i18next';

const ConstituencyForm = ({
	onSubmit = () => false,
	isSubmitting = false,
	defaultValues = {},
}: {
	onSubmit: (a: any) => void;
	isSubmitting?: boolean;
	defaultValues?: {} | object;
}) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues,
	});
	const { t } = useTranslation();
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={2}>
				<Grid item sm={12}>
					<FormInput
						control={control}
						name="name"
						label={t('NAME')}
						rules={{
							required: { value: true, message: t('PLEASE_ENTER_NAME') },
						}}
						errors={errors}
					/>
				</Grid>
			</Grid>
			<Grid container justifyContent={'center'}>
				<CustomButton
					type="submit"
					text={t('SUBMIT')}
					className=" px-10 py-5 my-20 add-donation-button"
					fontSize={14}
					color={'primary'}
					loading={isSubmitting}
				/>
			</Grid>
		</form>
	);
};

export default ConstituencyForm;
