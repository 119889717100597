import { Instance ,candidateInstances} from '../../common/axiosInstance';
import ROUTES from '../../common/routes';


const token=localStorage.getItem('accessToken')
export const getPartyListEffect = (payload:any) => {
    return Instance.request({
      url: `/party/list?pagination_required=${payload.pagination_required}&pageNo=${payload.pageNo}&pageSize=${payload.pageSize}`,
      method: "GET",
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  };

  export const addPartyEffect = (formData:any) => {

    return candidateInstances.request({
      url: `/party/add`,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }
  export const updatePartyEffect = (formData:any) => {
 console.log("service called",formData)
    return candidateInstances.request({
      url: `party/update/${formData?.id}`,
      method: "PUT",
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }
  export const deletePartyEffect = (formData:any) => {
   
    return candidateInstances.request({
      url: `/party/delete/${formData?.id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    });

  }
  export const getPartyEffect = (formData:any) => {
 
    return Instance.request({
      url: `/party/details/${formData}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  }