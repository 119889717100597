import { CandidateModel } from './../../models/CandidatesModels';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE_TYPE } from '../../models/CommonModels';
import { RootState, useAppSelector } from '../store';
import { LoadingState } from '../../common';

interface CandidatesDetailsTypes extends INITIAL_STATE_TYPE {
	data: null | CandidateModel;
}

interface CandidatesListTypes extends INITIAL_STATE_TYPE {
	data: CandidateModel[];
}

interface InitialStateTypes {
	candidatesList: CandidatesListTypes;
	candidateDetails: CandidatesDetailsTypes;
	saveCandidate: INITIAL_STATE_TYPE;
	updateCandidate: INITIAL_STATE_TYPE;
	deleteCandidate: INITIAL_STATE_TYPE;
	constituent: {
		status: LoadingState;
		data: any;
		error: any;
		message: any;
	};
	parlimentConstituent: {
		status: LoadingState;
		data: any;
		error: any;
		message: any;
	};
}

const initialState: InitialStateTypes = {
	candidatesList: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: [],
	},
	candidateDetails: {
		loading: false,
		success: false,
		error: false,
		message: null,
		data: null,
	},
	saveCandidate: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	updateCandidate: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	deleteCandidate: {
		loading: false,
		success: false,
		error: false,
		message: null,
	},
	constituent: {
		status: 'idle',
		data: undefined,
		error: undefined,
		message: undefined,
	},
	parlimentConstituent: {
		status: 'idle',
		data: undefined,
		error: undefined,
		message: undefined,
	},
};

const CandidateSlice = createSlice({
	name: 'candidates',
	initialState,
	reducers: {
		candidatesListInProgress: (state) => {
			state.candidatesList.data = [];
			state.candidatesList.loading = true;
			state.candidatesList.success = false;
			state.candidatesList.error = false;
			state.candidatesList.message = null;
		},
		candidatesListInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: CandidateModel[]; message: string }>
		) => {
			state.candidatesList.data = payload.data;
			state.candidatesList.loading = false;
			state.candidatesList.success = true;
			state.candidatesList.message = payload.message;
		},
		candidatesListInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.candidatesList.data = [];
			state.candidatesList.loading = false;
			state.candidatesList.error = true;
			state.candidatesList.message = payload.message;
		},

		candidateDetailsInProgress: (state, {}: PayloadAction<string>) => {
			state.candidateDetails.loading = true;
			state.candidateDetails.success = false;
			state.candidateDetails.error = false;
			state.candidateDetails.message = null;
		},
		candidateDetailsInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: CandidateModel; message: string }>
		) => {
			state.candidateDetails.data = payload.data;
			state.candidateDetails.loading = false;
			state.candidateDetails.success = true;
			state.candidateDetails.message = payload.message;
		},
		candidateDetailsInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.candidateDetails.data = null;
			state.candidateDetails.loading = false;
			state.candidateDetails.error = true;
			state.candidateDetails.message = payload.message;
		},

		saveCandidateInProgress: (state, {}: PayloadAction<CandidateModel>) => {
			state.saveCandidate.loading = true;
			state.saveCandidate.success = false;
			state.saveCandidate.error = false;
			state.saveCandidate.message = null;
		},
		saveCandidateInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.saveCandidate.loading = false;
			state.saveCandidate.success = true;
			state.saveCandidate.message = payload.message;
		},
		saveCandidateInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.saveCandidate.loading = false;
			state.saveCandidate.error = true;
			state.saveCandidate.message = payload.message;
		},
		updateCandidateInProgress: (state, {}: PayloadAction<CandidateModel>) => {
			state.updateCandidate.loading = true;
			state.updateCandidate.success = false;
			state.updateCandidate.error = false;
			state.updateCandidate.message = null;
		},
		updateCandidateInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.updateCandidate.loading = false;
			state.updateCandidate.success = true;
			state.updateCandidate.message = payload.message;
		},
		updateCandidateInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.updateCandidate.loading = false;
			state.updateCandidate.error = true;
			state.updateCandidate.message = payload.message;
		},
		deleteCandidateInProgress: (state, {}: PayloadAction<CandidateModel>) => {
			state.deleteCandidate.loading = true;
			state.deleteCandidate.success = false;
			state.deleteCandidate.error = false;
			state.deleteCandidate.message = null;
		},
		deleteCandidateInSuccess: (
			state,
			{ payload }: PayloadAction<{ data: any; message: string }>
		) => {
			state.deleteCandidate.loading = false;
			state.deleteCandidate.success = true;
			state.deleteCandidate.message = payload.message;
		},
		deleteCandidateInError: (
			state,
			{ payload }: PayloadAction<{ message: string }>
		) => {
			state.deleteCandidate.loading = false;
			state.deleteCandidate.error = true;
			state.deleteCandidate.message = payload.message;
		},
		constituentListRequest: (state) => {
			state.constituent.status = 'loading';
			state.constituent.data = undefined;
			state.constituent.error = undefined;
		},
		constituentListSuccess: (state, { payload }) => {
			console.log('payload', payload);
			state.constituent.status = 'done';
			state.constituent.data = payload.data;
			state.constituent.error = undefined;
			state.constituent.message = payload.message;
		},
		constituentListError: (state, { payload }) => {
			state.constituent.status = 'error';
			state.constituent.data = undefined;
			state.constituent.error = payload;
			state.constituent.message = payload.message;
		},
		parlimentConstituentListRequest: (state) => {
			state.parlimentConstituent.status = 'loading';
			state.parlimentConstituent.data = undefined;
			state.parlimentConstituent.error = undefined;
		},
		parlimentConstituentListSuccess: (state, { payload }) => {
			console.log('payload', payload);
			state.parlimentConstituent.status = 'done';
			state.parlimentConstituent.data = payload.data;
			state.parlimentConstituent.error = undefined;
			state.parlimentConstituent.message = payload.message;
		},
		parlimentConstituentListError: (state, { payload }) => {
			state.parlimentConstituent.status = 'error';
			state.parlimentConstituent.data = undefined;
			state.parlimentConstituent.error = payload;
			state.parlimentConstituent.message = payload.message;
		},
	},
});

export const {
	candidateDetailsInProgress,
	candidateDetailsInSuccess,
	candidateDetailsInError,
	saveCandidateInProgress,
	saveCandidateInSuccess,
	saveCandidateInError,
	candidatesListInProgress,
	candidatesListInSuccess,
	candidatesListInError,
	updateCandidateInProgress,
	updateCandidateInSuccess,
	updateCandidateInError,
	deleteCandidateInProgress,
	deleteCandidateInSuccess,
	deleteCandidateInError,
	constituentListRequest,
	constituentListSuccess,
	constituentListError,
	parlimentConstituentListRequest,
	parlimentConstituentListSuccess,
	parlimentConstituentListError,
} = CandidateSlice.actions;

export const selectCandidatesListLoading = (state: RootState) =>
	state.candidates.candidatesList.loading;
export const selectCandidatesList = (state: RootState) =>
	state.candidates.candidatesList.data;

export const carsoleImage = (state: any) =>
	state.candidates.candidateDetails.data?.data?.candidate_carousel_img;

export const Logo = (state: any) =>
	state.candidates.candidateDetails.data?.data?.candidate_logo;

export const Image = (state: any) =>
	state.candidates.candidateDetails.data?.data?.candidate_img;
export const selectSaveCandidateLoading = (state: RootState) =>
	state.candidates.saveCandidate.loading;

export const selectCandidateListLoading = (state: RootState) =>
	state.candidates.candidateDetails.loading;
export const selectCandidateDetails = (state: RootState) =>
	state.candidates.candidateDetails.data;

export const selectUpdateCandidateLoading = (state: RootState) =>
	state.candidates.updateCandidate.loading;
export const selectDeleteCandidateLoading = (state: RootState) =>
	state.candidates.deleteCandidate.loading;
export const constituentList = (state: RootState) =>
	state.candidates.constituent.data;
export const parlimentConstituentList = (state: RootState) =>
	state.candidates.parlimentConstituent.data;

export default CandidateSlice;
