import React, { useEffect } from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';
import CustomButton from '../../../common/ui/CustomButton';
import CandidateForm from './CandidateForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
	candidateDetailsInProgress,
	saveCandidateInProgress,
	selectCandidateDetails,
	selectCandidateListLoading,
	selectSaveCandidateLoading,
} from '../../../store/candidates/_candidates.slice';
import { CandidateModel } from '../../../models/CandidatesModels';
import { SUPER_ADMIN_ROUTES } from '../../../common/constants/Routes';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CandidateEditScreen = () => {
	const navigate = useNavigate();
	const { candidate_id } = useParams();
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const isSubmitLoading = useAppSelector(selectSaveCandidateLoading);
	const isCandidateDetailsLoading = useAppSelector(selectCandidateListLoading);

	const candidateDetails: any = useSelector(selectCandidateDetails);
	const candidateList: CandidateModel[] = candidateDetails?.data || [];
	console.log('candidateList', candidateList);

	useEffect(() => {
		if (candidate_id) {
			dispatch(candidateDetailsInProgress(candidate_id));
		}
	}, [candidate_id]);

	return (
		<Grid container direction={'column'} wrap={'nowrap'}>
			<Grid>
				<Grid className="donation-header">
					<Typography fontSize={20} fontWeight={700}>
						{t('EDIT_CANDIDATE')}
					</Typography>
					<CustomButton
						type="button"
						text={t('CANCEL')}
						className=" px-10 py-5 my-20 add-donation-button"
						fontSize={14}
						onClick={() => navigate(-1)}
						color={'warning'}
					/>
				</Grid>
			</Grid>
			<Grid>
				{isCandidateDetailsLoading ? (
					<Grid container justifyContent={'center'}>
						<Grid item>
							<CircularProgress color="primary" />
						</Grid>
					</Grid>
				) : (
					<CandidateForm
						isSubmitting={isSubmitLoading}
						defaultValues={candidateList}
						type="edit"
					/>
				)}
			</Grid>
		</Grid>
	);
};

export default CandidateEditScreen;
