import {
	axiosAuthInstance,
	axiosInstance,
	getInstance,
} from '../../common/axiosInstance';
import { publicRoutes } from '../../common/routes';

const axios = axiosInstance;
const getaxios = getInstance;
const { publicList, savePublic, updatePublic } = publicRoutes;

// TODO: uncomment when working on api

const publicListRequestInfo = (data: any) =>
	getaxios.request({
		url: `${publicList.url}?pageNo=${data?.pageNo}
		&pagesize=10&pagination_required=${data?.pagination_required}
		&canditate_id=${data?.canditate_id ? data?.canditate_id : ''}
		&people_id=${data?.people_id ? data?.people_id : ''}`,
		method: 'GET',
	});
const savePublicRequestInfo = (data: any) =>
	getaxios.request({
		url: savePublic.url,
		data: data,
		method: 'POST',
	});
const updatePublicRequestInfo = (data: any) =>
	getaxios.request({
		url: `${updatePublic.url}/${data?.public_id}`,
		data: data?.data,
		method: 'PUT',
	});

export {
	publicListRequestInfo,
	savePublicRequestInfo,
	updatePublicRequestInfo,
};
