import React from 'react';
import { Grid, Typography } from '@mui/material';
import CustomButton from '../../../common/ui/CustomButton';
import { useNavigate } from 'react-router-dom';
import ConstituencyForm from './ConstituencyForm';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
	saveConstituencyInProgress,
	selectSaveConstituencyLoading,
} from '../../../store/constituencies/_constituencies.slice';
import { SUPER_ADMIN_ROUTES } from '../../../common/constants/Routes';
import { ConstituencyModel } from '../../../models/ConstituenciesModels';
import { useTranslation } from 'react-i18next';

const ConstituencyAddScreen = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const isSubmitLoading = useAppSelector(selectSaveConstituencyLoading);
	const handleConstituencySubmit = (data: ConstituencyModel) => {
		let payload: any = {
			id: '',
			name: data.name,
			handleSuccess: () => navigate(SUPER_ADMIN_ROUTES.CONSTITUENCIES),
		};
		dispatch(saveConstituencyInProgress(payload));
	};

	return (
		<Grid container direction={'column'} wrap={'nowrap'}>
			<Grid>
				<Grid className="donation-header">
					<Typography fontSize={20} fontWeight={700}>
						{t('ADD_CONSTITUENCY')}
					</Typography>
					<CustomButton
						type="button"
						text={t('CANCEL')}
						className=" px-10 py-5 my-20 add-donation-button"
						fontSize={14}
						onClick={() => navigate(-1)}
						color={'warning'}
					/>
				</Grid>
			</Grid>
			<Grid>
				<ConstituencyForm
					onSubmit={handleConstituencySubmit}
					isSubmitting={isSubmitLoading}
				/>
			</Grid>
		</Grid>
	);
};

export default ConstituencyAddScreen;
