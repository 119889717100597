import {
	candidateInstances,
	getInstance,
	Instance,
} from '../../common/axiosInstance';
import ROUTES, { constituencyRoutes } from '../../common/routes';
const { constituentList, parlimentConstituentList } = constituencyRoutes;
// const axios = axiosInstance;
const getaxios = getInstance;
const token = localStorage.getItem('accessToken');
export const getdonationInstanceListEffect = (payload: any) => {
	return Instance.request({
		url: `/candidate/list?pagination_required=${payload.pagination_required}&pageNo=${payload.pageNo}&pageSize=${payload.pageSize}`,
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	});
};
export const addCandidateInstanceListEffect = (formData: any) => {
	return candidateInstances.request({
		url: `/candidate/add`,
		method: 'POST',
		data: formData,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	});
};
export const getCandidateEffect = (formData: any) => {
	return Instance.request({
		url: `/candidate/details?candidate_id=${formData}`,
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	});
};
export const updateCandidateEffect = (formData: any) => {
	return candidateInstances.request({
		url: `candidate/update/${formData.id}`,
		method: 'PUT',
		data: formData,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	});
};
export const deleteCandidateEffect = (formData: any) => {
	console.log(formData, 'formData');
	return candidateInstances.request({
		url: `/candidate/delete/${formData?.id}`,
		method: 'DELETE',
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	});
};
export const constituentRequsetInfo = (data: any) =>
	getaxios.request({
		url: `${constituentList.url}?pagination_required=${data.pagination}`,
		method: 'GET',
	});
export const parlimentConstituentRequsetInfo = (data: any) =>
	getaxios.request({
		url: `${parlimentConstituentList.url}?pagination_required=${data.pagination}&pageNo=${data.pageNo}&pageSize=${data.pageSize}`,
		method: 'GET',
	});
// TODO: uncomment when working on api
