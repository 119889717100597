import { call, put, takeLatest } from 'redux-saga/effects';

import {
	PetetionDetailRequest,
	PetetionDetailError,
	PetetionDetailSuccess,
	PetetionListRequest,
	PetetionListSuccess,
	PetetionListError,
	AddPetetionRequest,
	AddPetetionError,
	AddPetetionSuccess,
	UpdatePetetionRequest,
	UpdatePetetionSuccess,
	UpdatePetetionError,
	EditPetetionError,
	EditPetetionRequest,
	EditPetetionSuccess,
	SubconstituentListRequest,
	SubconstituentListSuccess,
	SubconstituentListError,
	PetetionLogError,
	PetetionLogRequest,
	PetetionLogSuccess,
} from './_petetion.slice';
import {
	addPetetions,
	petetionDetails,
	petetionList,
	updatePetetions,
	editPetetions,
	subconstituentRequsetInfo,
	petetionLog,
} from './_petetion.service';

import { setAlertInfo } from '../../common/Common.slice';
import { AxiosResponse } from 'axios';
import store from '../store';

function* handlePetetionDetails({
	payload,
}: ReturnType<typeof PetetionDetailRequest>) {
	try {
		const { data }: AxiosResponse<any> = yield call(petetionDetails, payload);
		yield put(PetetionDetailSuccess(data));
	} catch (e: any) {
		yield put(PetetionDetailError(e.message));
	}
}

function* handlePetetionList({ payload }: any) {
	try {
		console.log(payload);
		const { data }: AxiosResponse<any> = yield call(petetionList, payload);
		yield put(PetetionListSuccess(data));
	} catch (e: any) {
		yield put(PetetionListError(e.message));
	}
}

function* handleAddPetetion({
	payload,
}: ReturnType<typeof AddPetetionRequest>) {
	try {
		const { datas, handleNavigate }: any = payload;
		const { data }: AxiosResponse<any> = yield call(addPetetions, datas);
		if (data?.message) {
			handleNavigate();
		}
		const alertInfo = {
			open: true,
			type: 'Success',
			description: `${data?.message} `,
		};
		store.dispatch(setAlertInfo(alertInfo as any));
		yield put(AddPetetionSuccess(data));
	} catch (e: any) {
		yield put(AddPetetionError(e.message));
	}
}

function* handleUpdatePetetion({
	payload,
}: ReturnType<typeof UpdatePetetionRequest>) {
	try {
		const { petetionType, handleNavigate }: any = payload;
		const { data }: AxiosResponse<any> = yield call(updatePetetions, payload);
		yield put(UpdatePetetionSuccess(data));
		if (data?.message) {
			handleNavigate();
		}
		const alertInfo = {
			open: true,
			type: 'Success',
			description: `${data?.message} ${petetionType}`,
		};
		store.dispatch(setAlertInfo(alertInfo as any));
	} catch (e: any) {
		yield put(UpdatePetetionError(e.message));
	}
}

function* handleEditPetetion({
	payload,
}: ReturnType<typeof UpdatePetetionRequest>) {
	try {
		const { handleNavigate }: any = payload;
		console.log(payload);
		const { data }: AxiosResponse<any> = yield call(editPetetions, payload);
		yield put(EditPetetionSuccess(data));
		if (data?.message) {
			handleNavigate();
		}
		const alertInfo = {
			open: true,
			type: 'Success',
			description: `${data?.message}`,
		};
		store.dispatch(setAlertInfo(alertInfo as any));
	} catch (e: any) {
		yield put(EditPetetionError(e.message));
	}
}
function* subconstituentWatcher({ payload }: any) {
	try {
		console.log(payload);
		const { data }: AxiosResponse<any> = yield call(
			subconstituentRequsetInfo,
			payload
		);
		yield put(SubconstituentListSuccess(data));
	} catch (e: any) {
		yield put(SubconstituentListError(e.message));
	}
}

function* petetionLogs({ payload }: any) {
	try {
		console.log('petitionId', payload);
		const { data }: AxiosResponse<any> = yield call(petetionLog, payload);
		yield put(PetetionLogSuccess(data));
	} catch (e: any) {
		yield put(PetetionLogError(e.message));
	}
}

export default function* petetionSaga() {
	yield takeLatest(PetetionListRequest.type, handlePetetionList);
	yield takeLatest(PetetionDetailRequest.type, handlePetetionDetails);
	yield takeLatest(AddPetetionRequest.type, handleAddPetetion);
	yield takeLatest(UpdatePetetionRequest.type, handleUpdatePetetion);
	yield takeLatest(EditPetetionRequest.type, handleEditPetetion);
	yield takeLatest(SubconstituentListRequest.type, subconstituentWatcher);
	yield takeLatest(PetetionLogRequest.type, petetionLogs);
}
