export const AUTH_ROUTES = {
	LOGIN: '/',
	SIGNUP: '/signup',
	FORGET_PASSWORD: '/forget-password',
	OTP_SCREEN: '/otp-screen',
};

export const APP_ROUTES = {
	DASHBOARD: '/',
	CANDIDATE: '/candidate',
	ADMINCANDIDATE: '/candidate',
	PUBLIC_LIST: '/public-list',
	ADD_PUBLIC_LIST: '/public/add',
	EDIT_PUBLIC_LIST: '/public/edit',
	PETITION: '/petition',
	ADD_PETITION: '/petition/add',
	EDIT_PETITION: '/petition/edit',
	PDF_PETITION: '/petition/pdf',
	VIEW_PETITION: '/petition/view',
	CANDIDATE_PROFILE: '/profile',
	CHANGE_PASSWORD: '/change-password',
};

export const PUBLIC_MENU = [
	{ text: 'DASHBOARD', route: APP_ROUTES.DASHBOARD },
	{ text: 'PETITIONS', route: APP_ROUTES.PETITION },
	{ text: 'MY_PROFILE', route: APP_ROUTES.CANDIDATE_PROFILE },
	{ text: 'CHANGE_PASSWORD', route: APP_ROUTES.CHANGE_PASSWORD },
	{ text: 'LOG_OFF' },
];

export const SUPER_ADMIN_ROUTES = {
	DASHBOARD: '/',
	CANDIDATES: '/candidates',
	CANDIDATES_ADD: '/candidates/add',
	CANDIDATES_EDIT: '/candidates/edit',
	PARTIES: '/parties',
	PARTIES_ADD: '/parties/add',
	PARTIES_EDIT: '/parties/edit',
	CONSTITUENCIES: '/constituencies',
	CONSTITUENCIES_ADD: '/constituencies/add',
	CONSTITUENCIES_EDIT: '/constituencies/edit',
	PRICING_PLANS: '/pricing_plans',
	PRICING_PLANS_ADD: '/pricing_plans/add',
	PRICING_PLANS_EDIT: '/pricing_plans/edit',
};
export const ADMIN_MENU = [
	{ text: 'DASHBOARD', route: SUPER_ADMIN_ROUTES.DASHBOARD },
	{ text: 'CANDIDATES', route: SUPER_ADMIN_ROUTES.CANDIDATES },
	{ text: 'PARTIES', route: SUPER_ADMIN_ROUTES.PARTIES },
	{ text: 'PETITIONS', route: APP_ROUTES.PETITION },
	// { text: 'CONSTITUENCIES', route: SUPER_ADMIN_ROUTES.CONSTITUENCIES },
	{ text: 'MY_PROFILE', route: APP_ROUTES.CANDIDATE_PROFILE },
	{ text: 'CHANGE_PASSWORD', route: APP_ROUTES.CHANGE_PASSWORD },
	{ text: 'LOG_OFF' },
];
export const CANDIDATE_MENU = [
	{ text: 'DASHBOARD', route: APP_ROUTES.DASHBOARD },
	{ text: 'PUBLIC_LIST', route: APP_ROUTES.PUBLIC_LIST },
	{ text: 'PETITIONS', route: APP_ROUTES.PETITION },
	{ text: 'MY_PROFILE', route: APP_ROUTES.CANDIDATE_PROFILE },
	{ text: 'CHANGE_PASSWORD', route: APP_ROUTES.CHANGE_PASSWORD },
	{ text: 'LOG_OFF' },
];
export const PUBLIC_ROUTES = {};
