import { Grid } from '@mui/material';
import React, { useState } from 'react';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

import '../admin.scss';
import CandidateHeader from './CandidateHeader';
import CustomButton from '../../../../common/ui/CustomButton';
import TableComponent from '../../../../common/ui/TableComponent';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { GridColDef } from '@mui/x-data-grid';

const CandidateTable = () => {
	const navigate = useNavigate();

	const [veiwOpen, setVeiwOpen] = useState(false);

	const handleModel = () => {
		setVeiwOpen(true);
	};

	const columns: GridColDef[] = [
		{ field: 'id', headerName: 'ID', width: 70 },
		{
			headerName: 'donationId',
			field: 'donationId',
		},
		{
			headerName: 'Donate Type',
			field: 'donateType',
		},
		{
			headerName: 'Donated By',
			field: 'donatedBy',
		},
		{
			headerName: 'Donation For',
			field: 'donatedFor',
		},
		{
			headerName: 'PaymentDate',
			field: 'PaymentDate',
		},
		{
			headerName: 'Action',
			field: 'Action',
			// description: 'This column has a value getter and is not sortable.',
			// sortable: false,
			// width: 190,
			// disableColumnMenu: true,
			width: 300,
			renderCell: (a: any) => {
				return (
					<>
						<CustomButton
							text={'Edit'}
							className="mx-3  color-gray"
							// disabled={!decryptedPermissions3?.Donate?.edit}
							// onClick={() => {
							// 	navigate(APP_ROUTES.DONATE_EDIT, {
							// 		state: { text: 'Edit Donation', data: record },
							// 	});
							// }}
						/>
						<CustomButton
							text={'View'}
							className="mx-3"
							color={'warning'}
							// disabled={!decryptedPermissions3?.Donate?.view}
							// onClick={() => {
							// 	handleModel();
							// 	setdonationId(record.id);
							// 	setDonorId(record.Doner_Id);
							// }}
						/>
						<CustomButton
							text={'Print'}
							className="mx-3  color-gray"
							// disabled={!decryptedPermissions3?.Donate?.view}
							// onClick={() => {
							// 	navigate(APP_ROUTES.DONATION_PRINT, {
							// 		state: { button: 'true', data: record },
							// 	});
							// }}
						/>
					</>
				);
			},
		},
	];
	const rows = [
		{
			id: 1,
			DonationFor: 'Birthday',
			donatedBy: 'Jon',
			PaymentDate: '2024-01-05',
			DonateType: 'Material',
		},
		{
			id: 2,
			DonationFor: 'Anniversary',
			donatedBy: 'Jon',
			PaymentDate: '2024-01-05',
			DonateType: 'Material',
		},
		{
			id: 3,
			DonationFor: 'Independence day',
			donatedBy: 'Karan',
			PaymentDate: '2024-01-05',
			DonateType: 'Cash',
		},
		{
			id: 4,
			DonationFor: 'Birthday',
			donatedBy: 'Jon',
			PaymentDate: '2024-01-05',
			DonateType: 'Material',
		},
		{
			id: 5,
			DonationFor: 'Anniversary',
			donatedBy: 'Mithun',
			PaymentDate: '2024-01-05',
			DonateType: 'Material',
		},
		{
			id: 6,
			DonationFor: 'Birthday',
			donatedBy: 'Jon',
			PaymentDate: '2024-01-05',
			DonateType: 'Material',
		},
		{
			id: 7,
			DonationFor: 'Anniversary',
			donatedBy: 'Arun',
			PaymentDate: '2024-01-05',
			DonateType: 'Cash',
		},
		{
			id: 8,
			DonationFor: 'Birthday',
			donatedBy: 'Varun',
			PaymentDate: '2024-01-05',
			DonateType: 'Material',
		},
		{
			id: 9,
			DonationFor: 'Republic Day',
			donatedBy: 'Dharun',
			PaymentDate: '2024-01-05',
			DonateType: 'Cash',
		},
	];
	return (
		<Grid container>
			<Grid xs={12}>
				<CandidateHeader />
			</Grid>

			<Grid xs={12} my={5}>
				<TableComponent
					rows={rows}
					columns={columns}
					// pagination={{
					// 	total: donationData,
					// 	pageSize: 10,
					// 	current: currentPage, //
					// 	onChange: handlePageChange,
					// }}
					// bordered={bordered}
					type="donate"
					// view={decryptedPermissions3?.Donate?.view}
					// onChange={onChange}
					// ref={tableRef}
				/>
			</Grid>

			{/* <ViewModel open={veiwOpen} setOpen={setVeiwOpen} /> */}
		</Grid>
	);
};

export default CandidateTable;
