import { Grid, Typography } from '@mui/material';
import React from 'react';
import theme from '../../common/theme';
import { Link } from 'react-router-dom';
import { APP_ROUTES } from '../../common/constants/Routes';
import { useTranslation } from 'react-i18next';

const DashboardCount = ({
	image,
	className,
	title,
	count,
	petetionStatus,
}: {
	image: any;
	className: any;
	title: any;
	count: any;
	petetionStatus: any;
}) => {
	const { t } = useTranslation();
	return (
		<Grid
			item
			xs={12}
			sm={5}
			md={3.5}
			lg={3.5}
			xl={2.5}
			m={2}
			className={`dashboard-card ${className}`}
		>
			<Grid mb={8} mt={8}>
				<img src={image} className="volunteer-image" />
			</Grid>
			<Grid>
				<Link to={APP_ROUTES.PETITION} state={petetionStatus}>
					<Typography
						color={theme.palette.common.white}
						fontSize={40}
						fontWeight={600}
						textAlign={'center'}
					>
						{count}
					</Typography>

					<Typography
						color={theme.palette.common.white}
						fontSize={18}
						fontWeight={600}
					>
						{title}
					</Typography>
				</Link>
			</Grid>
		</Grid>
	);
};

export default DashboardCount;
