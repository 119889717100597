import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { SUPER_ADMIN_ROUTES } from '../../../common/constants/Routes';
import CustomButton from '../../../common/ui/CustomButton';
import { useTranslation } from 'react-i18next';

function CandidatesHeader() {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<Grid className="donation-header">
			<Typography fontSize={20} fontWeight={700}>
				{t('CANDIDATES')}
			</Typography>
			<CustomButton
				type="button"
				text={t('ADD_CANDIDATE')}
				startIcon={AddIcon}
				className=" px-10 py-5 my-20 add-donation-button"
				fontSize={14}
				onClick={() => {
					navigate(SUPER_ADMIN_ROUTES.CANDIDATES_ADD);
				}}
				color={'warning'}
			/>
		</Grid>
	);
}

export default CandidatesHeader;
