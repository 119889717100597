import React from 'react';
import { Grid } from '@mui/material';
import ConstituenciesHeader from './ConstituenciesHeader';
import ConstituenciesTable from './ConstituenciesTable';

const Constituencies = () => {
	return (
		<Grid container direction={'column'} wrap={'nowrap'}>
			<Grid>
				<ConstituenciesHeader />
			</Grid>
			<Grid>
				<ConstituenciesTable />
			</Grid>
		</Grid>
	);
};

export default Constituencies;
