import React from 'react';
import { Grid } from '@mui/material';
import CandidatesHeader from './CandidatesHeader';
import CandidatesTable from './CandidatesTable';

const Candidates = () => {
	return (
		<Grid container direction={'column'} wrap={'nowrap'}>
			<Grid>
				<CandidatesHeader />
			</Grid>
			<Grid>
				<CandidatesTable />
			</Grid>
		</Grid>
	);
};

export default Candidates;
