import React, { useEffect } from 'react';

import { GridColDef } from '@mui/x-data-grid';
import CustomButton from '../../../common/ui/CustomButton';
import TableComponent from '../../../common/ui/TableComponent';

import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
	deletePartyInProgress,
	partiesListInProgress,
	selectPartiesList,
	selectPartiesListLoading,
} from '../../../store/parties/_parties.slice';
import { useNavigate } from 'react-router-dom';
import { SUPER_ADMIN_ROUTES } from '../../../common/constants/Routes';
import { useSelector } from 'react-redux';
import Spinner from '../../../common/ui/Spinner';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PartiesTable = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const parties: any = useSelector(selectPartiesList);

	useEffect(() => {
		const payload: any = {
			pageNo: 1,
			pagination_required: true,
		};

		dispatch(partiesListInProgress(payload));
	}, []);
	const handleDelete = (id: any) => {
		const payload: any = {
			id: id,
		};
		dispatch(deletePartyInProgress(payload));
	};
	const partiesLists = parties?.data || [];
	const columns: GridColDef[] = [
		{
			headerName: t('NAME'),
			field: 'name',
			flex: 1,
		},
		{
			headerName: t('IMAGE'),
			field: 'image',
			flex: 1,
			renderCell: ({ row }: any) => {
				console.log('value', row);
				return (
					<img
						src={row.logo_url}
						alt="Party Image"
						style={{ width: 50, height: 50 }}
					/>
				);
			},
		},

		{
			headerName: t('ACTION'),
			field: 'Action',
			flex: 1,
			sortable: false,
			filterable: false,

			renderCell: ({ id }: any) => {
				return (
					<>
						<CustomButton
							color="primary"
							onClick={() =>
								navigate(`${SUPER_ADMIN_ROUTES.PARTIES_EDIT}/${id}`)
							}
							text={t('EDIT')}
							className="mx-3  color-gray"
						/>
						<CustomButton
							text={t('DELETE')}
							className="mx-3"
							onClick={() => handleDelete(id)}
							color={'warning'}
						/>
					</>
				);
			},
		},
	];
	const isLoading = useAppSelector(selectPartiesListLoading);
	const handlePagination = (data: any) => {
		console.log(data?.page, data);
		const payloads: any = {
			pageNo: data?.page + 1,
			pagination: true,
		};

		dispatch(partiesListInProgress(payloads));
	};

	return (
		<>
			{isLoading ? (
				<Grid className="spinner-loading">
					<Grid className="loading">
						<Spinner />
					</Grid>
				</Grid>
			) : (
				<TableComponent
					totalRecords={parties?.totalRecords || 0}
					rows={partiesLists ? partiesLists : []}
					columns={columns}
					handlePagination={handlePagination}
					type="party"
					paginationModel={{
						page: Number(parties?.currentPage) - 1 || 0,
						pageSize: Number(parties?.pagesize) || 5,
					}}
				/>
			)}
		</>
	);
};

export default PartiesTable;
