import React, { useEffect } from 'react';

import { GridColDef } from '@mui/x-data-grid';
import CustomButton from '../../../common/ui/CustomButton';
import TableComponent from '../../../common/ui/TableComponent';

import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
	candidatesListInProgress,
	deleteCandidateInProgress,
	selectCandidatesList,
	selectCandidatesListLoading,
} from '../../../store/candidates/_candidates.slice';
import { useNavigate } from 'react-router-dom';
import { SUPER_ADMIN_ROUTES } from '../../../common/constants/Routes';
import { useSelector } from 'react-redux';
import { CandidateModel } from '../../../models/CandidatesModels';
import { Grid } from '@mui/material';
import Spinner from '../../../common/ui/Spinner';
import { useTranslation } from 'react-i18next';

const CandidatesTable = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const candidateDetails: any = useSelector(selectCandidatesList);
	const candidateList: CandidateModel[] = candidateDetails?.data?.data || [];

	useEffect(() => {
		const payload: any = {
			pageNo: 1,
			pagination_required: true,
		};
		dispatch(candidatesListInProgress(payload));
	}, []);
	const handleDelete = (id: any) => {
		const payload: any = {
			id: id,
		};
		dispatch(deleteCandidateInProgress(payload));
	};

	const columns: GridColDef[] = [
		{
			headerName: t('FULL_NAME'),
			field: 'full_name',
			flex: 1,
		},
		{
			headerName: t('EMAIL'),
			field: 'email',
			flex: 1,
		},
		{
			headerName: t('MOBILE_NUMBER'),
			field: 'mobile',
			flex: 1,
		},
		{
			headerName: t('ADDRESS'),
			field: 'address',
			flex: 1,
		},
		{
			headerName: t('ACTION'),
			field: 'Action',
			flex: 1,
			sortable: false,
			filterable: false,

			renderCell: ({ id }: any) => {
				return (
					<>
						<CustomButton
							color="primary"
							onClick={() =>
								navigate(`${SUPER_ADMIN_ROUTES.CANDIDATES_EDIT}/${id}`)
							}
							text={t('EDIT')}
							className="mx-3  color-gray"
						/>
						<CustomButton
							text={t('DELETE')}
							className="mx-3"
							color={'warning'}
							onClick={() => handleDelete(id)}
						/>
					</>
				);
			},
		},
	];
	const isLoading = useAppSelector(selectCandidatesListLoading);
	const handlePagination = (data: any) => {
		console.log(data?.page, data);
		const payloads: any = {
			pageNo: data?.page + 1,
			pagination: true,
		};

		dispatch(candidatesListInProgress(payloads));
	};
	return (
		<>
			{isLoading ? (
				<Grid className="spinner-loading">
					<Grid className="loading">
						<Spinner />
					</Grid>
				</Grid>
			) : (
				<TableComponent
					totalRecords={candidateDetails?.data?.totalRecords || 0}
					rows={candidateList ? candidateList : []}
					columns={columns}
					handlePagination={handlePagination}
					type={'candidate'}
					paginationModel={{
						page: Number(candidateDetails?.data?.currentPage) - 1 || 0,
						pageSize: Number(candidateDetails?.data?.pagesize) || 5,
					}}
				/>
			)}
		</>
	);
};

export default CandidatesTable;
