import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import Separator from '../../common/ui/Separator';
import { APP_ROUTES } from '../../common/constants/Routes';
import SvgConverter from '../../common/SvgConverter';
import MenuIcon from '@mui/icons-material/Menu';
import LogoIcon from '../../assets/images/political_logo.png';
import Person2Icon from '../../common/icons/profile-png-5.png';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { candidateId } from '../../common/utils';
import {
	Logo,
	candidateDetailsInProgress,
} from '../../store/candidates/_candidates.slice';
const TopHeader = ({
	setDrawerOpen = () => {},
	setNotificationDrawer = () => {},
	contentHeight = 0,
}: {
	setDrawerOpen?: Function;
	setNotificationDrawer?: Function;
	contentHeight?: number | undefined;
}) => {
	const theme = useTheme();
	const role_id = localStorage.getItem('role_id');
	const name = localStorage.getItem('name');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const logo = useSelector(Logo);
	const handleLogOff = () => {
		localStorage.removeItem('user');
		navigate(APP_ROUTES.DASHBOARD);
		window.location.reload();
	};

	useEffect(() => {
		dispatch(candidateDetailsInProgress(candidateId));
	}, []);
	return (
		<Grid container className="dashBoard-header-menu  py-3">
			<Grid
				item
				className="mr-10 ml-15 menu-bar"
				display={'flex'}
				alignItems={'center'}
			>
				<IconButton
					// color='inherit'
					aria-label="open drawer"
					onClick={() => setDrawerOpen(true)}
					edge="start"
				>
					<MenuIcon />
				</IconButton>
			</Grid>
			<Grid item flex={1} className="mr-20">
				<Grid
					item
					justifyContent={'space-between  '}
					className="header-profile"
					alignItems={'center'}
				>
					<Grid className="logo-icon mt-5 my-4 ">
						{/* <SvgConverter Icon={LogoIcon} /> */}
						<img src={logo} alt="logo" className="img-logo " />
					</Grid>
					<Separator
						color={theme.palette.primary.main}
						className="profile-res1 "
					/>
					<Grid
						item
						display={'flex'}
						justifyContent={'center'}
						alignItems={'center'}
					>
						<Grid className="profile-img mt-5 mr-20">
							<img src={Person2Icon} alt="logo" className="img-logo" />
						</Grid>

						<Grid my={1}>
							<Typography
								color={theme.palette.common.black}
								display={'flex'}
								fontSize={14}
								whiteSpace={'nowrap'}
							>
								{name}
								<Typography
									color={theme.palette.common.black}
									fontStyle={'italic'}
									fontSize={14}
								>
									(
									{role_id === '2'
										? 'Candidate'
										: role_id === '3'
										? 'people'
										: 'Admin'}
									)
								</Typography>
							</Typography>

							<Link to={'#'} onClick={handleLogOff}>
								<Typography
									display={'flex'}
									alignItems={'center'}
									color={theme.palette.grey[600]}
									fontSize={14}
								>
									log out &nbsp; <PowerSettingsNewIcon className="f-15 mt-2" />
								</Typography>
							</Link>
						</Grid>
					</Grid>
				</Grid>
				<Separator color={theme.palette.primary.main} />
			</Grid>
		</Grid>
	);
};

export default TopHeader;
