import React, { useEffect } from 'react';

import { GridColDef } from '@mui/x-data-grid';
import CustomButton from '../../../common/ui/CustomButton';
import TableComponent from '../../../common/ui/TableComponent';

import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
	constituenciesListInProgress,
	selectConstituenciesList,
	selectConstituenciesListLoading,
} from '../../../store/constituencies/_constituencies.slice';
import { useNavigate } from 'react-router-dom';
import { SUPER_ADMIN_ROUTES } from '../../../common/constants/Routes';
import { useTranslation } from 'react-i18next';

const ConstituenciesTable = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	useEffect(() => {
		dispatch(constituenciesListInProgress());
	}, []);

	const columns: GridColDef[] = [
		{
			headerName: t('NAME'),
			field: 'name',
			width: 200,
		},

		{
			headerName: t('ACTION'),
			field: 'Action',
			width: 300,
			sortable: false,
			filterable: false,

			renderCell: ({ id }: any) => {
				return (
					<>
						<CustomButton
							color="primary"
							onClick={() =>
								navigate(`${SUPER_ADMIN_ROUTES.CONSTITUENCIES_EDIT}/${id}`)
							}
							text={t('EDIT')}
							className="mx-3  color-gray"
						/>
						<CustomButton
							text={t('DELETE')}
							className="mx-3"
							color={'warning'}
						/>
					</>
				);
			},
		},
	];
	const isLoading = useAppSelector(selectConstituenciesListLoading);
	const constituenciesList = useAppSelector(selectConstituenciesList);

	return (
		<TableComponent
			rows={constituenciesList}
			columns={columns}
			type="donate"
			loading={isLoading}
		/>
	);
};

export default ConstituenciesTable;
