import React, { useEffect } from 'react';

import { GridColDef } from '@mui/x-data-grid';
import CustomButton from '../../../common/ui/CustomButton';
import TableComponent from '../../../common/ui/TableComponent';

import { useAppDispatch, useAppSelector } from '../../../store/store';

import { useNavigate } from 'react-router-dom';
import { SUPER_ADMIN_ROUTES } from '../../../common/constants/Routes';
import {
	pricingPlansListInProgress,
	selectPricingPlansList,
	selectPricingPlansListLoading,
} from '../../../store/pricingPlans/_pricingPlans.slice';
import { PricingPlanModel } from '../../../models/PricingPlansModels';

const PricingPlansTable = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const pricingPlansList: PricingPlanModel[] = useAppSelector(
		selectPricingPlansList
	);

	useEffect(() => {
		const payload: any = {
			pageNo: 1,
			pagination_required: true,
		};
		dispatch(pricingPlansListInProgress(payload));
	}, []);

	const columns: GridColDef[] = [
		{
			headerName: 'Name',
			field: 'name',
			width: 200,
		},
		{
			headerName: 'Petitions',
			field: 'petitions',
			width: 200,
		},
		{
			headerName: 'Price',
			field: 'price',
			width: 100,
		},
		{
			headerName: 'Action',
			field: 'Action',
			width: 300,
			sortable: false,
			filterable: false,

			renderCell: ({ id }: any) => {
				return (
					<>
						<CustomButton
							color="primary"
							onClick={() =>
								navigate(`${SUPER_ADMIN_ROUTES.PRICING_PLANS_EDIT}/${id}`)
							}
							text={'Edit'}
							className="mx-3  color-gray"
						/>
						<CustomButton text={'Delete'} className="mx-3" color={'warning'} />
					</>
				);
			},
		},
	];
	const isLoading = useAppSelector(selectPricingPlansListLoading);

	return (
		<TableComponent
			rows={pricingPlansList}
			columns={columns}
			type="donate"
			loading={isLoading}
		/>
	);
};

export default PricingPlansTable;
