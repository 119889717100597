import React from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import FormInput from '../../../common/ui/Forms/FormInput/FormInput';
import CustomButton from '../../../common/ui/CustomButton';

import { useNavigate } from 'react-router';
import { SUPER_ADMIN_ROUTES } from '../../../common/constants/Routes';
import {
	savePricingPlanInProgress,
	selectSavePricingPlanLoading,
} from '../../../store/pricingPlans/_pricingPlans.slice';
import { useAppDispatch, useAppSelector } from '../../../store/store';

const PricingPlanForm = ({ defaultValues = {} }: { defaultValues?: any }) => {
	const isSubmitLoading = useAppSelector(selectSavePricingPlanLoading);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const {
		handleSubmit,
		formState: { errors },
		control,
	} = useForm({ defaultValues });

	const handlePricingPlanSubmit = (data: any) => {
		let payload: any = {
			id: '',
			name: data.name,
			petitions: data.petitions,
			price: data.price,
			handleSuccess: () => navigate(SUPER_ADMIN_ROUTES.PRICING_PLANS),
		};
		dispatch(savePricingPlanInProgress(payload));
	};
	return (
		<form onSubmit={handleSubmit(handlePricingPlanSubmit)}>
			<Grid container spacing={2}>
				<Grid item md={6} sm={12}>
					<FormInput
						control={control}
						name="name"
						label="Name"
						rules={{
							required: { value: true, message: 'Please enter name' },
						}}
						errors={errors}
					/>
				</Grid>
				<Grid item md={6} sm={12}>
					<FormInput
						control={control}
						name="petitions"
						label="Petitions"
						rules={{
							required: { value: true, message: 'Please enter petitions' },
						}}
						errors={errors}
					/>
				</Grid>
				<Grid item md={6} sm={12}>
					<FormInput
						control={control}
						name="price"
						label="Price"
						rules={{
							required: { value: true, message: 'Please enter price' },
						}}
						errors={errors}
					/>
				</Grid>
			</Grid>
			<Grid container justifyContent={'center'}>
				<CustomButton
					type="submit"
					text="Submit"
					className=" px-10 py-5 my-20 add-donation-button"
					fontSize={14}
					color={'primary'}
					loading={isSubmitLoading}
				/>
			</Grid>
		</form>
	);
};

export default PricingPlanForm;
