import { delay, put, takeLatest, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
	getPartyListError,
	getPartyListRequest,
	getPartyListSuccess,
	getProfileError,
	getProfileRequest,
	getProfileSuccess,
	updatePasswordError,
	updatePasswordRequest,
	updatePasswordSuccess,
	updateProfileError,
	updateProfileRequest,
	updateProfileSuccess,
} from './_profile.slice';

import {
	getProfileRequestInfo,
	updatePasswordRequestInfo,
	updateProfileRequestInfo,
} from './_profile.service';
import { setAlertInfo } from '../../common/Common.slice';
import { getPartyListEffect } from '../parties/_parties.service';
import store from '../store';

function* getProfileWatcher({ payload }: any) {
	try {
		const { data }: AxiosResponse<any> = yield call(getProfileRequestInfo);
		yield put(getProfileSuccess(data));
	} catch (e: any) {
		const alertInfo = {
			open: true,
			type: 'FAILED',
			description: `${e?.message} `,
		};
		yield put(getProfileError(e.message));
		store.dispatch(setAlertInfo(alertInfo as any));
	}
}

function* updateProfileWatcher({
	payload,
}: ReturnType<typeof updateProfileRequest>) {
	const { ...requestPayload }: any = payload;
	console.log('requestPayload', requestPayload);
	try {
		const { data }: AxiosResponse<any> = yield call(
			updateProfileRequestInfo,
			requestPayload
		);
		console.log('saga----profile', data?.candidatesInfo);
		yield put(updateProfileSuccess(data));
		const alertInfo = {
			open: true,
			type: 'Success',
			description: `${data?.message} `,
		};
		store.dispatch(setAlertInfo(alertInfo as any));
	} catch (data: any) {
		console.log('e', data);
		const alertInfo = {
			open: true,
			type: 'FAILED',
			description: `${data?.response?.data?.message} `,
		};
		store.dispatch(setAlertInfo(alertInfo as any));
		yield put(updateProfileError(data?.response?.data?.message));
	}
}
function* updatePasswordWatcher({
	payload,
}: ReturnType<typeof updatePasswordRequest>) {
	const { ...requestPayload }: any = payload;
	console.log('requestPayload', requestPayload);
	try {
		const { data }: AxiosResponse<any> = yield call(
			updatePasswordRequestInfo,
			requestPayload
		);

		yield put(updatePasswordSuccess(data));
		const alertInfo = {
			open: true,
			type: 'Success',
			description: `${data?.message} `,
		};
		store.dispatch(setAlertInfo(alertInfo as any));
	} catch (e: any) {
		console.log('saga---password', e);
		yield put(updatePasswordError(e.message));
		const alertInfo = {
			open: true,
			type: 'FAILED',
			description: `${e?.message} `,
		};
		store.dispatch(setAlertInfo(alertInfo as any));
	}
}
function* partyListWatcher({
	payload,
}: ReturnType<typeof updatePasswordRequest>) {
	const { ...requestPayload }: any = payload;

	try {
		const { data }: AxiosResponse<any> = yield call(
			getPartyListEffect,
			requestPayload
		);

		yield put(getPartyListSuccess(data));
	} catch (e: any) {
		yield put(getPartyListError(e.message));
	}
}
export default function* profileSaga() {
	yield takeLatest(getProfileRequest.type, getProfileWatcher);
	yield takeLatest(updateProfileRequest.type, updateProfileWatcher);
	yield takeLatest(updatePasswordRequest.type, updatePasswordWatcher);
	yield takeLatest(getPartyListRequest.type, partyListWatcher);
}
