import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import '../candidate.scss';
import AddIcon from '@mui/icons-material/Add';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import CustomButton from '../../../../common/ui/CustomButton';
import AddPetitionForm from './AddPetitionForm';

const EditPetitionForm = () => {
	const location = useLocation();
	const editData = location.state?.data?.data;
	console.log('editData', editData);
	return <AddPetitionForm editData={editData} />;
};

export default EditPetitionForm;
