import React from 'react';
import { Grid, Typography } from '@mui/material';
import CustomButton from '../../../common/ui/CustomButton';
import { useNavigate } from 'react-router-dom';
import CandidateForm from './CandidateForm';
import { CandidateModel } from '../../../models/CandidatesModels';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
	saveCandidateInProgress,
	selectSaveCandidateLoading,
} from '../../../store/candidates/_candidates.slice';
import { SUPER_ADMIN_ROUTES } from '../../../common/constants/Routes';
import { useTranslation } from 'react-i18next';

const CandidateAddScreen = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const isSubmitLoading = useAppSelector(selectSaveCandidateLoading);

	return (
		<Grid container direction={'column'} wrap={'nowrap'}>
			<Grid>
				<Grid className="donation-header">
					<Typography fontSize={20} fontWeight={700}>
						{t('ADD_CANDIDATE')}
					</Typography>
					<CustomButton
						type="button"
						text={t('CANCEL')}
						className=" px-10 py-5 my-20 add-donation-button"
						fontSize={14}
						onClick={() => navigate(-1)}
						color={'warning'}
					/>
				</Grid>
			</Grid>
			<Grid>
				<CandidateForm isSubmitting={isSubmitLoading} type="add" />
			</Grid>
		</Grid>
	);
};

export default CandidateAddScreen;
