import { delay, put, call, takeLatest } from 'redux-saga/effects';
import {
	constituencyDetailsInError,
	constituencyDetailsInProgress,
	constituencyDetailsInSuccess,
	constituenciesListInError,
	constituenciesListInProgress,
	constituenciesListInSuccess,
	saveConstituencyInError,
	saveConstituencyInProgress,
	saveConstituencyInSuccess,
} from './_constituencies.slice';
import { ConstituenciesList } from '../../features/super_admin/Constituencies/constant';
import { getConstituentListEffect } from './_constituencies.service';

function* getConstituenciesListWatcher({ payload: { ...payload } }: any) {
	try {
		const { data } = yield call(getConstituentListEffect, payload);
		yield put(
			constituenciesListInSuccess({
				data: data,
				message: 'Success',
			})
		);
	} catch (error) {
		yield put(
			constituencyDetailsInError({
				message: 'error',
			})
		);
	}
}

function* getConstituencyDetailsWatcher({
	payload,
}: ReturnType<typeof constituencyDetailsInProgress>) {
	try {
		yield delay(3000);
		yield put(
			constituencyDetailsInSuccess({
				data: ConstituenciesList[0],
				message: 'Success',
			})
		);
	} catch (error) {
		yield put(
			constituencyDetailsInError({
				message: 'error',
			})
		);
	}
}

function* saveConstituencyWatcher({
	payload: { handleSuccess, ...payload },
}: any) {
	try {
		yield delay(3000);
		yield put(
			saveConstituencyInSuccess({
				data: {},
				message: 'Success',
			})
		);
		if (handleSuccess) {
			handleSuccess();
		}
	} catch (error) {
		yield put(
			saveConstituencyInError({
				message: 'error',
			})
		);
	}
}

export default function* ConstituenciesSaga() {
	yield takeLatest(
		constituenciesListInProgress.type,
		getConstituenciesListWatcher
	);
	yield takeLatest(
		constituencyDetailsInProgress.type,
		getConstituencyDetailsWatcher
	);
	yield takeLatest(saveConstituencyInProgress.type, saveConstituencyWatcher);
}
