import { createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../common';
import { RootState, useAppSelector } from '../store';
import { PetetionModels } from '../../models/PetetionModels';

interface PetetionState {
	isLoading: LoadingState;
	data: any | null;
	errors: any | null;
	petetionDetails: {
		status: LoadingState;
		data: any;
		error: any;
		message: any;
	};
	PetetionList: {
		status: LoadingState;
		data: any;
		error: any;
		message: any;
	};
	AddPetetion: {
		status: LoadingState;
		data: any;
		error: any;
		message: any;
	};
	UpdatePetetion: {
		status: LoadingState;
		data: any;
		error: any;
		message: any;
	};
	EditPetetion: {
		status: LoadingState;
		data: any;
		error: any;
		message: any;
	};
	subconstituent: {
		status: LoadingState;
		data: any;
		error: any;
		message: any;
	};
	PetetionLog: {
		status: LoadingState;
		data: any;
		error: any;
		message: any;
	};
}

const initialState: PetetionState = {
	isLoading: 'idle',
	data: null,
	errors: null,
	petetionDetails: {
		status: 'idle',
		data: undefined,
		error: undefined,
		message: undefined,
	},
	PetetionList: {
		status: 'idle',
		data: undefined,
		error: undefined,
		message: undefined,
	},
	AddPetetion: {
		status: 'idle',
		data: undefined,
		error: undefined,
		message: undefined,
	},
	UpdatePetetion: {
		status: 'idle',
		data: undefined,
		error: undefined,
		message: undefined,
	},
	EditPetetion: {
		status: 'idle',
		data: undefined,
		error: undefined,
		message: undefined,
	},
	subconstituent: {
		status: 'idle',
		data: undefined,
		error: undefined,
		message: undefined,
	},
	PetetionLog: {
		status: 'idle',
		data: undefined,
		error: undefined,
		message: undefined,
	},
};
const petetionSlice = createSlice({
	name: 'petetion',
	initialState,
	reducers: {
		PetetionDetailRequest: (state) => {
			state.petetionDetails.status = 'loading';
			state.petetionDetails.data = undefined;
			state.petetionDetails.error = undefined;
		},
		PetetionDetailSuccess: (state, { payload }) => {
			state.petetionDetails.status = 'done';
			state.petetionDetails.data = payload.data;
			state.petetionDetails.error = undefined;
			state.petetionDetails.message = payload.message;
		},
		PetetionDetailError: (state, { payload }) => {
			state.petetionDetails.status = 'error';
			state.petetionDetails.data = undefined;
			state.petetionDetails.error = payload;
			state.petetionDetails.message = payload.message;
		},

		PetetionListRequest: (state) => {
			state.PetetionList.status = 'loading';
			state.PetetionList.data = undefined;
			state.PetetionList.error = undefined;
		},
		PetetionListSuccess: (state, { payload }) => {
			state.PetetionList.status = 'done';
			state.PetetionList.data = payload.data;
			state.PetetionList.error = undefined;
			state.PetetionList.message = payload.message;
		},
		PetetionListError: (state, { payload }) => {
			state.PetetionList.status = 'error';
			state.PetetionList.data = undefined;
			state.PetetionList.error = payload;
			state.PetetionList.message = payload.message;
		},

		AddPetetionRequest: (state) => {
			state.AddPetetion.status = 'loading';
			state.AddPetetion.data = undefined;
			state.AddPetetion.error = undefined;
		},
		AddPetetionSuccess: (state, { payload }) => {
			state.AddPetetion.status = 'done';
			state.AddPetetion.data = payload.data;
			state.AddPetetion.error = undefined;
			state.AddPetetion.message = payload.message;
		},
		AddPetetionError: (state, { payload }) => {
			state.AddPetetion.status = 'error';
			state.AddPetetion.data = undefined;
			state.AddPetetion.error = payload;
			state.AddPetetion.message = payload.message;
		},
		UpdatePetetionRequest: (state) => {
			state.UpdatePetetion.status = 'loading';
			state.UpdatePetetion.data = undefined;
			state.UpdatePetetion.error = undefined;
		},
		UpdatePetetionSuccess: (state, { payload }) => {
			state.UpdatePetetion.status = 'done';
			state.UpdatePetetion.data = payload.data;
			state.UpdatePetetion.error = undefined;
			state.UpdatePetetion.message = payload.message;
		},
		UpdatePetetionError: (state, { payload }) => {
			state.UpdatePetetion.status = 'error';
			state.UpdatePetetion.data = undefined;
			state.UpdatePetetion.error = payload;
			state.UpdatePetetion.message = payload.message;
		},
		EditPetetionRequest: (state) => {
			state.EditPetetion.status = 'loading';
			state.EditPetetion.data = undefined;
			state.EditPetetion.error = undefined;
		},
		EditPetetionSuccess: (state, { payload }) => {
			state.EditPetetion.status = 'done';
			state.EditPetetion.data = payload.data;
			state.EditPetetion.error = undefined;
			state.EditPetetion.message = payload.message;
		},
		EditPetetionError: (state, { payload }) => {
			state.EditPetetion.status = 'error';
			state.EditPetetion.data = undefined;
			state.EditPetetion.error = payload;
			state.EditPetetion.message = payload.message;
		},

		SubconstituentListRequest: (state) => {
			state.subconstituent.status = 'loading';
			state.subconstituent.data = undefined;
			state.subconstituent.error = undefined;
		},
		SubconstituentListSuccess: (state, { payload }) => {
			console.log('payload', payload);
			state.subconstituent.status = 'done';
			state.subconstituent.data = payload.data;
			state.subconstituent.error = undefined;
			state.subconstituent.message = payload.message;
		},
		SubconstituentListError: (state, { payload }) => {
			state.subconstituent.status = 'error';
			state.subconstituent.data = undefined;
			state.subconstituent.error = payload;
			state.subconstituent.message = payload.message;
		},

		PetetionLogRequest: (state) => {
			console.log('petitionId', state);
			state.PetetionLog.status = 'loading';
			state.PetetionLog.data = undefined;
			state.PetetionLog.error = undefined;
		},
		PetetionLogSuccess: (state, { payload }) => {
			state.PetetionLog.status = 'done';
			state.PetetionLog.data = payload.data;
			state.PetetionLog.error = undefined;
			state.PetetionLog.message = payload.message;
		},
		PetetionLogError: (state, { payload }) => {
			state.PetetionLog.status = 'error';
			state.PetetionLog.data = undefined;
			state.PetetionLog.error = payload;
			state.PetetionLog.message = payload.message;
		},
	},
});

export const {
	PetetionDetailRequest,
	PetetionDetailSuccess,
	PetetionDetailError,
	PetetionListRequest,
	PetetionListError,
	PetetionListSuccess,
	AddPetetionRequest,
	AddPetetionSuccess,
	AddPetetionError,
	UpdatePetetionRequest,
	UpdatePetetionSuccess,
	UpdatePetetionError,
	EditPetetionError,
	EditPetetionSuccess,
	EditPetetionRequest,
	SubconstituentListRequest,
	SubconstituentListSuccess,
	SubconstituentListError,
	PetetionLogRequest,
	PetetionLogSuccess,
	PetetionLogError,
} = petetionSlice.actions;
export const selectPetetionDetailsLoading = (state: RootState) =>
	state.petetion.petetionDetails.status === 'loading';

export const selectPetetionDetailsInfo = (state: RootState) =>
	state.petetion.petetionDetails.data;

export const selectPetetionDetailsError = (state: RootState) =>
	state.petetion.petetionDetails.error;

export const selectPetetionListLoading = (state: RootState) =>
	state.petetion.PetetionList.status;

export const selectPetetionListInfo = (state: RootState) =>
	state.petetion.PetetionList.data;

export const selectPetetionListError = (state: RootState) =>
	state.petetion.PetetionList.error;

export const selectAddPetetionLoading = (state: RootState) =>
	state.petetion.AddPetetion.status === 'loading';

export const selectAddPetetionInfo = (state: RootState) =>
	state.petetion.AddPetetion.data;

export const selectAddPetetionError = (state: RootState) =>
	state.petetion.AddPetetion.error;
export const subconstituentData = (state: RootState) =>
	state.petetion.subconstituent.data;
export const updatePetitionLoading = (state: RootState) =>
	state.petetion.UpdatePetetion.status === 'loading';

export const petetionLogInfo = (state: RootState) =>
	state.petetion.PetetionLog.data;
export default petetionSlice;
