import { delay, put, takeLatest, call } from 'redux-saga/effects';
import {
	candidateDetailsInError,
	candidateDetailsInProgress,
	candidateDetailsInSuccess,
	candidatesListInError,
	candidatesListInProgress,
	candidatesListInSuccess,
	constituentListError,
	constituentListRequest,
	constituentListSuccess,
	deleteCandidateInError,
	deleteCandidateInProgress,
	deleteCandidateInSuccess,
	parlimentConstituentListError,
	parlimentConstituentListRequest,
	parlimentConstituentListSuccess,
	saveCandidateInError,
	saveCandidateInProgress,
	saveCandidateInSuccess,
	updateCandidateInError,
	updateCandidateInProgress,
	updateCandidateInSuccess,
} from './_candidates.slice';
// import { CandidatesList } from '../../features/super_admin/Candidates/constant';
import {
	addCandidateInstanceListEffect,
	constituentRequsetInfo,
	deleteCandidateEffect,
	getCandidateEffect,
	getdonationInstanceListEffect,
	parlimentConstituentRequsetInfo,
	updateCandidateEffect,
} from './_candidates.service';

import { AxiosResponse } from 'axios';
import { setAlertInfo } from '../../common/Common.slice';
import { useDispatch } from 'react-redux';

function* getCandidatesListWatcher({
	payload: { handleSuccess, ...payload },
}: any) {
	try {
		const { data } = yield call(getdonationInstanceListEffect, payload);
		yield put(
			candidatesListInSuccess({
				data: data,
				message: 'Success',
			})
		);
		if (handleSuccess) {
			handleSuccess();
		}
	} catch (error) {
		yield put(
			candidatesListInError({
				message: 'error',
			})
		);
	}
}

function* getCandidateDetailsWatcher({
	payload,
}: ReturnType<typeof candidateDetailsInProgress>) {
	try {
		const { data } = yield call(getCandidateEffect, payload);
		yield put(
			candidateDetailsInSuccess({
				data: data,
				message: 'Success',
			})
		);
	} catch (error) {
		yield put(
			candidateDetailsInError({
				message: 'error',
			})
		);
	}
}

function* saveCandidateWatcher({
	payload,
}: ReturnType<typeof saveCandidateInProgress>) {
	const { navigate, ...requestPayload }: any = payload;

	try {
		console.log('requestPayload', requestPayload);
		const { data }: AxiosResponse<any> = yield call(
			addCandidateInstanceListEffect,
			requestPayload
		);
		yield put(saveCandidateInSuccess(data));

		if (requestPayload.handleSuccess) {
			requestPayload.handleSuccess();
		}
	} catch (e: any) {
		yield put(saveCandidateInError(e.message));
	}
}

function* updateCandidateWatcher({
	payload,
}: ReturnType<typeof updateCandidateInProgress>) {
	const { navigate, ...requestPayload }: any = payload;

	try {
		const { data }: AxiosResponse<any> = yield call(
			updateCandidateEffect,
			requestPayload
		);
		yield put(updateCandidateInSuccess(data));
		if (requestPayload.handleSuccess) {
			requestPayload.handleSuccess();
		}
	} catch (e: any) {
		yield put(updateCandidateInError(e.message));
	}
}
function* deleteCandidateWatcher({
	payload,
}: ReturnType<typeof deleteCandidateInProgress>) {
	const { navigate, ...requestPayload }: any = payload;

	try {
		const { data }: AxiosResponse<any> = yield call(
			deleteCandidateEffect,
			requestPayload
		);
		yield put(deleteCandidateInSuccess(data));
		if (requestPayload.handleSuccess) {
			requestPayload.handleSuccess();
		}
	} catch (e: any) {
		yield put(deleteCandidateInError(e.message));
	}
}
function* constituentListWatcher({ payload }: any) {
	try {
		console.log(payload);
		const { data }: AxiosResponse<any> = yield call(
			constituentRequsetInfo,
			payload
		);
		yield put(constituentListSuccess(data));
	} catch (e: any) {
		yield put(constituentListError(e.message));
	}
}
function* parlimentConstituentListWatcher({ payload }: any) {
	try {
		console.log(payload);
		const { data }: AxiosResponse<any> = yield call(
			parlimentConstituentRequsetInfo,
			payload
		);
		yield put(parlimentConstituentListSuccess(data));
	} catch (e: any) {
		yield put(parlimentConstituentListError(e.message));
	}
}
export default function* CandidatesSaga() {
	yield takeLatest(candidatesListInProgress.type, getCandidatesListWatcher);
	yield takeLatest(candidateDetailsInProgress.type, getCandidateDetailsWatcher);
	yield takeLatest(saveCandidateInProgress.type, saveCandidateWatcher);
	yield takeLatest(updateCandidateInProgress.type, updateCandidateWatcher);
	yield takeLatest(deleteCandidateInProgress.type, deleteCandidateWatcher);
	yield takeLatest(constituentListRequest.type, constituentListWatcher);
	yield takeLatest(
		parlimentConstituentListRequest.type,
		parlimentConstituentListWatcher
	);
}
