import React, { useEffect } from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';
import CustomButton from '../../../common/ui/CustomButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import {
	constituencyDetailsInProgress,
	saveConstituencyInProgress,
	selectConstituencyDetails,
	selectSaveConstituencyLoading,
	selectConstituencyLoading,
} from '../../../store/constituencies/_constituencies.slice';
import { SUPER_ADMIN_ROUTES } from '../../../common/constants/Routes';
import ConstituencyForm from './ConstituencyForm';
import { ConstituencyModel } from '../../../models/ConstituenciesModels';
import { useTranslation } from 'react-i18next';

const ConstituenciesEditScreen = () => {
	const navigate = useNavigate();
	const { constituency_id } = useParams();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const isSubmitLoading = useAppSelector(selectSaveConstituencyLoading);
	const isConstituenciesDetailsLoading = useAppSelector(
		selectConstituencyLoading
	);
	const constituenciesDetails = useAppSelector(selectConstituencyDetails);

	const handleConstituencySubmit = (data: ConstituencyModel) => {
		let payload: any = {
			id: constituency_id,
			name: data.name,
			handleSuccess: () => navigate(SUPER_ADMIN_ROUTES.CONSTITUENCIES),
		};
		dispatch(saveConstituencyInProgress(payload));
	};

	useEffect(() => {
		if (constituency_id) {
			dispatch(constituencyDetailsInProgress(constituency_id));
		}
	}, [constituency_id]);

	return (
		<Grid container direction={'column'} wrap={'nowrap'}>
			<Grid>
				<Grid className="donation-header">
					<Typography fontSize={20} fontWeight={700}>
						{t('EDIT_CONSTITUENCY')}
					</Typography>
					<CustomButton
						type="button"
						text={t('CANCEL')}
						className=" px-10 py-5 my-20 add-donation-button"
						fontSize={14}
						onClick={() => navigate(-1)}
						color={'warning'}
					/>
				</Grid>
			</Grid>
			<Grid>
				{isConstituenciesDetailsLoading ? (
					<Grid container justifyContent={'center'}>
						<Grid item>
							<CircularProgress color="primary" />
						</Grid>
					</Grid>
				) : (
					<ConstituencyForm
						onSubmit={handleConstituencySubmit}
						isSubmitting={isSubmitLoading}
						defaultValues={constituenciesDetails ? constituenciesDetails : {}}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export default ConstituenciesEditScreen;
