import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE_TYPE } from '../../models/CommonModels';
import { RootState, useAppSelector } from '../store';
import { LoadingState } from '../../common';

interface InitialStateTypes {
	getProfile: {
		status: LoadingState;
		data: any;
		error: any;
		message: any;
	};
	updateProfile: {
		status: LoadingState;
		data: any;
		error: any;
		message: any;
	};
	updatePassword: {
		status: LoadingState;
		data: any;
		error: any;
		message: any;
	};
	getParty: {
		status: LoadingState;
		data: any;
		error: any;
		message: any;
	};
}

const initialState: InitialStateTypes = {
	getProfile: {
		status: 'idle',
		data: undefined,
		error: undefined,
		message: undefined,
	},
	updateProfile: {
		status: 'idle',
		data: undefined,
		error: undefined,
		message: undefined,
	},
	updatePassword: {
		status: 'idle',
		data: undefined,
		error: undefined,
		message: undefined,
	},
	getParty: {
		status: 'idle',
		data: undefined,
		error: undefined,
		message: undefined,
	},
};

const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		getProfileRequest: (state) => {
			state.getProfile.status = 'loading';
			state.getProfile.data = undefined;
			state.getProfile.error = undefined;
			state.updateProfile.status = 'idle';
			state.updateProfile.data = {};
			state.updateProfile.error = undefined;
			state.updateProfile.status = 'idle';
		},
		getProfileSuccess: (state, { payload }) => {
			state.getProfile.status = 'done';
			state.getProfile.data = payload.data;
			state.getProfile.error = undefined;
			state.getProfile.message = payload.message;
		},
		getProfileError: (state, { payload }) => {
			state.getProfile.status = 'error';
			state.getProfile.data = undefined;
			state.getProfile.error = payload;
			state.getProfile.message = payload.message;
		},
		updateProfileRequest: (state) => {
			state.updateProfile.status = 'loading';
			state.updateProfile.data = {};
			state.updateProfile.error = undefined;
		},
		updateProfileSuccess: (state, action) => {
			state.updateProfile.status = 'done';
			state.updateProfile.data = action.payload;
			state.updateProfile.error = null;
			state.updateProfile.message = action;
		},
		updateProfileError: (state, { payload }) => {
			state.updateProfile.status = 'error';
			state.updateProfile.data = {};
			state.updateProfile.error = payload;
			state.updateProfile.message = payload.message;
		},
		updatePasswordRequest: (state) => {
			state.updatePassword.status = 'loading';
			state.updatePassword.data = undefined;
			state.updatePassword.error = undefined;
		},
		updatePasswordSuccess: (state, { payload }) => {
			state.updatePassword.status = 'done';
			state.updatePassword.data = payload;
			state.updatePassword.error = undefined;
			state.updatePassword.message = payload.message;
		},
		updatePasswordError: (state, { payload }) => {
			state.updatePassword.status = 'error';
			state.updatePassword.data = undefined;
			state.updatePassword.error = payload;
			state.updatePassword.message = payload.message;
		},
		getPartyListRequest: (state) => {
			state.getParty.status = 'loading';
			state.getParty.data = undefined;
			state.getParty.error = undefined;
		},
		getPartyListSuccess: (state, { payload }) => {
			state.getParty.status = 'done';
			state.getParty.data = payload.data;
			state.getParty.error = undefined;
			state.getParty.message = payload.message;
		},
		getPartyListError: (state, { payload }) => {
			state.getParty.status = 'error';
			state.getParty.data = undefined;
			state.getParty.error = payload;
			state.getParty.message = payload.message;
		},
	},
});

export const {
	getProfileRequest,
	getProfileSuccess,
	getProfileError,

	updateProfileRequest,
	updateProfileSuccess,
	updateProfileError,

	updatePasswordRequest,
	updatePasswordSuccess,
	updatePasswordError,

	getPartyListRequest,
	getPartyListSuccess,
	getPartyListError,
} = profileSlice.actions;

export const getprofileStatus = (state: RootState) =>
	state.profile.getProfile.status;

export const getProfileInfo = (state: RootState) =>
	state.profile.getProfile.data;

export const selectprofileListError = (state: RootState) =>
	state.profile.getProfile.error;
export const getProfileSuccessData = (state: RootState) =>
	state.profile.getProfile.status;

export const updateProfileStatus = (state: RootState) =>
	state.profile.updateProfile.status;
export const updateProfileData = (state: RootState) =>
	state.profile.updateProfile.data;
export const updatePasswordLoading = (state: RootState) =>
	state.profile.updatePassword.status === 'loading';

export const updatePasswordSuccessData = (state: RootState) =>
	state.profile.updatePassword.data;
export const updatePasswordSuccessDone = (state: RootState) =>
	state.profile.updatePassword.status === 'done';
export const selectPartiesList = (state: RootState) =>
	state.profile.getParty.data;
export const partyListSuccess = (state: RootState) =>
	state.profile.getParty.status;
export const updateProfileLoading = (state: RootState) =>
	state.profile.updateProfile.status === 'loading';

export default profileSlice;
