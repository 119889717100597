import { combineSlices } from '@reduxjs/toolkit';
import CandidateSlice from './candidates/_candidates.slice';
import PartieSlice from './parties/_parties.slice';
import ConstituenciesSlice from './constituencies/_constituencies.slice';
import authSlice from './auth/_auth.slice';
import commonSlice from '../common/Common.slice';
import publicSlice from './public/_public.slice';
import PetetionSlice from './petetions/_petetion.slice';
import profileSlice from './profile/_profile.slice';
import PricingPlanSlice from './pricingPlans/_pricingPlans.slice';

const rootReducer = combineSlices(
	CandidateSlice,
	PartieSlice,
	ConstituenciesSlice,
	authSlice,
	commonSlice,
	publicSlice,
	PetetionSlice,
	profileSlice,

	commonSlice,
	PricingPlanSlice
);

export default rootReducer;
