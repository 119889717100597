import React from 'react';
import CustomButton from '../CustomButton';
import { Grid, Typography } from '@mui/material';
import CommonModal from '../CommonModal';

const DeleteModel = ({
	open,
	setOpen,
	handleDelete,
	setDeleteData,
}: {
	open: any;
	setOpen: any;
	handleDelete: any;
	setDeleteData: any;
}) => {
	return (
		<CommonModal
			onClose={setOpen}
			open={open}
			modalClassName="px-20 py-20"
			boxProps={{ width: 300, height: 150 }}
		>
			<Grid>
				<Typography textAlign={'center'} mb={5}>
					Are You Sure Want to delete?
				</Typography>
			</Grid>
			<Grid display={'flex'} justifyContent={'space-around'}>
				<CustomButton
					text={'Cancel'}
					className="px-5 py-3"
					color={'warning'}
					onClick={() => setOpen(false)}
				/>
				<CustomButton
					text={'Delete'}
					className="px-5 py-3 brown-color"
					onClick={() => {
						setOpen(false);
						setDeleteData(true);
					}}
				/>
			</Grid>
		</CommonModal>
	);
};

export default DeleteModel;
