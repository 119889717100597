import {
	Divider,
	Grid,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import '../candidate.scss';
import DownloadIcon from '@mui/icons-material/Download';
import AddPetitionForm from './AddPetitionForm';
import FormInput from '../../../../common/ui/Forms/FormInput/FormInput';
import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import { Controller, useForm } from 'react-hook-form';
import TitlebarImageList from './ImageList';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CustomButton from '../../../../common/ui/CustomButton';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import theme from '../../../../common/theme';
import CommonModal from '../../../../common/ui/CommonModal';
import ForgetPasswordModal from './ForwardModal';
import ForwardModal from './ForwardModal';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { useDispatch, useSelector } from 'react-redux';
import {
	SubconstituentListRequest,
	UpdatePetetionRequest,
	subconstituentData,
	updatePetitionLoading,
} from '../../../../store/petetions/_petetion.slice';
import cryptoEncryptionDecryption from '../../../../common/crypto';
import ViewImageandFile from './ViewImageandFile';
import CustomSelectBox from '../../../../common/ui/Selectbox/CustomSelect';
import CircularIndeterminate from '../../../../common/ui/CircularLoading';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ViewPetitionLogs from './ViewPetitionLogs';
// import ViewImageAndFile from './ViewImageAndFile';

const ViewPetition = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [forwardModal, setForwardModal] = useState(false);
	const [logModal, setLogModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [petitionType, setPetitionType] = useState('');
	const constituentList = useSelector(subconstituentData);
	const updateLoading = useSelector(updatePetitionLoading);
	const viewPetitionData = location?.state?.data?.data;
	const userInfo = localStorage.getItem('userInfo');
	const decrptionUser = JSON.parse(
		cryptoEncryptionDecryption.Decrypt(userInfo)
	);
	const constituent_id = localStorage.getItem('constituent_id');
	const designation_status = localStorage.getItem('designation_status');
	console.log('viewPetitionData', viewPetitionData);
	const {
		register,
		formState: { errors },
		control,
	} = useForm({
		defaultValues: {
			firstName: viewPetitionData?.full_name || '',
			email: viewPetitionData?.email || '',
			phone: viewPetitionData?.mobile || '',
			address: viewPetitionData?.address || '',
			description: viewPetitionData?.description || '',
			candidate: viewPetitionData?.canditate_id || '',
			parliament_sub_constituencies_id:
				viewPetitionData?.parliament_sub_constituencies_id || '',
			Parliamentsubconstituencies_name:
				viewPetitionData?.Parliamentsubconstituencies_name || '',

			subject: viewPetitionData?.subject || '',
		},
	});
	const handleNavigate = () => {
		navigate(APP_ROUTES.PETITION);
	};
	const onClickForward = () => {
		setForwardModal(true);
		setPetitionType('forward');
	};
	const onClickReject = () => {
		setForwardModal(true);

		setPetitionType('reject');
	};
	const onClickResolved = () => {
		setForwardModal(true);

		setPetitionType('resolved');
	};
	const onClickInProgress = () => {
		const payload = {
			id: viewPetitionData?.id,
			petetionType: 'inprogress',
			data: {},
			handleNavigate,
		};
		setPetitionType('inprogress');
		dispatch(UpdatePetetionRequest(payload as any));
	};
	const handleDownload = (file: any) => {
		if (file.name) {
			const blobUrl = URL.createObjectURL(file);

			const link = document.createElement('a');
			link.href = blobUrl;
			link.setAttribute('download', file.name);
			document.body.appendChild(link);
			link.click();
			URL.revokeObjectURL(blobUrl);
		} else {
			window.open(file, '_blank');
		}
	};
	useEffect(() => {
		dispatch(SubconstituentListRequest(constituent_id as any));
	}, [constituent_id]);

	useEffect(() => {
		if (updateLoading) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [updateLoading]);
	console.log(
		'updateLoading',
		viewPetitionData,
		viewPetitionData?.parliament_sub_constituencies_id
	);
	return (
		<Grid container justifyContent={'center'}>
			<Grid
				item
				xs={12}
				p={2}
				display={'flex'}
				justifyContent={'space-between'}
			>
				<Typography
					fontSize={20}
					fontWeight={600}
					color={theme.palette.info.dark}
				>
					{t('VIEW_PETITION')}
				</Typography>
				<CustomButton
					type="button"
					text={t('GO_BACK')}
					startIcon={SubdirectoryArrowRightIcon}
					color={'primary'}
					className="p-8 color-gray"
					onClick={() => {
						navigate(APP_ROUTES.PETITION);
					}}
				/>
			</Grid>{' '}
			{loading ? (
				<Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
					<CircularIndeterminate />
				</Grid>
			) : (
				<>
					<Grid
						item
						xs={12}
						p={2}
						display={'flex'}
						justifyContent={'space-between'}
					>
						<CustomButton
							type="button"
							text={t('VIEW_LOGS')}
							startIcon={RemoveRedEyeIcon}
							color={'warning'}
							className="p-8"
							onClick={() => {
								setLogModal(true);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={t('FULL_NAME')}
							justifyContent="flex-start"
							className="mb-6  "
							labelProps={{ color: 'black' }}
						/>
						<FormInput
							name="firstName"
							type="text"
							placeholder={`${t('FULL_NAME')}...`}
							className="input-from"
							disabled={true}
							inputProps={{
								...register('firstName', {}),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
						<LabelWithIcon
							label={t('MOBILE_NUMBER')}
							justifyContent="flex-start"
							className="mb-6 "
							labelProps={{ color: 'black' }}
						/>
						<FormInput
							name="phone"
							type="text"
							placeholder={`${t('MOBILE_NUMBER')}...`}
							control={control}
							className="input-from"
							inputProps={{
								...register('phone', {}),
							}}
							errors={errors}
							disabled={true}
						/>
					</Grid>
					<>
						<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
							<LabelWithIcon
								label={t('SUBJECT')}
								justifyContent="flex-start"
								className="mb-6  "
								labelProps={{ color: 'black' }}
							/>
							<FormInput
								name="subject"
								type="text"
								placeholder={`${t('SUBJECT')}...`}
								control={control}
								className="input-from"
								rules={{
									required: {
										value: true,
										message: t('PLEASE_ENTER_SUBJECT'),
									},
								}}
								inputProps={{
									...register('subject', {}),
								}}
								errors={errors}
								disabled={true}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
							<LabelWithIcon
								label={t('ASSEMBLY_CONSTITUENCY')}
								justifyContent="flex-start"
								className="mb-6  "
								labelProps={{ color: 'black' }}
							/>
							<FormInput
								name="Parliamentsubconstituencies_name"
								type="text"
								placeholder={`${t('SUBJECT')}...`}
								control={control}
								className="input-from"
								rules={{
									required: {
										value: true,
										message: t('PLEASE_ENTER_SUBJECT'),
									},
								}}
								inputProps={{
									...register('Parliamentsubconstituencies_name', {}),
								}}
								errors={errors}
								disabled={true}
							/>
						</Grid>
					</>
					<>
						<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
							<LabelWithIcon
								label={t('ADDRESS')}
								justifyContent="flex-start"
								className="mb-6"
								labelProps={{ color: 'black' }}
							/>
							<FormInput
								// hideCountButton
								name="address"
								type="text"
								multipleLine
								placeholder={`${t('ADDRESS')}...`}
								control={control}
								className="input-from-text"
								inputProps={{
									...register('address', {}),
								}}
								disabled={true}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6} xl={6} p={2}>
							<LabelWithIcon
								label={t('DESCRIPTION')}
								justifyContent="flex-start"
								className="mb-6"
								labelProps={{ color: 'black' }}
							/>
							<FormInput
								// hideCountButton
								name="description"
								type="text"
								multipleLine
								placeholder={`${t('DESCRIPTION')}...`}
								control={control}
								className="input-from-text"
								inputProps={{
									...register('description', {}),
								}}
								errors={errors}
								disabled={true}
							/>
						</Grid>

						{(viewPetitionData?.image_url?.length > 0 ||
							viewPetitionData?.pdf_url?.length > 0) && (
							<ViewImageandFile
								desctiptionsLable={''}
								title=""
								descriptions=""
								images={viewPetitionData?.image_url}
								files={viewPetitionData?.pdf_url}
							/>
						)}
						<>
							{(viewPetitionData?.forword_image.length > 0 ||
								viewPetitionData?.forword_pdf.length > 0 ||
								viewPetitionData?.forword_description) && (
								<ViewImageandFile
									desctiptionsLable={`${t('FORWARD')} ${t('DESCRIPTION')}`}
									title="FORWARD"
									descriptions={viewPetitionData?.forword_description}
									images={viewPetitionData?.forword_image}
									files={viewPetitionData?.forword_pdf}
								/>
							)}

							{(viewPetitionData?.resolved_image.length > 0 ||
								viewPetitionData?.resolved_pdf.length > 0 ||
								viewPetitionData?.resolved_description) && (
								<ViewImageandFile
									desctiptionsLable={`${t('RESOLVED')} ${t('DESCRIPTION')}`}
									title="RESOLVED"
									descriptions={viewPetitionData?.resolved_description}
									images={viewPetitionData?.resolved_image}
									files={viewPetitionData?.resolved_pdf}
								/>
							)}
							{(viewPetitionData?.rejected_image.length > 0 ||
								viewPetitionData?.rejected_pdf.length > 0 ||
								viewPetitionData?.rejected_description) && (
								<ViewImageandFile
									desctiptionsLable={`${t('REJECTED')} ${t('DESCRIPTION')}`}
									title="REJECTED"
									descriptions={viewPetitionData?.rejected_description}
									images={viewPetitionData?.rejected_image}
									files={viewPetitionData?.rejected_pdf}
								/>
							)}
						</>

						{Number(decrptionUser?.role_id) === 2 && (
							<Grid
								item
								xs={6}
								justifyContent="space-between"
								display={'flex'}
								my={5}
							>
								<CustomButton
									type="button"
									text={t('INPROGRESS')}
									color={'primary'}
									className="p-12 mx-10 color-gray"
									onClick={onClickInProgress}
									disabled={viewPetitionData?.status > 2}
								/>
								<CustomButton
									type="button"
									text={t('FORWARD')}
									color={'warning'}
									className="p-12"
									onClick={onClickForward}
									disabled={viewPetitionData?.status >= 3}
								/>
								<CustomButton
									type="button"
									text={t('RESOLVED')}
									color={'primary'}
									className="p-12 mx-10 color-gray"
									onClick={onClickResolved}
									disabled={viewPetitionData?.status >= 4}
								/>
								<CustomButton
									type="button"
									text={t('REJECT')}
									color={'warning'}
									className="p-12"
									onClick={onClickReject}
									disabled={viewPetitionData?.status >= 4}
								/>
							</Grid>
						)}
					</>
				</>
			)}
			<ForwardModal
				setOpen={setForwardModal}
				open={forwardModal}
				modalType={petitionType}
				petitionId={viewPetitionData?.id}
				handleNavigate={handleNavigate}
				viewPetitionData={viewPetitionData}
			/>
			<ViewPetitionLogs
				setOpen={setLogModal}
				open={logModal}
				petitionId={viewPetitionData?.id}
			/>
		</Grid>
	);
};

export default ViewPetition;
