import {
	axiosAuthInstance,
	axiosInstance,
	getInstance,
} from '../../common/axiosInstance';
import { profileRoutes, publicRoutes } from '../../common/routes';

const axios = axiosInstance;
const getaxios = getInstance;
const { getProfile, updateProfile, updatePassword } = profileRoutes;

// TODO: uncomment when working on api

const getProfileRequestInfo = () =>
	getaxios.request({
		url: getProfile.url,
		method: 'GET',
	});
const updateProfileRequestInfo = (data: any) =>
	getaxios.request({
		url: `${updateProfile.url}/${data?.public_id}`,
		data: data?.data,
		method: 'PUT',
	});
const updatePasswordRequestInfo = (data: any) =>
	getaxios.request({
		url: updatePassword.url,
		data: data,
		method: 'POST',
	});
export {
	getProfileRequestInfo,
	updateProfileRequestInfo,
	updatePasswordRequestInfo,
};
