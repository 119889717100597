import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import '../candidate.scss';
import CustomButton from '../../../../common/ui/CustomButton';
import TableComponent from '../../../../common/ui/TableComponent';
import { APP_ROUTES } from '../../../../common/constants/Routes';
import { GridColDef } from '@mui/x-data-grid';
import PublicListHeader from './PublicListHeader';
import CommonModal from '../../../../common/ui/CommonModal';
import theme from '../../../../common/theme';
import DeleteModel from '../../../../common/ui/Modal/DeleteModel';
import { useDispatch, useSelector } from 'react-redux';
import cryptoEncryptionDecryption from '../../../../common/crypto';
import {
	publicDefaultData,
	publicsListInProgress,
	selectpublicListInfo,
	selectpublicListLoading,
} from '../../../../store/public/_public.slice';
import CircularIndeterminate from '../../../../common/ui/CircularLoading';
import { useTranslation } from 'react-i18next';

const PublicList = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [veiwOpen, setVeiwOpen] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [deleteId, setDeleteId] = useState();
	const [deleteData, setDeleteData] = useState(false);
	const publicList = useSelector(selectpublicListInfo);
	const publicListLoading = useSelector(selectpublicListLoading);

	console.log('publicList', publicList);
	const handleModel = () => {
		setVeiwOpen(true);
	};
	const handleDelete = () => {
		// dispatch(delete)
	};
	const columns: GridColDef[] = [
		// {
		// 	field: 'id',
		// 	headerName: t('ID'),
		// 	disableColumnMenu: true,
		// 	sortable: false,
		// 	flex: 1,
		// },
		{
			headerName: t('NAME'),
			field: 'full_name',
			disableColumnMenu: true,
			sortable: false,
			flex: 1,
		},
		{
			headerName: t('EMAIL'),
			field: 'email',
			disableColumnMenu: true,
			sortable: false,
			flex: 1,
		},
		{
			headerName: t('CONTACT'),
			field: 'contact',
			disableColumnMenu: true,
			sortable: false,
			flex: 1,
		},
		{
			headerName: t('ADDRESS'),
			field: 'address',
			disableColumnMenu: true,
			sortable: false,
			flex: 1,
		},
		// {
		// 	headerName: t('CANDIDATE_NAME'),
		// 	field: 'candidate_id',
		// 	disableColumnMenu: true,
		// 	sortable: false,
		// 	flex: 1,
		// },
		// {
		// 	headerName: 'Action',
		// 	field: 'Action',
		// 	disableColumnMenu: true,
		// 	sortable: false,
		// 	flex: 1,

		// 	width: 350,
		// 	renderCell: ({ row }) => {
		// 		const handleEditClick = () => {
		// 			navigate('/public/edit', {
		// 				state: { data: { data: row, type: 'edit' } },
		// 			});
		// 		};

		// 		const handleViewClick = () => {
		// 			// handle view click logic
		// 		};

		// 		const handleDeleteClick = () => {
		// 			setOpenDeleteModal(true);
		// 			setDeleteId;
		// 		};

		// 		return (
		// 			<>
		// 				<CustomButton
		// 					text={'Edit'}
		// 					className="mx-3  color-gray"
		// 					onClick={handleEditClick}
		// 				/>
		// 				{/* <CustomButton
		// 					text={'View'}
		// 					className="mx-3"
		// 					color={'warning'}
		// 					onClick={handleViewClick}
		// 				/> */}
		// 				<CustomButton
		// 					text={'Delete'}
		// 					className="mx-3"
		// 					onClick={handleDeleteClick}
		// 					color={'warning'}
		// 				/>
		// 			</>
		// 		);
		// 	},
		// },
	];

	useEffect(() => {
		if (deleteData) {
			handleDelete();
		}
	}, [deleteData]);
	useEffect(() => {
		const payload = {
			canditate_id: decrptionUser?.candidate_id
				? decrptionUser?.candidate_id
				: '',
			people_id: decrptionUser?.people_id ? decrptionUser?.people_id : '',
			pageNo: 1,
			pagination_required: true,
		};
		dispatch(publicsListInProgress(payload as any));
		dispatch(publicDefaultData());
	}, []);
	useEffect(() => {
		if (publicListLoading) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [publicListLoading]);
	const userInfo = localStorage.getItem('userInfo');
	const decrptionUser = JSON.parse(
		cryptoEncryptionDecryption.Decrypt(userInfo)
	);
	const handlePagination = (data: any) => {
		console.log(data?.page, data);
		const payloads = {
			canditate_id: decrptionUser?.candidate_id
				? decrptionUser?.candidate_id
				: '',
			people_id: decrptionUser?.people_id ? decrptionUser?.people_id : '',
			pageNo: data?.page + 1,
			pagination_required: true,
		};
		dispatch(publicsListInProgress(payloads as any));
	};
	return (
		<Grid container my={5}>
			<Grid xs={12}>
				<PublicListHeader />
			</Grid>
			{loading ? (
				<Grid xs={12} my={5} display={'flex'} justifyContent={'center'}>
					<CircularIndeterminate />
				</Grid>
			) : (
				<Grid xs={12} my={5}>
					<TableComponent
						rows={publicList?.data ? publicList?.data : []}
						columns={columns}
						type="public"
						totalRecords={publicList?.totalRecords || 0}
						handlePagination={handlePagination}
						paginationModel={{
							page: Number(publicList?.currentPage) - 1 || 0,
							pageSize: Number(publicList?.pagesize) || 5,
						}}
					/>
				</Grid>
			)}

			<DeleteModel
				setOpen={setOpenDeleteModal}
				open={openDeleteModal}
				handleDelete={handleDelete}
				setDeleteData={setDeleteData}
			/>
		</Grid>
	);
};

export default PublicList;
