import { delay, put, takeLatest, call } from 'redux-saga/effects';
import {
	pricingPlanDetailsInError,
	pricingPlanDetailsInProgress,
	pricingPlanDetailsInSuccess,
	pricingPlansListInError,
	pricingPlansListInProgress,
	pricingPlansListInSuccess,
	savePricingPlanInError,
	savePricingPlanInProgress,
	savePricingPlanInSuccess,
} from './_pricingPlans.slice';
// import { PricingPlansList } from '../../features/super_admin/PricingPlans/constant';
// import {
// 	addPricingPlanInstanceListEffect,
// 	getdonationInstanceListEffect,
// } from './_pricingPlans.service';

import { AxiosResponse } from 'axios';
import { PricingPlansList } from '../../features/super_admin/PricingPlans/constant';

function* getPricingPlansListWatcher({
	payload: { handleSuccess, ...payload },
}: any) {
	try {
		// const { data } = yield call(getdonationInstanceListEffect, payload);
		yield put(
			pricingPlansListInSuccess({
				data: PricingPlansList,
				message: 'Success',
			})
		);
		if (handleSuccess) {
			handleSuccess();
		}
	} catch (error) {
		yield put(
			pricingPlansListInError({
				message: 'error',
			})
		);
	}
}

function* getPricingPlanDetailsWatcher({
	payload,
}: ReturnType<typeof pricingPlanDetailsInProgress>) {
	try {
		yield delay(3000);
		// yield put(
		// 	// pricingPlanDetailsInSuccess({
		// 	// 	data: "",
		// 	// 	message: 'Success',
		// 	// })
		// );
	} catch (error) {
		yield put(
			pricingPlanDetailsInError({
				message: 'error',
			})
		);
	}
}

function* savePricingPlanWatcher({
	payload,
}: ReturnType<typeof savePricingPlanInProgress>) {
	const { navigate, ...requestPayload }: any = payload;

	try {
		// const { data }: AxiosResponse<any> = yield call(
		// 	addPricingPlanInstanceListEffect,
		// 	requestPayload?.data
		// );
		// yield put(savePricingPlanInSuccess(data));
		// console.log('data', data?.user?.role_id);
		// if (requestPayload.handleSuccess) {
		// 	requestPayload.handleSuccess();
		// }
	} catch (e: any) {
		yield put(savePricingPlanInError(e.message));
	}
}

export default function* PricingPlansSaga() {
	yield takeLatest(pricingPlansListInProgress.type, getPricingPlansListWatcher);
	yield takeLatest(
		pricingPlanDetailsInProgress.type,
		getPricingPlanDetailsWatcher
	);
	yield takeLatest(savePricingPlanInProgress.type, savePricingPlanWatcher);
}
