import React from 'react';

interface Column {
	key: string;
	title: string;
	dataIndex: string;
}

interface PrintTableProps {
	dataSource: any[]; // Change 'any' to the actual type of your data source
	columns: Column[];
	orgName: string;
}

const PrintTable: React.FC<PrintTableProps> = ({
	dataSource,
	columns,
	orgName,
}) => {
	return (
		<>
			<h1>{orgName}</h1>
			<table style={{ width: '100%', borderCollapse: 'collapse' }}>
				<thead>
					<tr>
						{columns.map((column) => (
							<th
								key={column.key}
								style={{ border: '1px solid black', padding: '8px' }}
							>
								{column.title}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{dataSource.map((data, index) => (
						<tr key={index}>
							{columns.map((column) => (
								<td
									key={column.key}
									style={{ border: '1px solid black', padding: '8px' }}
								>
									{data[column.dataIndex]}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</>
	);
};

export default PrintTable;
